export class VendorDialogViewModel {
    isOpen: boolean=false;
    isUpdate: boolean=false;
  
    id: number=0;
    vendorName: string="";
    vendorDescription: string="";
  
    originalData!: VendorDialogViewModel;
  
    isVendorNameEmpty: boolean=false;
  }
  