// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.master-data-inactive {
  color: rgba(238, 0, 0, 0.7333333333);
}

.master-data-inactive::after {
  content: " (Inactive)";
  color: rgba(238, 127, 0, 0.8);
}`, "",{"version":3,"sources":["webpack://./src/app/module/utilities/unit-vessel/unit-vessel.component.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;AACJ;;AAEE;EACE,sBAAA;EACA,6BAAA;AACJ","sourcesContent":[".master-data-inactive {\n    color: #e00b;\n  }\n  \n  .master-data-inactive::after {\n    content:\" (Inactive)\";\n    color: rgba(238, 127, 0, 0.8);\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
