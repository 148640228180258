export class SearchViewModel{
  unitId: number =0;
  outageId: number =0;
  vesselId: number =0;
  zoneId: number =0;
  productType: string = "";
  productPosition: string = "";
  active: boolean=false;
}

export class AnchorRefractoryViewModel {
  id: number =0;
  vesselEstimateRevisionId?: number =0;
  siteUnitId: number =0;
  outageId: number =0;
  vesselId: number =0;
  zoneId: number =0;
  productId: number =0;
  productPosition: string = "";
  area: number =0;
  thickness: number =0;
  anchorSpacing: string = "";
  qty: number =0;
  productCost: number =0;
  usageFactor: number =0;
  volume: number =0;
  baseWeight: number =0;
  createdDate!: Date;
  active: boolean=false;
}

export class ZoneCalcFactorViewModel {
  hfGeometricCalcFactor: number =0;
  bkGeometricCalcFactor: number =0;
}

export class VesselProductViewModel {
  id: number =0;
  siteUnitId: number =0;
  outageId: number =0;
  vesselId: number =0;
  productId: number =0;
  productCategoryId: number =0;
  quantity: number =0;
  labourhours: number =0;
  amountWorker: number =0;
  cost: number =0;
  createdDate: Date=new Date();
  active: boolean=false;
}
