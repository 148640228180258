import { Component, OnInit } from '@angular/core';
import { ShellService } from '../../services/shell/shell.service';

@Component({
  selector: 'app-access-denied',
  standalone: true,
  imports: [],
  templateUrl: './access-denied.component.html',
  styleUrl: './access-denied.component.scss'
})
export class AccessDeniedComponent implements OnInit {
  errorMessage:string=""
  constructor(private shellService:ShellService){}
  ngOnInit(): void {
    this.errorMessage = this.shellService.errorMessage;
    if (this.errorMessage == "" || this.errorMessage == undefined) {
      this.errorMessage = "Access Denied! Contact your system administrator."
    }
  }

}
