// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer-content {
  text-align: center;
  font-size: smaller;
  color: #00518b;
  padding-top: 15px;
}

.footer-content > * {
  opacity: 0;
  transition: 0.5s 1s ease-out;
}

.footer-content.active > * {
  opacity: 1;
}

.footer-data {
  margin-left: 16px;
  margin-right: 16px;
}

.clear-btn {
  background-color: transparent;
  border-bottom: 1px solid;
  color: #E64D4D;
  margin-left: 3px;
}

.pi-icon-xs {
  font-size: inherit !important;
}

.pi-button-icon-xs {
  font-size: inherit !important;
  height: 1.357rem !important;
  width: 1.357rem !important;
  vertical-align: 0.5px !important;
}

.footer-buttons {
  display: inline-block !important;
  width: 64px;
  padding-top: 4px;
  bottom: 12px;
}

/* Global style configs */
.i18n-flag {
  display: inline-block;
  width: 50px;
  height: 25px;
  vertical-align: middle;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.7333333333), inset 0 1px rgba(255, 255, 255, 0.2666666667), inset 0 -1px rgba(255, 255, 255, 0.2), inset 0 -1px rgba(0, 0, 0, 0.3333333333);
  border: 1px solid #333;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/footer/footer.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,kBAAA;EACA,cAAA;EACA,iBAAA;AACJ;;AAEA;EACI,UAAA;EACA,4BAAA;AACJ;;AAEA;EACI,UAAA;AACJ;;AAEA;EACI,iBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,6BAAA;EACA,wBAAA;EACA,cAAA;EACA,gBAAA;AACJ;;AAEA;EACI,6BAAA;AACJ;;AAEA;EACI,6BAAA;EACA,2BAAA;EACA,0BAAA;EACA,gCAAA;AACJ;;AAEA;EACI,gCAAA;EACA,WAAA;EACA,gBAAA;EACA,YAAA;AACJ;;AAGA,yBAAA;AACA;EACI,qBAAA;EACA,WAAA;EACA,YAAA;EACA,sBAAA;EACA,sBAAA;EACA,2BAAA;EACA,kBAAA;EACA,iLAAA;EACA,sBAAA;AAAJ","sourcesContent":[".footer-content {\n    text-align: center;\n    font-size: smaller;\n    color: #00518b;\n    padding-top: 15px;\n}\n\n.footer-content>* {\n    opacity: 0;\n    transition: 0.5s 1s ease-out;\n}\n\n.footer-content.active>* {\n    opacity: 1;\n}\n\n.footer-data {\n    margin-left: 16px;\n    margin-right: 16px;\n}\n\n.clear-btn {\n    background-color: transparent;\n    border-bottom: 1px solid;\n    color: #E64D4D;\n    margin-left: 3px;\n}\n\n.pi-icon-xs {\n    font-size: inherit !important;\n}\n\n.pi-button-icon-xs {\n    font-size: inherit !important;\n    height: 1.357rem !important;\n    width: 1.357rem !important;\n    vertical-align: .5px !important;\n}\n\n.footer-buttons {\n    display: inline-block !important;\n    width: 64px;\n    padding-top: 4px;\n    bottom: 12px;\n}\n\n\n/* Global style configs */\n.i18n-flag {\n    display: inline-block;\n    width: 50px;\n    height: 25px;\n    vertical-align: middle;\n    background-size: cover;\n    background-position: center;\n    border-radius: 4px;\n    box-shadow: 0 1px 4px #000b, inset 0 1px #fff4, inset 0 -1px #fff3, inset 0 -1px #0005;\n    border: 1px solid #333;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
