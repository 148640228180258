
export class Config {
    version: string="";
    country: string="";
    siteName: string;
    unitName: string;
    outageName: string
    constructor(){
        this.siteName = "";
        this.unitName = "";
        this.outageName = "";
    }

}