// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `host ::ng-deep .p-inputnumber-button-group .p-inputnumber-button {
  padding: 6px 12px;
  border-radius: 0 !important;
}`, "",{"version":3,"sources":["webpack://./src/app/module/utilities/site/site.component.scss"],"names":[],"mappings":"AAGY;EACE,iBAAA;EACA,2BAAA;AAFd","sourcesContent":["host{\n    ::ng-deep{\n        .p-inputnumber-button-group {\n            .p-inputnumber-button {\n              padding: 6px 12px;\n              border-radius: 0 !important;\n            }\n          }\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
