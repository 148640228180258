import { Component, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import {GlobalSiteUnitOutage, ShellService } from '../../../shared/services/shell/shell.service';
import { environment } from '../../../../environments/environment';
import { FlatoutMessages } from '../../../shared/constants';
import { AccessLevel } from '../../../auth/app-roles';

@Component({
  selector: 'app-flatout-uploader',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './flatout-uploader.component.html',
  styleUrl: './flatout-uploader.component.scss'
})
export class FlatoutUploaderComponent implements OnInit {
  constructor(private shellService: ShellService) { }
  siteUnitOutage: GlobalSiteUnitOutage | undefined = undefined;
  openUploader = FlatoutMessages.uploader;

  ngOnInit(): void {
    let accessLevel = this.shellService.getAppAccessLevel()
    if (accessLevel != AccessLevel.Administrator) {
      this.shellService.showErrorScreen(FlatoutMessages.noUploaderAccess)
    }

    this.siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(false, false);

    if (this.siteUnitOutage.unitId > 0) {
      this.openFlatoutUploader();
    }

    this.shellService.globalSiteUnitOutageSet.subscribe((obj) => {
      this.siteUnitOutage = obj;
      this.openFlatoutUploader();
    })
  }
  /**
   * This method is used to open the flatout uploader
   */
  openFlatoutUploader() {
    if (this.siteUnitOutage) {
      var url = environment.apiConfig.flatoutUri + "upload?" +
        "siteId=" + this.siteUnitOutage?.siteId +
        "&unitId=" + this.siteUnitOutage?.unitId;

      window.open(url, "_blank");
    }
  }

}
