// model used for dropdown
export class Unit {
    unitId: number = 0;
    unitName: string = "";
}

export class UnitViewModel {
    id: number = 0;
    siteId: number = 0;
    siteName: string = "";
    name: string = "";
    yearBuilt: number = 0;
    markNumber: number = 0;
    ratedCapacity: number = 0;
    normalProduct: string = "";
    currentCapacity: number = 0;
    averageVelocity: number = 0;
    fuelTypeId: number = 0;
    fuelType: string = "";
    averageFuelRate: number = 0;
    createdBy: string = "";
    createdDate!: Date;
    modifiedBy: string = "";
    active: boolean = false;
}
