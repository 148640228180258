export class ZoneDialogViewModel {
    isOpen: boolean=false;
    isUpdate: boolean=false;
  
    id: number=0;
    zoneName: string="";
    zoneDescription: string="";
    
    originalData!: ZoneDialogViewModel;
  
    isZoneNameEmpty: boolean=false;
  }