// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.product-data-inactive {
  color: rgba(238, 0, 0, 0.7333333333);
}
.product-data-inactive ::after {
  content: " (Inactive)";
  color: rgba(238, 127, 0, 0.8);
}`, "",{"version":3,"sources":["webpack://./src/app/module/utilities/product-information/product-information.component.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;AACJ;AACI;EACI,sBAAA;EACA,6BAAA;AACR","sourcesContent":[".product-data-inactive {\n    color: #e00b;\n\n    ::after{\n        content: \" (Inactive)\";\n        color: rgba(238, 127, 0, 0.8);\n    }\n  }\n  \n\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
