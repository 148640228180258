import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { OutageComponent } from "../../module/utilities/outage/outage.component";
import { EstimateRefractoryMaterialsComponent } from "../../module/utilities/estimate-refractory-materials/estimate-refractory-materials.component";
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-future-outage-setup',
  standalone: true,
  imports: [OutageComponent, EstimateRefractoryMaterialsComponent,RouterModule,NgIf],
  templateUrl: './future-outage-setup.component.html',
  styleUrl: './future-outage-setup.component.scss'
})
export class FutureOutageSetupComponent implements OnInit {

  @ViewChild("wizardContainer", { read: ViewContainerRef }) wizardContainer: any;
  
  page: string = "";
  routes = [
    'outage',
    'estimate-refractory-materials'
  ]

  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      if (this.routes.some(pg => params['comp'] === pg)) {
        this.page = params['comp'];
      }
    }); 
  }

  ngOnInit(): void {
  }

}
