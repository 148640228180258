export class BatchUsageProductViewModel {
    id: number = 0;  
    batchUsageId: number = 0;
    productId: number = 0;
    productPosition: string="";
    qty: number = 0;
    productCost: number = 0;
    batchNumber: number = 0;  
  }
  
  export class ApplicationMethod {
    id: number = 0;
    name: string="";  
  }
  