import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs';
import { PaginatedResult } from '../../models/paginatedResult.model';
import { ShellService } from '../../../../shared/services/shell/shell.service';
import { AppHttpHeaders } from '../../../../shared/services/common/app-http-headers';
import { Shift } from '../../viewModels/shift.model';
import { JobType } from '../../viewModels/jobType.model';

@Injectable({
  providedIn: 'root'
})
export class DailyUsageService {
  appReqHeader = new AppHttpHeaders();
  accessPointUrl: string = this.appReqHeader.domainURL + 'api/DailyUsage';
  constructor(private http: HttpClient, private shellService: ShellService) { }

  searchBatchUsage(searchObj: any, pageNumber?: number, pageSize?: number) {
    const paginatedResult: PaginatedResult<any> = new PaginatedResult<any>();
    let params = new HttpParams();
    if (pageNumber != null && pageSize != null) {
      params = params.append('PageNumber', pageNumber.toString());
      params = params.append('PageSize', pageSize.toString());
    }
    return this.http.post(this.accessPointUrl + "/SearchBatchUsage", searchObj, { observe: 'response', params, headers: this.appReqHeader.formHeaders }).pipe(
      map(response => {
        paginatedResult.result = response.body != null ? response.body : [];
        if (response.headers.get('X-Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('X-Pagination') || '{}');
        }
        // console.log(paginatedResult);

        return paginatedResult;
      }), catchError(this.shellService.handleError)
    );
  }

  getCalculations(searchObj: any) {
    return this.http.post(this.accessPointUrl + "/GetBatchUsageCalculation", searchObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(this.shellService.handleError)
    );
  }

  searchBatchUsageProduct(searchObj: any, dailyUsageId: number) {
    return this.http.post(this.accessPointUrl + "/SearchBatchUsageProduct?dailyUsageId=" + dailyUsageId, searchObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getShifts() {
    return this.http.get<Shift[]>(this.appReqHeader.domainURL + 'api/Shift', { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getJobTypes() {
    return this.http.get<JobType[]>(this.appReqHeader.domainURL + 'api/JobType', { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getApplicationMethod() {
    return this.http.get(this.appReqHeader.domainURL + 'api/ApplicationMethod', { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  addBatchUsage(obj: any) {
    return this.http.post(this.accessPointUrl + "/AddBatchUsage", obj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  updateBatchUsage(batchUsageId: number, patchObject: any) {
    var query = "";
    if (batchUsageId) {
      query = "/" + batchUsageId;
    }
    return this.http.patch(this.accessPointUrl + query + "/BatchUsage", patchObject, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  addAnchorRefractoryBatchProductDetails(anchorRefractoryViewModel: any) {
    return this.http.post(this.accessPointUrl + "/AddAnchorRefractoryProduct", anchorRefractoryViewModel, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  updateAnchorRefractoryBatchProduct(id: number, anchorRefractoryViewModel: any) {
    var query = "";
    if (id) {
      query = "/" + id;
    }
    return this.http.patch(this.accessPointUrl + query + "/BatchUsageProduct", anchorRefractoryViewModel, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  deleteBatchUsage(id: number) {
    var query = "";
    if (id) {
      query = "/" + id + "/batchUsage";
    }
    return this.http.delete<any>(this.accessPointUrl + query, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  deleteAnchorRefractoryProduct(id: number) {
    var query = "";
    if (id) {
      query = "/" + id + "/batchUsageProduct";
    }
    return this.http.delete<any>(this.accessPointUrl + query, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
}
