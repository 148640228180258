import { NgClass, NgIf, NgFor, NgForOf, NgStyle, DecimalPipe } from "@angular/common";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { ConfirmationService } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { ConfirmDialogModule } from "primeng/confirmdialog";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { DialogService } from "primeng/dynamicdialog";
import { InputNumberModule } from "primeng/inputnumber";
import { TableModule } from "primeng/table";
import { Subscription } from "rxjs";
import { environment } from "../../../../environments/environment";
import { constProduct } from "../../../shared/constants";
import { GlobalSiteUnitOutage, ShellService } from "../../../shared/services/shell/shell.service";
import { ZoneCalcFactorViewModel, AnchorRefractoryViewModel, VesselProductViewModel, SearchViewModel } from "../models/nominalLining.model";
import { Outage } from "../models/outage.model";
import { Product } from "../models/product.model";
import { Category } from "../models/productCategory.model";
import { Spacing } from "../models/spacing.model";
import { Vessel } from "../models/vessel.model";
import { AppSettingService } from "../services/app-setting/app-setting.service";
import { NominalLiningService } from "../services/nominal-lining/nominal-lining.service";
import { ProductService } from "../services/product/product.service";
import { SiteService } from "../services/site/site.service";
import { UnitVesselZoneService } from "../services/unit-vessel-zone/unit-vessel-zone.service";
import { UnitVesselService } from "../services/unit-vessel/unit-vessel.service";
import { UnitService } from "../services/unit/unit.service";
import { VesselProductService } from "../services/vessel-product/vessel-product.service";
import { AccessLevel } from "../../../auth/app-roles";
import { ProductType } from "../models/productType.model";

@Component({
  selector: 'app-nominal-lining',
  standalone: true,
  imports: [ FormsModule, NgClass, NgIf, NgFor, NgForOf, NgStyle, ButtonModule,DropdownModule, TableModule, DialogModule, ConfirmDialogModule, InputNumberModule, DecimalPipe],
  providers: [DialogService, ConfirmationService],
  templateUrl: './nominal-lining.component.html',
  styleUrl: './nominal-lining.component.scss'
})
export class NominalLiningComponent implements OnInit, OnDestroy {
  showingVesselProductGrid = false
  loading: boolean = false;
  selectedVessel: any;
  selectedZone: any;
  vessels: Vessel[] = [];
  zones: any;
  anchorBackupCols: any[] = [];
  anchorHotfaceCols: any[] = [];
  refractoryBackupCols: any[] = [];
  refractoryHotfaceCols: any[] = [];
  anchorBackupGridData: any;
  anchorHotfaceGridData: any;
  refractoryBackupGridData: any;
  refractoryHotfaceGridData: any;
  globalSite: string = "";
  globalUnit: string = "";
  globalOutageDate: string = "";
  retrieveObject: any;
  totalAnchorCost: number = 0;
  totalRefractoryCost: number = 0;
  totalCostEstimateZone: number = 0;
  zoneCalcFactor: ZoneCalcFactorViewModel = new ZoneCalcFactorViewModel();
  anchorRefractoryObj: AnchorRefractoryViewModel = new AnchorRefractoryViewModel();
  costPerTonne: number = 0;
  repairAreaId: number = 0;
  repairArea: number = 0;
  products: Product[] = [];
  refractoryProducts: Product[] = [];
  selectedProduct: any;
  selectedSpacing: any;
  spacings: Spacing[] = [];
  DialogProductType: string = "";
  DialogProductPosition: string = "";
  rowValue: any;
  total: number = 0;
  isProductDataLoaded: boolean = false;
  isRefractoryProductDataLoaded: boolean = false;
  isSpacingDataLoaded: boolean = false;
  tempArray: any;
  isancho: boolean = false;
  anchorDensity: number = 0;
  isAnchorDensityThicknessCostPerTonneConversionDataLoaded: boolean = false;
  thicknessConversion: number = 0;
  isVesselNameEmpty: boolean = false;
  isZoneNameEmpty: boolean = false;
  accessLevel: AccessLevel = AccessLevel.NoAccess

  // Site Unit Outage Dialog
  unitId: number = 0;
  countryId: number = 0;
  selectedOutage: Outage = new Outage();

  // Subscriptions
  globalDataSub: Subscription = new Subscription();
  nominalLiningOutageSub: Subscription = new Subscription();
  vesselByUnitSub: Subscription = new Subscription();
  zoneByIdSub: Subscription = new Subscription()
  first: number = 0;

  globalSiteUnitOutage: GlobalSiteUnitOutage | undefined = undefined;

  // Rahul's Addition
  disableStatus = false;
  isChildZone = false;
  isRepairAreaValidGlobal = false;
  isAreaEntryNotRequired = true;
  isDataNotAvailable = false;

  constructor(private siteService: SiteService, private unitService: UnitService, private shellService: ShellService,
    private activatedRoute: ActivatedRoute,
    private nominalService: NominalLiningService,
    private productService: ProductService, private appSetting: AppSettingService,
    private confirmationService: ConfirmationService, private vesselProductService: VesselProductService,
    private dialogService: DialogService) { }

  ngOnInit(): void {
    this.accessLevel = this.shellService.getAppAccessLevel();
    this.anchorBackupGridHeader();
    this.anchorHotfaceGridHeader();
    this.vesselProductGridHeader();
    // Retrieve the object from localstorage
    this.globalSiteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(false, false);
    if (this.globalSiteUnitOutage && this.globalSiteUnitOutage.unitId != undefined) {
      this.unitId = this.globalSiteUnitOutage.unitId;
      this.countryId = this.globalSiteUnitOutage.countryId;
      this.getNominalOutages(this.unitId);
      this.getVessel(this.globalSiteUnitOutage.unitId);
    }
    this.globalDataSub = this.shellService.globalSiteUnitOutageSet.subscribe((data) => {
      this.unitId = data.unitId;
      this.countryId = data.countryId;
      this.getNominalOutages(this.unitId);
      this.getVessel(this.unitId);
      this.getSiteDetail(data.siteId);
    });
    this.zoneCalcFactor = new ZoneCalcFactorViewModel();
    this.anchorRefractoryObj = new AnchorRefractoryViewModel();
    this.vesselProductObj = new VesselProductViewModel();
    this.refractoryBackupGridHeader();
    this.refractoryHotfaceGridHeader();
    var siteId = this.globalSiteUnitOutage ? this.globalSiteUnitOutage.siteId : 0;
    this.getSiteDetail(siteId);
    if (this.activatedRoute.snapshot.queryParamMap.get('siteId') && this.activatedRoute.snapshot.queryParamMap.get('unitId')) {
      if (Number(this.activatedRoute.snapshot.queryParamMap.get('siteId')) != this.globalSiteUnitOutage.siteId || Number(this.activatedRoute.snapshot.queryParamMap.get('unitId')) != this.globalSiteUnitOutage.unitId)
        this.shellService.resetConfig(true, false)
    }
  }

  gangRate: number = 0;
  getSiteDetail(siteId: number) {
    this.gangRate = 0;
    this.siteService.getSiteById(siteId).subscribe((data) => {
      this.gangRate = data.gangRate;
    });
  }

  ngOnDestroy() {
    if (this.globalDataSub) { this.globalDataSub.unsubscribe() }
    if (this.nominalLiningOutageSub) { this.nominalLiningOutageSub.unsubscribe() }
    if (this.vesselByUnitSub) { this.vesselByUnitSub.unsubscribe() }
    if (this.zoneByIdSub) { this.zoneByIdSub.unsubscribe() }
  }

  //#region Site Unit Outage Dialog
  getNominalOutages(unitId: number) {
    this.nominalLiningOutageSub = this.unitService.getNominalOutageByUnitId(unitId).subscribe(response => {
      if (response) {
        this.selectedOutage = response;
        this.shellService.setOutage(response);
        this.shellService.refreshFooter(response);
      }
    }, () => {
      this.shellService.alert(this.dialogService, { title: 'Warning!', message: "Failed to retreive nominal lining outage for unit. This could be due to bad data and may break functionality.", severity: 'warning ' })
      this.shellService.showLoadingIndicator(false);
    })
  }
  //#endregionp[p]

  incrementDecimalRepairArea() {

    if (this.accessLevel >= AccessLevel.BasicUser) {
      if (this.repairArea)
        this.repairArea += 0.01;
      else
        this.repairArea = 0.01;
      this.disableStatus = false;
    }

  }

  decrementDecimalRepairArea() {
    if (this.repairArea > 0.01)
      this.repairArea -= 0.01;
    else {
      this.repairArea = 0;
      this.shellService.alert(this.dialogService, { title: "Nominal Lining Absent", message: "Nominal lining repair area is required.Please enter the repair area", severity: "error" })
    }

  }

  //get only those vessel which associated to the selected unit
  getVessel(unitId: number) {
    this.vessels = [];
    this.shellService.showLoadingIndicator(true);
    this.vesselByUnitSub = this.unitService.getVesselByUnitId(unitId).subscribe(response => {
      if (response) {
        this.vessels = response;
        let paramterVessel = (Number(this.activatedRoute.snapshot.queryParamMap.get('vesselId')))
        if (paramterVessel > 0) {
          let requiredVessel = this.vessels.find((vessel) => { return vessel.id === paramterVessel })
          if (requiredVessel) {
            this.selectedVessel = requiredVessel
            this.onVesselChange(requiredVessel)
          }
          window.history.replaceState({}, document.title, "/#/calciner-setup/nominal-lining");
        }
      }
      this.shellService.showLoadingIndicator(false);
    }, () => {
      this.shellService.showLoadingIndicator(false);
    })
  }

  onVesselChange(vessel: any) {
    if (this.showingVesselProductGrid == true) {
      this.loadVesselProductGridData();
    } else {
      this.selectedZone = [];
      this.resetAll();
      var siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(false, false);
      if (vessel) {
        this.isVesselNameEmpty = false;
        this.zoneByIdSub = this.unitService.getZoneBySiteUnitVesselId(siteUnitOutage.unitId, vessel.id).subscribe(response => {
          this.zones = response;
        })
      }
    }

  }

  toggleVesselProductGrid(): void {
    this.showingVesselProductGrid = !this.showingVesselProductGrid;
    var selectedVessel = this.selectedVessel ? this.selectedVessel.id : "";
    if (this.showingVesselProductGrid) {
      if (selectedVessel) {
        this.loadVesselProductGridData();
      }
    }
  }

  productTypes: ProductType[] = [];
  productCategories: Category[] = [];
  vesselProducts: any;
  productDropList: Product[] = [];
  selectedProductType: any;
  selectedProductCategory: any;
  selectedVesselProduct: any;

  vesselProductDialog = {
    opened: false,
    isProductTypeEmpty: false,
    isProductCategoryEmpty: false,
    isProductEmpty: false,
    isQuantityEmpty: false,
    isLabourHoursEmpty: false,
    isAmountWorkerEmpty: false,
    isUpdate: false
  }

  //validate vessel product
  validateVesselProduct() {
    var isValid = true;
    var productType = this.selectedProductType ? this.selectedProductType.name : "";
    if (productType) {
      this.vesselProductDialog.isProductTypeEmpty = false;
    } else {
      this.vesselProductDialog.isProductTypeEmpty = true;
      isValid = false;
    }
    var productCategory = this.selectedProductCategory ? this.selectedProductCategory.name : "";
    if (productCategory) {
      this.vesselProductDialog.isProductCategoryEmpty = false;
    } else {
      this.vesselProductDialog.isProductCategoryEmpty = true;
      isValid = false;
    }
    var vesselProduct = this.selectedVesselProduct ? this.selectedVesselProduct.name : "";
    if (vesselProduct) {
      this.vesselProductDialog.isProductEmpty = false;
    } else {
      this.vesselProductDialog.isProductEmpty = true;
      isValid = false;
    }
    if (this.vesselProductObj.quantity > 0) {
      this.vesselProductDialog.isQuantityEmpty = false;
    } else {
      this.vesselProductDialog.isQuantityEmpty = true;
      isValid = false;
    }
    if (this.vesselProductObj.labourhours > 0) {
      this.vesselProductDialog.isLabourHoursEmpty = false;
    } else {
      this.vesselProductDialog.isLabourHoursEmpty = true;
      isValid = false;
    }
    if (this.vesselProductObj.amountWorker > 0) {
      this.vesselProductDialog.isAmountWorkerEmpty = false;
    } else {
      this.vesselProductDialog.isAmountWorkerEmpty = true;
      isValid = false;
    }

    return isValid;
  }

  toggleVesselProductDialog() {
    if (!this.validateFilterSelection(true)) {
      return;
    }
    this.vesselProductDialog.opened = !this.vesselProductDialog.opened;
    if (this.vesselProductDialog.opened) {
      this.vesselProductDialog.isUpdate = false;
      this.resetVesselProuctDetail();
      this.getProductTypes();
    }
  }

  getProductTypes(productType?: string, productCategory?: string, product?: string): void {
    this.shellService.showLoadingIndicator(true);
    this.productTypes = [];
    this.productService.getProductTypes().subscribe((response) => {
      if (response != null) {
        for (var i = 0; i < response.length; i++) {
          if (response[i].isVesselZone) {
            this.productTypes.push(response[i]);
          }
        }
        if (productType) {
          var rowIndex = this.productTypes.findIndex(x => x.name == productType);
          this.selectedProductType = this.productTypes[rowIndex];
          this.onProductTypeChange(null, productCategory, this.selectedProductType.id, product);
        }
      }
      this.shellService.showLoadingIndicator(false);
    }, () => {
      this.shellService.showLoadingIndicator(false);
    })
  }

  onProductTypeChange(event: any, productCategory?: string, id?: number, product?: string) {
    var productTypeId = event == null ? 0 : event.value.id;
    if (productCategory) {
      productTypeId = id;
    }
    this.productService.getCategoryByProductType(productTypeId).subscribe(response => {
      if (response) {
        this.productCategories = response;
        if (productCategory) {
          var rowIndex = this.productCategories.findIndex(x => x.name == productCategory);
          this.selectedProductCategory = this.productCategories[rowIndex];
          this.ProductCategoryChange(null, product, this.selectedProductCategory.id);
        }
      }
    });
  }

  vesselProductCalcObj: any;
  vesselProductCost: any;
  vesselProductDialogControlChange(event: any, type: string) {
    if (type == 'product') {
      this.vesselProductCost = 0;
      this.vesselProductCost = event.value.costPerUnit;
    } else if (type == 'qty') {
      this.vesselProductObj.quantity = 0;
      this.vesselProductObj.quantity = event.value;
    }
    else if (type == 'labor') {
      this.vesselProductObj.labourhours = 0;
      this.vesselProductObj.labourhours = event.value;
    }
    else if (type == 'amountWorker') {
      this.vesselProductObj.amountWorker = 0;
      this.vesselProductObj.amountWorker = event.value;
    }
    this.calculateVesselProductCost();
  }

  resetVesselProuctDetail() {
    this.vesselProductObj = new VesselProductViewModel();
    this.vesselProductDialog.isProductTypeEmpty = false;
    this.vesselProductDialog.isProductCategoryEmpty = false;
    this.vesselProductDialog.isProductEmpty = false;
    this.vesselProductDialog.isQuantityEmpty = false;
    this.vesselProductDialog.isLabourHoursEmpty = false;
    this.vesselProductDialog.isAmountWorkerEmpty = false;
    this.vesselProductDialog.isUpdate = this.vesselProductDialog.isUpdate ? this.vesselProductDialog.isUpdate : false;
    this.selectedProductType = undefined;
    this.selectedProductCategory = undefined;
    this.selectedVesselProduct = undefined;
  }

  vesselProductObj: VesselProductViewModel = new VesselProductViewModel();

  vesselProductCols: any;
  vesselProductGridHeader() {
    this.vesselProductCols = [
      { field: 'productType', header: 'Product Type' },
      { field: 'productCategory', header: 'Product Category' },
      { field: 'product', header: 'Product' },
      { field: 'quantity', header: 'Quantity' },
      { field: 'labourhours', header: 'Labour hours' },
      { field: 'amountWorker', header: 'Amount of workers' },
      { field: 'cost', header: 'Cost' },
    ];
  }

  loadVesselProductGridData() {
    this.loading = true;
    this.vesselProducts = [];
    var siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(false, false);
    var searchObj = {
      unitId: siteUnitOutage.unitId,
      outageId: siteUnitOutage.outageId,
      vesselId: this.selectedVessel ? this.selectedVessel.id : 0,
      VesselEstimateRevisionId: 0,
      active: true,
    }
    this.vesselProductService.loadVesselProduct(searchObj).subscribe(response => {
      if (response) {
        this.first = 0;
        this.vesselProducts = response;
      }
      this.loading = false;
    })
  }

  ProductCategoryChange(event: any, product?: string, id?: number) {
    var productCategoryId = event == null ? 0 : event.value.id;
    this.productDropList = [];
    if (product) {
      productCategoryId = id;
    }
    var siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(false, false);
    this.productService.getProductByCategory(productCategoryId, siteUnitOutage.countryId).subscribe(response => {
      if (response) {
        this.productDropList = response;
        if (product) {
          var rowIndex = this.productDropList.findIndex(x => x.name == product);
          this.selectedVesselProduct = this.productDropList[rowIndex];
          this.vesselProductCost = this.selectedVesselProduct.costPerUnit;
        }
      }
    });
  }

  saveVesselProducts() {
    if (!this.validateVesselProduct()) {
      return;
    }
    var siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(false, false);
    let vesselProductViewModel: any = {
      id: 0,
      siteUnitId: siteUnitOutage.unitId,
      outageId: siteUnitOutage.outageId,
      vesselId: this.selectedVessel ? this.selectedVessel.id : 0,
      productId: this.selectedVesselProduct.id,
      quantity: this.vesselProductObj.quantity,
      labourhours: this.vesselProductObj.labourhours,
      amountWorker: this.vesselProductObj.amountWorker,
      cost: this.vesselProductObj.cost,
      active: true,
      createdDate: new Date()
    }
    this.shellService.showLoadingIndicator(true);
    this.vesselProductService.addVesselProductDetails(vesselProductViewModel).subscribe(() => {
      this.shellService.showLoadingIndicator(false);
      this.vesselProductDialog.opened = false;
      // this.alertService.openAlert("Success!", "Saved successfully!", "success");
      this.shellService.alert(this.dialogService, { title: 'Success!', message: 'Saved successfully!', severity: 'success' })
      this.loadVesselProductGridData();
      this.resetVesselProuctDetail();

    }, () => {
      this.shellService.showLoadingIndicator(false);

      this.vesselProductDialog.opened = false;
      this.resetVesselProuctDetail();
    })
  }

  deleteVesselProductObj: any;
  deleteVesselProductRow(obj: any) {
    this.deleteObj = {};
    this.deleteVesselProductObj = {
      id: obj.id
    }
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this Vessel Product?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'aq-destructive ',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'aq-outline-primary',
      accept: () => {
        this.deleteVesselProductConfirm();
      },
      reject: () => {
        this.deleteVesselProductObj = {};
      }
    });
  }

  deleteVesselProductConfirm() {
    if (this.deleteVesselProductObj.id) {
      this.shellService.showLoadingIndicator(true);
      this.vesselProductService.deleteVesselProduct(this.deleteVesselProductObj.id).subscribe(() => {
        this.shellService.showLoadingIndicator(false);
        // this.alertService.openAlert("Deleted!", "Product deleted successfully!", "success");
        this.shellService.alert(this.dialogService, { title: 'Deleted!', message: 'Product deleted successfully!', severity: 'success' })
        this.loadVesselProductGridData();
        this.deleteVesselProductObj = {};
      }, () => {
        this.shellService.showLoadingIndicator(false);
      })
    }
  }

  vesselProductId: number = 0;
  editVesselProduct(data: any) {
    this.vesselProductDialog.isUpdate = true;
    this.vesselProductId = data.id;

    this.vesselProductObj.active = data.active;
    this.vesselProductObj.quantity = data.quantity;
    this.vesselProductObj.labourhours = data.labourhours;
    this.vesselProductObj.amountWorker = data.amountWorker;
    this.vesselProductObj.cost = data.cost;
    this.getProductTypes(data.productType, data.productCategory, data.product);
    this.setVesselProductRowData(data.product, data.productCategory, data.productType);
    this.vesselProductDialog.opened = true;
  }

  //sets the original grid data
  vesselProductGridRowData: any;
  setVesselProductRowData(productName: string, productCategoryName: string, productType: string) {
    this.vesselProductGridRowData = {
      id: this.id,
      productName: productName,
      quantity: this.vesselProductObj.quantity,
      labourhours: this.vesselProductObj.labourhours,
      amountWorker: this.vesselProductObj.amountWorker,
      cost: this.vesselProductObj.cost
    }
  }

  //create array only for modified fields
  tempVesselProductPatchArrayObj: any = [];
  bindVesselProductPatchObject(vesselProduct: any) {
    this.tempPatchArrayObj = [];
    var patchArrayObj = [];
    if (vesselProduct.productName != this.vesselProductGridRowData.productName && vesselProduct.productId > 0) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("productId", vesselProduct.productId))
    }
    if (vesselProduct.quantity != this.vesselProductGridRowData.quantity) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("quantity", vesselProduct.quantity))
    }
    if (vesselProduct.labourhours != this.vesselProductGridRowData.labourhours) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("labourhours", vesselProduct.labourhours))
    }
    if (vesselProduct.amountWorker != this.vesselProductGridRowData.amountWorker) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("amountWorker", vesselProduct.amountWorker))
    }
    if (vesselProduct.cost != this.vesselProductGridRowData.cost) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("cost", vesselProduct.cost))
    }

    if (this.tempPatchArrayObj.length > 0) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))

      for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
        patchArrayObj.push(this.tempPatchArrayObj[i][0]);
      }
    }
    return patchArrayObj;
  }

  updateVesselProduct() {
    if (!this.validateVesselProduct()) {
      return;
    }
    var siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(false, false);

    let vesselProduct = {
      id: this.vesselProductId,
      siteUnitId: siteUnitOutage.unitId,
      outageId: siteUnitOutage.outageId,
      vesselId: this.selectedVessel ? this.selectedVessel.id : 0,
      productId: this.selectedVesselProduct.id,
      productName: this.selectedVesselProduct.name,
      quantity: this.vesselProductObj.quantity,
      labourhours: this.vesselProductObj.labourhours,
      amountWorker: this.vesselProductObj.amountWorker,
      cost: this.vesselProductObj.cost,
      active: true,
    }
    var vesselProductViewModel = this.bindVesselProductPatchObject(vesselProduct);
    if (vesselProductViewModel.length > 0) {
      this.shellService.showLoadingIndicator(true);
      this.vesselProductService.updateVesselProduct(vesselProduct.id, vesselProductViewModel).subscribe(() => {
        this.shellService.showLoadingIndicator(false);
        this.loadVesselProductGridData();
        this.vesselProductDialog.opened = false;
        // this.alertService.openAlert("Success!", "Updated successfully!", "success");
        this.shellService.alert(this.dialogService, { title: 'Success!', message: "Updated successfully!", severity: 'success' });
        this.resetVesselProuctDetail();

      }, () => {
        this.shellService.showLoadingIndicator(false);
        this.vesselProductDialog.opened = false;
        this.resetVesselProuctDetail();
      })
    }
  }

  //Cost = (Product Cost x Quantity) + (Amount of workers x Labour hours x gangrate)
  calculateVesselProductCost() {
    var productCost = this.vesselProductCost;
    var qty = this.vesselProductObj.quantity;
    var amountWorker = this.vesselProductObj.amountWorker;
    var labourhours = this.vesselProductObj.labourhours;
    var gangRate = this.gangRate;
    if (productCost > 0 && qty > 0 && amountWorker > 0 && labourhours > 0 && gangRate > 0) {
      this.vesselProductObj.cost = 0;
      var finalCalc = (productCost * qty) + (amountWorker * labourhours * gangRate);
      this.vesselProductObj.cost = finalCalc > 0 ? finalCalc : 0;
    }

  }

  //save repair area
  saveRepairArea() {
    if (!this.validateFilterSelection()) {
      return;
    }
    var isRepairAreaValid = true;
    var siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(false, false);
    if (this.repairArea > 0) {
      if (this.anchorBackupGridData) {
        for (var i = 0; i < this.anchorBackupGridData.length; i++) {
          var gridRepairArea = this.anchorBackupGridData[i].area;
          if (gridRepairArea > this.repairArea) {
            isRepairAreaValid = false;
          }
        }
      }
      if (this.anchorHotfaceGridData) {
        for (var i = 0; i < this.anchorHotfaceGridData.length; i++) {
          var gridRepairArea = this.anchorHotfaceGridData[i].area;
          if (gridRepairArea > this.repairArea) {
            isRepairAreaValid = false;
          }
        }
      }
      if (this.refractoryBackupGridData) {
        for (var i = 0; i < this.refractoryBackupGridData.length; i++) {
          var gridRepairArea = this.refractoryBackupGridData[i].area;
          if (gridRepairArea > this.repairArea) {
            isRepairAreaValid = false;
          }
        }
      }
      if (this.refractoryHotfaceGridData) {
        for (var i = 0; i < this.refractoryHotfaceGridData.length; i++) {
          var gridRepairArea = this.refractoryHotfaceGridData[i].area;
          if (gridRepairArea > this.repairArea) {
            isRepairAreaValid = false;
          }
        }
      }
      if (isRepairAreaValid) {
        var refractoryDetailsObj = {
          siteUnitId: siteUnitOutage.unitId,
          outageId: siteUnitOutage.outageId,
          vesselId: this.selectedVessel ? this.selectedVessel.id : 0,
          zoneId: this.selectedZone ? this.selectedZone.id : 0,
          repairArea: this.repairArea,
          active: true,
          createdDate: new Date()
        }
        if (this.repairAreaId > 0) {
          this.tempPatchArrayObj = [];
          var patchArrayObj = [];
          this.tempPatchArrayObj.push(this.shellService.createPatchArray("repairArea", this.repairArea));
          this.tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()));
          for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
            patchArrayObj.push(this.tempPatchArrayObj[i][0]);
          }
          this.nominalService.updateHotfaceRepairArea(this.repairAreaId, patchArrayObj).subscribe(() => {
            this.shellService.alert(this.dialogService, { title: 'Updated!', message: 'Repair Area updated successfully!', severity: 'success' })
          });
        } else {
          this.nominalService.saveRepairArea(refractoryDetailsObj).subscribe(() => {
            this.shellService.alert(this.dialogService, { title: 'Saved!', message: "Repair Area saved successfully!", severity: 'success' })
          });
        }
      } else {
        this.shellService.alert(this.dialogService, { title: "Validation!", message: "Repair Area input is less than the Araea for Anchor/Refractory product! ", severity: 'warning' })
        return; //stop here
      }
    } else {
      this.shellService.alert(this.dialogService, { title: "Validation!", message: "Repair Area is required!", severity: 'warning' })
      return;
    }
  }

  //#region Anchor details backup grid
  anchorBackupGridHeader() {
    this.anchorBackupCols = [
      { field: 'product', header: 'Backup Anchors', width: this.accessLevel > AccessLevel.Contractor ? '25%' : '30%' },
      { field: 'area', header: 'Area (SqM)', width: this.accessLevel > AccessLevel.Contractor ? '18%' : '20%', format: true },
      { field: 'anchorSpacing', header: 'Spacing', width: this.accessLevel > AccessLevel.Contractor ? '15%' : '20%' },
      { field: 'qty', header: 'Qty', width: this.accessLevel > AccessLevel.Contractor ? '8%' : '10%' },
      { field: 'productCost', header: 'Cost($)', width: this.accessLevel > AccessLevel.Contractor ? '10%' : '20%', format: true }];
  }
  //#endregion

  createSearchObj(productType: string, productPosition: string,) {
    var siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(false, false);
    var searchObj: SearchViewModel = {
      unitId: siteUnitOutage.unitId,
      outageId: siteUnitOutage.outageId,
      vesselId: this.selectedVessel ? this.selectedVessel.id : 0,
      zoneId: this.selectedZone ? this.selectedZone.id : 0,
      productType: productType,
      productPosition: productPosition,
      active: true
    }
    return searchObj;
  }

  onZoneChanged() {
    this.isProductDataLoaded = false;
    this.isRefractoryProductDataLoaded = false;
    this.loadAnchorRefractoryProductsData();
  }

  //this reset all grid and calculation
  resetAll() {
    this.anchorBackupGridData = [];
    this.anchorHotfaceGridData = [];
    this.refractoryBackupGridData = [];
    this.refractoryHotfaceGridData = [];
    this.repairArea = 0;
    this.repairAreaId = 0;
    this.totalAnchorCost = 0;
    this.totalRefractoryCost = 0;
    this.totalCostEstimateZone = 0;
    this.disableStatus = false;
  }

  loadAnchorRefractoryProductsData() {
    if (!this.validateFilterSelection()) {
      return;
    }
    this.resetAll(); //this reset all grid and calculation
    var anchorBackupProductType = constProduct.anchorType;
    var anchorBackupProductPosition = constProduct.positionBackup;
    let anchorBackup = this.createSearchObj(anchorBackupProductType, anchorBackupProductPosition);

    var anchorHotfaceProductType = constProduct.anchorType;
    var anchorHotfaceProductPosition = constProduct.positionHotface;
    let anchorHotface = this.createSearchObj(anchorHotfaceProductType, anchorHotfaceProductPosition);

    var refractoryBackupProductType = constProduct.refractoryType;
    var refractoryBackupProductPosition = constProduct.positionBackup;
    let refractoryBackup = this.createSearchObj(refractoryBackupProductType, refractoryBackupProductPosition);

    var refractoryHotfaceProductType = constProduct.refractoryType;
    var refractoryHotfaceProductPosition = constProduct.positionHotface;
    let refractoryHotface = this.createSearchObj(refractoryHotfaceProductType, refractoryHotfaceProductPosition);

    let unitVesselZoneObj = this.createSearchObj("", ""); //to fetch repair area,HF/Bk GCF

    this.loading = true;
    this.nominalService.getNominalLiningAllProducts(anchorBackup, anchorHotface, refractoryBackup, refractoryHotface, unitVesselZoneObj).subscribe(response => {
      this.loading = false;
      if (response) {
        // console.log('response -> ', response)
        this.anchorBackupGridData = response[0];
        this.anchorHotfaceGridData = response[1];
        this.refractoryBackupGridData = response[2];
        this.refractoryHotfaceGridData = response[3];
        this.zoneCalcFactor = response[4];
        if (response[5]) {
          this.repairAreaId = response[5].id;
          this.repairArea = response[5].repairArea;

          this.isDataNotAvailable = false
        }
        else {
          this.isDataNotAvailable = true;
          // this.checkWhetherRepairAreaShouldBeModifiable(unitVesselZoneObj.zoneId);
          // this.isChildZone = true;
          // this.disableStatus = (this.accessLevel < 3)
        }
        this.checkWhetherRepairAreaShouldBeModifiable(unitVesselZoneObj.zoneId);

        this.calculateDetails();
      }
    }, () => {
      this.loading = false;
      this.shellService.showLoadingIndicator(false);
    })
  }

  getZonesLength() {
    if (this.zones)
      return this.zones.length;
    else
      return 0;
  }

  calculateDetails() {
    this.totalAnchorCost = 0;
    this.totalRefractoryCost = 0;
    this.totalCostEstimateZone = 0;
    var anchorBackup = 0;
    var anchorHotface = 0;
    var refractoryBackup = 0;
    var refractoryHotface = 0;
    if (this.anchorBackupGridData) {
      anchorBackup = this.findSum(this.anchorBackupGridData); //ensure it return numbers only
    }
    if (this.anchorHotfaceGridData) {
      anchorHotface = this.findSum(this.anchorHotfaceGridData); //ensure it return numbers only
    }
    this.totalAnchorCost = anchorBackup + anchorHotface;
    if (this.refractoryBackupGridData) {
      refractoryBackup = this.findSum(this.refractoryBackupGridData);
    }
    if (this.refractoryHotfaceGridData) {
      refractoryHotface = this.findSum(this.refractoryHotfaceGridData);
    }
    this.totalRefractoryCost = refractoryBackup + refractoryHotface;
    this.totalCostEstimateZone = this.totalAnchorCost + this.totalRefractoryCost;
  }

  findSum(data: any) {
    this.total = 0;
    this.rowValue = data
    for (let i = 0; i < data.length; i++) {
      this.total += this.rowValue[i].productCost
    }
    return this.total;
  }

  //#region Anchor details hotface grid
  anchorHotfaceGridHeader() {
    this.anchorHotfaceCols = [
      { field: 'product', header: 'Hotface Anchors', width: this.accessLevel > AccessLevel.Contractor ? '25%' : '30%' },
      { field: 'area', header: 'Area (SqM)', width: this.accessLevel > AccessLevel.Contractor ? '18%' : '20%', format: true },
      { field: 'anchorSpacing', header: 'Spacing', width: this.accessLevel > AccessLevel.Contractor ? '15%' : '20%' },
      { field: 'qty', header: 'Qty', width: this.accessLevel > AccessLevel.Contractor ? '8%' : '10%' },
      { field: 'productCost', header: 'Cost($)', width: this.accessLevel > AccessLevel.Contractor ? '10%' : '20%', format: true }];
  }
  //#endregion

  //#region Refractory details Backup grid
  refractoryBackupGridHeader() {
    this.refractoryBackupCols = [
      { field: 'product', header: 'Backup Product' },
      { field: 'area', header: 'Area (SqM)', format: true },
      { field: 'thickness', header: 'Thickness (mm)', format: true },
      { field: 'usageFactor', header: 'Usage Factor %', format: true },
      { field: 'volume', header: 'Volume (CuM)', format: true },
      { field: 'baseWeight', header: 'Base Wt (Kg)', format: true },
      { field: 'productCost', header: 'Cost($)', format: true }];
  }
  //#endregion

  //#region Refractory details hotface grid
  refractoryHotfaceGridHeader() {
    this.refractoryHotfaceCols = [
      { field: 'product', header: 'Hotface Product' },
      { field: 'area', header: 'Area (SqM)', format: true }, //qty acts as area for refractory 
      { field: 'thickness', header: 'Thickness (mm)', format: true },
      { field: 'usageFactor', header: 'Usage Factor %', format: true },
      { field: 'volume', header: 'Volume (CuM)', format: true },
      { field: 'baseWeight', header: 'Base Wt (Kg)', format: true },
      { field: 'productCost', header: 'Cost($)', format: true }];
  }
  //#endregion

  //validate if vessel and zone is selected or not
  validateFilterSelection(isVesselproduct?: boolean) {
    var isValid = true;
    var vessalName = this.selectedVessel ? this.selectedVessel.name : "";
    if (vessalName) {
      this.isVesselNameEmpty = false;
    } else {
      this.isVesselNameEmpty = true;
      isValid = false;
    }
    if (isVesselproduct == false) {
      var zone = this.selectedZone ? this.selectedVessel.name : "";
      if (zone) {
        this.isZoneNameEmpty = false;
      } else {
        this.isZoneNameEmpty = true;
        isValid = false;
      }
    }

    return isValid;
  }

  //#region nominal lining save
  nominalDialog = {
    opened: false,
    isProductEmpty: false,
    isAreaEmpty: false,
    isAreaGreaterThanRepairArea: "",
    isQtyEmpty: false,
    isSpacingEmpty: false,
    isProductCostEmpty: false,
    isUpdate: false
  }

  isRepairAreaEmpty: boolean = false;

  repairAreaChange(event: any) {
    if (event.value > 0) {
      this.isRepairAreaEmpty = false;
    } else {
      this.isRepairAreaEmpty = true;
      this.shellService.alert(this.dialogService, { title: 'Nominal Lining Absent', message: "Nominal lining Repair area is required.Please enter the Repair Area ", severity: 'error' })
    }
  }

  toggleNominalDialog(productType: string, productPosition: string, productName?: string, spacing?: string) {
    if (!this.validateFilterSelection()) {
      return;
    }
    if (this.repairArea > 0) {
      this.nominalDialog.opened = !this.nominalDialog.opened;
      this.DialogProductType = productType;
      this.DialogProductPosition = productPosition;
      var siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(false, false);
      if (!productName) { //productname will have data only at update time
        this.anchorRefractoryObj.area = this.repairArea;
      }
      if (productType == 'Anchor' && this.isProductDataLoaded == false) {
        this.loadProductDropdownData('Anchor', productName);
      } else {
        if (this.isProductDataLoaded == true) {
          this.bindAnchorProducts(productName);
        }
      }
      if (productType == 'Anchor' && this.isSpacingDataLoaded == false) {
        this.loadSpacingDropdownData(siteUnitOutage.countryId, 1, spacing);// 1 is enum code for spacing
      } else {
        if (this.isSpacingDataLoaded == true) {
          this.bindSelectedSpacing(spacing);
        }
      }
      if (this.isAnchorDensityThicknessCostPerTonneConversionDataLoaded == false) {
        this.getAnchorDensityThicknessCostPerTonneConversion(siteUnitOutage.countryId, 2); ////2 is enum code 
      }
      if (productType == 'Refractory' && this.isRefractoryProductDataLoaded == false) {
        this.loadProductDropdownData('Refractory', productName);
      } else {
        if (this.isRefractoryProductDataLoaded == true) {
          this.bindRefractoryProduct(productName);
        }
      }
    } else {
      this.isRepairAreaEmpty = true;
    }

  }

  bindAnchorProducts(productName?: string) {
    if (this.products) {
      let rowIndex = this.products.findIndex(x => x.name == productName);
      this.selectedProduct = this.products[rowIndex];
    }
  }

  bindRefractoryProduct(productName?: string) {
    if (this.refractoryProducts) {
      let rowIndex = this.refractoryProducts.findIndex(x => x.name == productName);
      this.selectedProduct = this.refractoryProducts[rowIndex];
    }
  }

  bindSelectedSpacing(spacing?: string) {
    if (this.spacings) {
      let rowIndex = this.spacings.findIndex(x => x.name == spacing);
      this.selectedSpacing = this.spacings[rowIndex];
    }
  }

  loadProductDropdownData(productType: string, productName?: string) {
    var siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(false, false);
    this.productService.getProductsByProductType(productType, siteUnitOutage.countryId).subscribe(response => {
      if (productType == 'Anchor') {
        this.products = [];
        this.products = response;
        this.isProductDataLoaded = true;
        if (productName) {
          this.bindAnchorProducts(productName);
        }
      }
      if (productType == 'Refractory') {
        this.refractoryProducts = [];
        this.refractoryProducts = response;
        this.isRefractoryProductDataLoaded = true;
        if (productName) {
          this.bindRefractoryProduct(productName);
        }
      }

    })
  }

  loadSpacingDropdownData(countryId: number, paramName: number, spacing?: string) {
    this.tempArray = [];
    this.spacings = [];
    this.appSetting.getSpacing(countryId, paramName).subscribe(response => {
      if (response) {
        for (var i = 0; i < response.length; i++) {
          this.tempArray.push([{ name: response[i] }]);
        }
        for (var val in this.tempArray) {
          this.spacings.push(this.tempArray[val][0]);
        }
      }
      if (spacing) {
        this.bindSelectedSpacing(spacing);
      }
      this.isSpacingDataLoaded = true;
    })
  }

  getAnchorDensityThicknessCostPerTonneConversion(countryId: number, paramName: number) {
    this.appSetting.getAppsettings(countryId, paramName).subscribe(response => {
      if (response) {
        this.anchorDensity = parseInt(response[0]);
        this.thicknessConversion = parseInt(response[1]);
        this.costPerTonne = parseInt(response[2]);
        this.isAnchorDensityThicknessCostPerTonneConversionDataLoaded = true;
      }
    })
  }

  loadAnchorBackupGrid() {
    this.anchorBackupGridData = [];
    var anchorBackupProductType = constProduct.anchorType;
    var anchorBackupProductPosition = constProduct.positionBackup;
    let anchorBackup = this.createSearchObj(anchorBackupProductType, anchorBackupProductPosition);
    this.nominalService.loadAnchorBackup(anchorBackup).subscribe(response => {
      if (response) {
        this.anchorBackupGridData = response;
      }
      this.calculateDetails();
    })
  }

  loadAnchorHotfaceGrid() {
    this.anchorHotfaceGridData = [];
    var anchorHotfaceProductType = constProduct.anchorType;
    var anchorHotfaceProductPosition = constProduct.positionHotface;
    let anchorHotface = this.createSearchObj(anchorHotfaceProductType, anchorHotfaceProductPosition);
    this.nominalService.loadAnchorHotface(anchorHotface).subscribe(response => {
      if (response) {
        this.anchorHotfaceGridData = response;
      }
      this.calculateDetails();
    })
  }

  loadRefractoryBackupGrid() {
    this.refractoryBackupGridData = [];
    var refractoryBackupProductType = constProduct.refractoryType;
    var refractoryBackupProductPosition = constProduct.positionBackup;
    let refractoryBackup = this.createSearchObj(refractoryBackupProductType, refractoryBackupProductPosition);
    this.nominalService.loadRefractoryBackup(refractoryBackup).subscribe(response => {
      if (response) {
        this.refractoryBackupGridData = response;
      }
      this.calculateDetails();
    })
  }

  loadRefractoryHotfaceGrid() {
    this.refractoryHotfaceGridData = [];
    var refractoryHotfaceProductType = constProduct.refractoryType;
    var refractoryHotfaceProductPosition = constProduct.positionHotface;
    let refractoryHotface = this.createSearchObj(refractoryHotfaceProductType, refractoryHotfaceProductPosition);
    this.nominalService.loadRefractoryHotface(refractoryHotface).subscribe(response => {
      if (response) {
        this.refractoryHotfaceGridData = response;
      }
      this.calculateDetails();
    })
  }
  /**
   * This method is used to save nominal lining details
   * @returns 
   */
  saveNominalLiningDetails() {
    if (!this.validateNominalDetails()) {
      return;
    }
    var siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(false, false);
    let anchorRefractoryViewModel: AnchorRefractoryViewModel = {
      id: 0,
      siteUnitId: siteUnitOutage.unitId,
      outageId: siteUnitOutage.outageId,
      vesselId: this.selectedVessel ? this.selectedVessel.id : 0,
      zoneId: this.selectedZone ? this.selectedZone.id : 0,
      productId: this.selectedProduct.id,
      productPosition: this.DialogProductPosition,
      area: this.anchorRefractoryObj.area,
      thickness: this.anchorRefractoryObj.thickness ? this.anchorRefractoryObj.thickness : 0,
      anchorSpacing: this.selectedSpacing ? this.selectedSpacing.name : 0,
      qty: this.anchorRefractoryObj.qty ? this.anchorRefractoryObj.qty : 0,
      productCost: this.anchorRefractoryObj.productCost,
      usageFactor: this.anchorRefractoryObj.usageFactor,
      volume: this.anchorRefractoryObj.volume,
      baseWeight: this.anchorRefractoryObj.baseWeight,
      active: true,
      createdDate: new Date()
    }
    this.shellService.showLoadingIndicator(true);
    this.nominalService.addAnchorRefractoryDetails(anchorRefractoryViewModel).subscribe(() => {
      this.shellService.showLoadingIndicator(false);
      this.isSpacingDataLoaded = false;
      this.isProductDataLoaded = false;
      this.nominalDialog.opened = false;
      this.shellService.alert(this.dialogService, { title: 'Success!', message: "Saved successfully!", severity: 'success' })
      this.resetNominalDetails();
      if (this.DialogProductType == 'Anchor' && anchorRefractoryViewModel.productPosition == 'Backup') {
        this.loadAnchorBackupGrid();
      }
      if (this.DialogProductType == 'Anchor' && anchorRefractoryViewModel.productPosition == 'Hotface') {
        this.loadAnchorHotfaceGrid();
      }
      if (this.DialogProductType == 'Refractory' && anchorRefractoryViewModel.productPosition == 'Backup') {
        this.loadRefractoryBackupGrid();
      }
      if (this.DialogProductType == 'Refractory' && anchorRefractoryViewModel.productPosition == 'Hotface') {
        this.loadRefractoryHotfaceGrid();
      }
      this.DialogProductType = "";
      this.DialogProductPosition = "";
    }, () => {
      this.shellService.showLoadingIndicator(false);
      this.DialogProductType = "";
      this.DialogProductPosition = "";
      this.isSpacingDataLoaded = false;
      this.isProductDataLoaded = false;
      this.nominalDialog.opened = false;
      this.resetNominalDetails();
    })
  }
  /**
   * This method is used to handle the nominal dialog control change
   * @param event 
   * @param type 
   * @param fieldName 
   */
  nominalDialogControlChange(event: any, type: string, fieldName: string) {
    var isQty = false;
    if (fieldName == 'area') {
      this.anchorRefractoryObj.area = event.value;
    }
    if (fieldName == 'qty') {
      this.anchorRefractoryObj.qty = event.value;
      isQty = true;
    }
    if (fieldName == 'thickness') {
      this.anchorRefractoryObj.thickness = event.value;
    }
    if (fieldName == 'usageFactor') {
      this.anchorRefractoryObj.usageFactor = event.value;
    }
    if (type == 'Anchor') {
      this.calculteAnchorDetails(isQty);
    }
    else if (type == 'Refractory') {
      this.calculateRefractoryDetails();
    }
  }

  calculteAnchorDetails(updateCostOnly: boolean) {
    debugger
    var product = this.selectedProduct ? this.selectedProduct.name : "";
    var area = this.anchorRefractoryObj.area;
    var spacing = this.selectedSpacing ? this.selectedSpacing.name : "";
    var quantity = this.anchorRefractoryObj.qty;
    var anchorDensity = this.anchorDensity ? this.anchorDensity : 0;

    var gcfFactor = 0;
    if (this.DialogProductPosition == 'Backup') {
      gcfFactor = this.zoneCalcFactor.bkGeometricCalcFactor;
    } else if (this.DialogProductPosition == 'Hotface') {
      gcfFactor = this.zoneCalcFactor.hfGeometricCalcFactor;
    }
    if (!gcfFactor || gcfFactor == 0) {
      gcfFactor = 1; //set default if there's no gcfFactor present
    }
    if (product && area && spacing) { //calculation will be done based on these field

      //Get X & Y spacing
      var spacingX = 0, spacingY = 0;
      var spacingArray = spacing.split('X');
      if (spacingArray.length >= 1) {
        spacingX = parseInt(spacingArray[0].trim());
        spacingY = parseInt(spacingArray[1].trim());
      }

      //Calculate density
      var spacingMultiplier = (spacingX * spacingY);
      var density = 0;
      if (spacingMultiplier != 0) {
        density = anchorDensity / (spacingX * spacingY);
      }

      //calculate quantity
      if (!updateCostOnly) {
        quantity = Math.floor(area * density * gcfFactor);
        this.anchorRefractoryObj.qty = quantity;
      }

      //Calculate cost
      var costPerUnit = 0;
      var productCost = quantity * costPerUnit;
      if (this.DialogProductType == 'Anchor') {
        if (this.products.length > 0) {
          var row = this.products.find(r => r.name == product);
          costPerUnit = row ? row.costPerUnit : 0;
        }
      } else {
        if (this.refractoryProducts.length > 0) {
          var row = this.refractoryProducts.find(r => r.name == product);
          costPerUnit = row ? row.costPerUnit : 0;
        }
      }
      if (costPerUnit != 0) {
        productCost = quantity * costPerUnit;
        this.anchorRefractoryObj.productCost = productCost ? productCost : 0;
      }
    }
  }

  calculateRefractoryDetails() {
    var product = this.selectedProduct ? this.selectedProduct.name : "";
    var area = this.anchorRefractoryObj.area;
    var thickness = this.anchorRefractoryObj.thickness ? this.anchorRefractoryObj.thickness : 0;
    var usageFactor = this.anchorRefractoryObj.usageFactor ? this.anchorRefractoryObj.usageFactor : 0;
    var thicknessConversion = this.thicknessConversion ? this.thicknessConversion : 0;

    var gcfFactor = 0;
    if (this.DialogProductPosition == 'Backup') {
      gcfFactor = this.zoneCalcFactor.bkGeometricCalcFactor;
    } else if (this.DialogProductPosition == 'Hotface') {
      gcfFactor = this.zoneCalcFactor.hfGeometricCalcFactor;
    }
    if (!gcfFactor || gcfFactor == 0) {
      gcfFactor = 1; //set default if there's no gcfFactor present
    }
    if (product && area > 0 && thickness > 0) { //calculation will be done based on these field
      //Calculate volume
      var thicknessFactor = 1;
      if (thicknessConversion != 0) {
        thicknessFactor = thickness / thicknessConversion;
      }
      var volume = area * gcfFactor * thicknessFactor;
      this.anchorRefractoryObj.volume = volume ? volume : 0;

      //Calculate weight
      var publishedDensity = 0;
      var costPerUnit = 0;
      if (this.refractoryProducts.length > 0) {
        var row = this.refractoryProducts.find(r => r.name == product);
        costPerUnit = row ? row.costPerUnit : 0;
        var val = row ? row.publishedDensity : 0;
        publishedDensity = val ? val : 0;
      }
      var weight = this.anchorRefractoryObj.volume * publishedDensity;
      if (usageFactor != 0) {
        weight = weight + ((weight * usageFactor) / 100);
      }
      weight = Math.floor(weight);
      this.anchorRefractoryObj.baseWeight = weight;

      //calculate cost
      if (costPerUnit) {
        var cost = weight * costPerUnit;
        cost = cost / this.costPerTonne;
        this.anchorRefractoryObj.productCost = cost ? cost : 0;
      }
    }
  }
  /**
   * This method is used to validate the nominal lining details
   * @returns 
   */
  validateNominalDetails() {
    // debugger
    var isValid = true;
    var product = this.selectedProduct ? this.selectedProduct.name : "";
    var area = this.anchorRefractoryObj.area ? this.anchorRefractoryObj.area : 0;
    if (product) {
      this.nominalDialog.isProductEmpty = false;
    } else {
      if (this.nominalDialog.isUpdate && !this.gridRowData.active) {
        this.nominalDialog.isProductEmpty = false;
      } else {
        this.nominalDialog.isProductEmpty = true;
        isValid = false;
      }
    }

    if (area) {
      if (this.repairArea < area) { //before checking this make sure we have repair area
        this.nominalDialog.isAreaGreaterThanRepairArea = "Area can not be greater than repair area";
        this.nominalDialog.isAreaEmpty = true;
        isValid = false;
      } else {
        this.nominalDialog.isAreaGreaterThanRepairArea = "";
        this.nominalDialog.isAreaEmpty = false;
      }
    } else {
      this.nominalDialog.isAreaEmpty = false;
      isValid = false;
    }
    return isValid;
  }

  /**
   * This method is used to reset nominal details
   */
  resetNominalDetails() {
    this.selectedProduct = [];
    this.selectedSpacing = [];
    this.anchorRefractoryObj = new AnchorRefractoryViewModel();
    this.nominalDialog.isProductEmpty = false;
    this.nominalDialog.isAreaEmpty = false;
    this.nominalDialog.isAreaGreaterThanRepairArea = "";
    this.nominalDialog.isUpdate = false;
  }

  //#region Anchor Refractory product delete

  deleteObj: any;
  deleteRow(obj: any, productType: string, productPosition: string) {
    this.deleteObj = {};
    this.deleteObj = {
      nominalId: obj.id,
      productType: productType,
      productPosition: productPosition
    }
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this product?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'aq-destructive ',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'aq-outline-primary',
      accept: () => {
        this.deleteConfirm();
      },
      reject: () => {
        this.deleteObj = {};
      }
    });
  }

  /**
   * This is method is used to get confirmation to delete 
   */
  deleteConfirm() {
    if (this.deleteObj.nominalId) {
      this.shellService.showLoadingIndicator(true);
      this.nominalService.deleteAnchorRefractoryDetails(this.deleteObj.nominalId).subscribe(() => {
        this.shellService.showLoadingIndicator(false);
        this.shellService.alert(this.dialogService, { title: 'Deleted!', message: "Product deleted successfully!", severity: 'success' })
        if (this.deleteObj.productType == constProduct.anchorType && this.deleteObj.productPosition == constProduct.positionBackup) {
          this.loadAnchorBackupGrid();
        }
        if (this.deleteObj.productType == constProduct.anchorType && this.deleteObj.productPosition == constProduct.positionHotface) {
          this.loadAnchorHotfaceGrid();
        }
        if (this.deleteObj.productType == constProduct.refractoryType && this.deleteObj.productPosition == constProduct.positionBackup) {
          this.loadRefractoryBackupGrid();
        }
        if (this.deleteObj.productType == constProduct.refractoryType && this.deleteObj.productPosition == constProduct.positionHotface) {
          this.loadRefractoryHotfaceGrid();
        }
        this.deleteObj = {};
      }, () => {
        this.shellService.showLoadingIndicator(false);
      })
    }
  }

  id: number = 0;
  /**
   * This method is used to edit row of the table
   * @param data 
   * @param type 
   */
  editRow(data: any, type: string) {
    this.nominalDialog.isUpdate = true;
    this.id = data.id;
    var productType = "";
    var productPosition = "";
    if (type == 'anchorBackup') {
      productType = constProduct.anchorType;
      productPosition = constProduct.positionBackup;
    }
    else if (type == 'anchorHotface') {
      productType = constProduct.anchorType;
      productPosition = constProduct.positionHotface;
    }
    else if (type == 'refractoryBackup') {
      productType = constProduct.refractoryType;
      productPosition = constProduct.positionBackup;
    }
    else if (type == 'refractoryHotface') {
      productType = constProduct.refractoryType;
      productPosition = constProduct.positionHotface;
    }
    var spacing = "";
    if (productType == 'Anchor') {
      this.anchorRefractoryObj.thickness = data.thickness;
      spacing = data.anchorSpacing;
    }
    if (productType == 'Refractory') {
      this.anchorRefractoryObj.thickness = data.thickness;
      this.anchorRefractoryObj.usageFactor = data.usageFactor;
      this.anchorRefractoryObj.volume = data.volume;
      this.anchorRefractoryObj.baseWeight = data.baseWeight;
    }
    this.anchorRefractoryObj.active = data.active;
    this.anchorRefractoryObj.qty = data.qty;
    this.anchorRefractoryObj.area = data.area;
    this.anchorRefractoryObj.productCost = data.productCost;
    this.toggleNominalDialog(productType, productPosition, data.product, spacing);
    this.setRowData(data.product, spacing);
  }

  //sets the original grid data
  gridRowData: any;
  setRowData(productName: string, spacing?: string) {
    this.gridRowData = {
      id: this.id,
      productName: productName,
      active: this.anchorRefractoryObj.active,
      thickness: this.anchorRefractoryObj.thickness,
      anchorSpacing: spacing ? spacing : "",
      area: this.anchorRefractoryObj.area,
      qty: this.anchorRefractoryObj.qty,
      productCost: this.anchorRefractoryObj.productCost,
      usageFactor: this.anchorRefractoryObj.usageFactor,
      volume: this.anchorRefractoryObj.volume,
      baseWeight: this.anchorRefractoryObj.baseWeight,
    }
  }

  //create array only for modified fields
  tempPatchArrayObj: any = [];
  bindNominalPatchObject(nominal: any) {
    this.tempPatchArrayObj = [];
    var patchArrayObj = [];
    if (nominal.id != this.gridRowData.id) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("id", nominal.id))
    }
    if (nominal.productName != this.gridRowData.productName && nominal.productName != "" && nominal.productId > 0) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("productId", nominal.productId))
    }
    if (nominal.qty != this.gridRowData.qty) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("qty", nominal.qty))
    }
    if (nominal.area != this.gridRowData.area) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("area", nominal.area))
    }
    if (nominal.productCost != this.gridRowData.productCost) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("productCost", nominal.productCost))
    }
    if (nominal.thickness != this.gridRowData.thickness) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("thickness", nominal.thickness))
    }

    if (this.DialogProductType == 'Anchor') {
      if (nominal.anchorSpacing != this.gridRowData.anchorSpacing) {
        this.tempPatchArrayObj.push(this.shellService.createPatchArray("anchorSpacing", nominal.anchorSpacing))
      }
    }
    if (this.DialogProductType == 'Refractory') {
      if (nominal.usageFactor != this.gridRowData.usageFactor) {
        this.tempPatchArrayObj.push(this.shellService.createPatchArray("usageFactor", nominal.usageFactor))
      }
      if (nominal.volume != this.gridRowData.volume) {
        this.tempPatchArrayObj.push(this.shellService.createPatchArray("volume", nominal.volume))
      }
      if (nominal.baseWeight != this.gridRowData.baseWeight) {
        this.tempPatchArrayObj.push(this.shellService.createPatchArray("baseWeight", nominal.baseWeight))
      }
    }
    this.tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))

    if (this.tempPatchArrayObj.length > 1) {
      for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
        patchArrayObj.push(this.tempPatchArrayObj[i][0]);
      }
    }
    return patchArrayObj;
  }
  /**
   * This method is used to update the nominal lining details
   * @returns 
   */
  updateNominalLiningDetails() {
    if (!this.validateNominalDetails()) {
      return;
    }
    let nominal = {
      id: this.id,
      productName: this.selectedProduct ? this.selectedProduct.name : "",
      productId: this.selectedProduct ? this.selectedProduct.id : 0,
      productPosition: this.DialogProductPosition,
      thickness: this.anchorRefractoryObj.thickness ? this.anchorRefractoryObj.thickness : 0,
      anchorSpacing: this.selectedSpacing ? this.selectedSpacing.name : 0,
      area: this.anchorRefractoryObj.area,
      qty: this.anchorRefractoryObj.qty,
      productCost: this.anchorRefractoryObj.productCost,
      usageFactor: this.anchorRefractoryObj.usageFactor,
      volume: this.anchorRefractoryObj.volume,
      baseWeight: this.anchorRefractoryObj.baseWeight,
    }
    var nominalViewModel = this.bindNominalPatchObject(nominal);
    if (nominalViewModel.length > 0) {
      this.shellService.showLoadingIndicator(true);
      this.nominalService.updateAnchorRefractoryDetails(nominal.id, nominalViewModel).subscribe(() => {
        this.shellService.showLoadingIndicator(false);
        this.isSpacingDataLoaded = false;
        this.isProductDataLoaded = false;
        this.nominalDialog.opened = false;
        this.shellService.alert(this.dialogService, { title: 'Success!', message: 'Updated successfully!', severity: 'success' })
        this.resetNominalDetails();
        if (this.DialogProductType == constProduct.anchorType && nominal.productPosition == constProduct.positionBackup) {
          this.loadAnchorBackupGrid();
        }
        if (this.DialogProductType == constProduct.anchorType && nominal.productPosition == constProduct.positionHotface) {
          this.loadAnchorHotfaceGrid();
        }
        if (this.DialogProductType == constProduct.refractoryType && nominal.productPosition == constProduct.positionBackup) {
          this.loadRefractoryBackupGrid();
        }
        if (this.DialogProductType == constProduct.refractoryType && nominal.productPosition == constProduct.positionHotface) {
          this.loadRefractoryHotfaceGrid();
        }
        this.DialogProductType = "";
        this.DialogProductPosition = "";
      }, () => {
        this.shellService.showLoadingIndicator(false);
        this.DialogProductType = "";
        this.DialogProductPosition = "";
        this.isSpacingDataLoaded = false;
        this.isProductDataLoaded = false;
        this.nominalDialog.opened = false;
        this.resetNominalDetails();
      })
    }
  }

  /**
   *  This method is used to check whether the repair area should be modifiable or not .
   *  @param {number} zoneId 
   */
  checkWhetherRepairAreaShouldBeModifiable(zoneId: number) {
    if (this.accessLevel >= AccessLevel.BasicUser) {
      let _zone = this.zones.filter((zone: any) => zone.id == zoneId)[0]
      this.isChildZone = _zone.isLeafNode
      if (this.isDataNotAvailable && _zone.isLeafNode) {
        this.repairArea = 0;
        this.disableStatus = false;
        this.shellService.alert(this.dialogService, { title: "Data Not Found", message: "Please enter nominal lining details for this zones", severity: "warning" })

      }
      else if (!this.isChildZone) {
        this.disableStatus = true
        this.shellService.alert(this.dialogService, { title: "Read Only Zone", message: "Since this zone is not present at the lowest level of zone hierarchy, you will not be able to edit the Repair Area of this zone ", severity: "warning" })
        return
      }
      else if (this.isChildZone) {
        this.disableStatus = this.repairArea <= 0 || this.isRepairAreaEmpty
        this.isAreaEntryNotRequired = true
        if (this.disableStatus)
          this.shellService.alert(this.dialogService, { title: "Nominal Lining Absent", message: 'Nominal Lining Repair is required. Please enter the Repair Area ', severity: 'error' })
      }
    }

  }

  //#endregion

  //#region Flatout
  openFlatout() {
    if (this.globalSiteUnitOutage) {
      var url = environment.apiConfig.flatoutUri + "upload?" +
        "siteId=" + this.globalSiteUnitOutage.siteId +
        "&unitId=" + this.globalSiteUnitOutage.unitId;

      if (this.selectedVessel) {
        url += "&vesselId=" + this.selectedVessel.id
      }
      window.open(url, "_blank");
    }
  }
  //#endregion

}
