import { NgClass, NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { TableModule } from 'primeng/table';
import {  SitePage } from '../../../shared/constants';
import { ShellService } from '../../../shared/services/shell/shell.service';
import { Country } from '../models/country.model';
import { Site } from '../models/site.model';
import { CountryService } from '../services/country.service';
import { SiteService } from '../services/site/site.service';
import { AccessLevel } from '../../../auth/app-roles';


@Component({
  selector: 'app-site',
  standalone: true,
  imports: [DropdownModule, TableModule, ConfirmDialogModule, FormsModule, NgFor, NgIf, NgStyle, NgClass, DialogModule, InputNumberModule, InputTextModule,InputSwitchModule],
  providers: [DialogService, ConfirmationService],
  templateUrl: './site.component.html',
  styleUrl: './site.component.scss'
})
export class SiteComponent implements OnInit {
  //#region variable declaration
  countries: Country[] = [];
  sites: Site[] = [];
  cols: any[] = [];
  first: number = 0;
  loading: boolean = false;
  selectedCountry: any;
  selectedFilterCountry: any;
  isFilterModeOn: boolean = false;
  siteFilterName: string = "";
  siteName: string = "";
  gangRate: number = 0;
  id: number = -1;
  isActive: boolean = true; //default is true
  deleteObj: any;
  accessLevel: AccessLevel=AccessLevel.NoAccess;
  country=SitePage.country;
  siteNameLabel=SitePage.siteName;
  gangRateLabel=SitePage.gangRate;
  
  constructor(private siteService: SiteService, private countryService: CountryService, private shellService: ShellService, private confirmationService: ConfirmationService, private dialogService: DialogService) { }

  ngOnInit(): void {
    this.loadSiteGridData();
    this.countryService.getCountries().subscribe(response => {
      this.countries = response;
    });
    this.accessLevel = this.shellService.getAppAccessLevel()
  }

  //initialize header
  headerInitialize() {
    this.cols = [
      { field: 'countryName', header: 'Country' },
      { field: 'name', header: 'Name' },
      { field: 'gangRate', header: 'Gang Rate' },
    ];
  }

  loadSiteGridData() {
    this.loading = true;
    this.siteService.getSites().subscribe(response => {
      if (response) {
        this.first = 0;
        this.sites = response;
      }
      this.headerInitialize();
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  //#endregion

  //#region filter methods
  filterRecords() {
    this.sites = [];
    var filterSites = {
      countryId: this.selectedFilterCountry ? this.selectedFilterCountry.id : 0,
      siteName: this.siteFilterName ? this.siteFilterName : "",
      active: this.isActive
    }
    this.siteService.searchSites(filterSites).subscribe(response => {
      if (response) {
        this.sites = response;
        this.headerInitialize();
      }
    })
  }

  resetFilterControls() {
    this.siteFilterName = "";
    this.selectedFilterCountry = [];
    this.isActive = true;
    this.loadSiteGridData();
  }

  showFilter() {
    this.isFilterModeOn = true;
  }

  closeFilter() {
    this.isFilterModeOn = false;
  }
  //#endregion

  //#region modal popup
  //#region modal popup
  siteDialog = {
    opened: false,
    isCountryEmpty: false,
    isSiteNameEmpty: false,
    isGangRateEmpty: false,
    isUpdate: false,
  }

  toggleSiteDialog() {
    this.siteDialog.opened = !this.siteDialog.opened;
    this.resetSiteDialog();
  }

  editRow(obj: any) {
    this.resetSiteDialog();
    this.siteDialog.opened = true;
    let rowIndex = this.countries.findIndex(x => x.name == obj.countryName);
    this.selectedCountry = this.countries[rowIndex];
    this.id = obj.id;
    this.siteName = obj.name;
    this.gangRate = obj.gangRate;
    this.siteDialog.isUpdate = true;
    this.setRowData();
  }

  resetSiteDialog() {
    this.siteName = "";
    this.gangRate = 0;
    this.selectedCountry = [];
    this.siteDialog.isCountryEmpty = false;
    this.siteDialog.isSiteNameEmpty = false;
    this.siteDialog.isGangRateEmpty = false;
    this.siteDialog.isUpdate = false;

  }

  saveSiteDetails() {
    debugger
    if (!this.validateSiteDialog()) {
      return;
    }
    let siteObj = {
      id: 0,
      name: this.siteName,
      gangRate: this.gangRate,
      countryName: this.selectedCountry ? this.selectedCountry.name : "",
      CountryId: this.selectedCountry ? this.selectedCountry.id : "",
      createdDate: new Date()
    }
    this.shellService.showLoadingIndicator(true);
    this.siteService.addSites(siteObj).subscribe(response => {
      this.shellService.showLoadingIndicator(false);
      this.toggleSiteDialog();
      // this.alertService.openAlert("Success!", "Saved Successfully!", "success");
      this.shellService.alert(this.dialogService,{title:'Success!',message:'Saved Successfully!',severity:'success'})
      this.filterRecords();
    }, error => {
      this.toggleSiteDialog();
      this.shellService.showLoadingIndicator(false);
    })
  }

  editSite() {
    if (!this.validateSiteDialog()) {
      return;
    }
    let site = {
      id: this.id,
      name: this.siteName,
      gangRate: this.gangRate,
      countryId: this.selectedCountry ? this.selectedCountry.id : "",
    }
    var siteViewModel = this.bindSitePatchObject(site);
    if (siteViewModel.length > 0) {
      this.shellService.showLoadingIndicator(true);
      this.siteService.patchUpdate(site.id, siteViewModel).subscribe(response => {
        this.toggleSiteDialog();
        this.filterRecords();
        this.shellService.showLoadingIndicator(false);
      }, error => {
        this.toggleSiteDialog();
        this.shellService.showLoadingIndicator(false);
      })
    }
  }

  deleteSite(id: number) {
    this.shellService.showLoadingIndicator(true);
    this.siteService.deleteSite(id).subscribe(response => {
      this.shellService.alert(this.dialogService,{title:'Success!',message:'Site deleted Successfully!',severity:'success'})
      this.filterRecords();
      this.shellService.showLoadingIndicator(false);
      let siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(true);
      if (siteUnitOutage.siteId == id) {
        this.shellService.resetConfig(true);
      }
    }, error => {
      this.shellService.showLoadingIndicator(false);
    })
  }

  //create array only for modified fields
  tempPatchArrayObj: any = [];
  bindSitePatchObject(site: any) {
    this.tempPatchArrayObj = [];
    var patchArrayObj = [];
    if (site.id != this.gridRowData.id) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("id", site.id))
    }
    if (site.name != this.gridRowData.name) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("name", site.name))
    }
    if (site.countryId != this.gridRowData.countryId) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("countryId", site.countryId))
    }
    if (site.gangRate != this.gridRowData.gangRate) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("gangRate", site.gangRate))
    }

    if (this.tempPatchArrayObj.length > 0) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))
      for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
        patchArrayObj.push(this.tempPatchArrayObj[i][0]);
      }
    }
    return patchArrayObj;
  }

  //sets the original grid data
  gridRowData: any;
  setRowData() {
    this.gridRowData = {
      id: this.id,
      name: this.siteName,
      gangRate: this.gangRate,
      countryId: this.selectedCountry ? this.selectedCountry.id : "",
    }
  }

  validateSiteDialog() {
    var isValid = true;
    let country = this.selectedCountry ? this.selectedCountry.name : "";
    if (country) {
      this.siteDialog.isCountryEmpty = false;
    } else {
      isValid = false;
      this.siteDialog.isCountryEmpty = true;
    }
    var siteName = this.siteName.trim();
    if (siteName) {
      this.siteDialog.isSiteNameEmpty = false;
    } else {
      this.siteDialog.isSiteNameEmpty = true;
      isValid = false;
    }
    if (this.gangRate > 0) {
      this.siteDialog.isGangRateEmpty = false;
    } else {
      this.siteDialog.isGangRateEmpty = true;
      isValid = false;
    }
    return isValid;
  }
  //#endregion

  deleteRow(obj: any) {
    this.deleteObj = {};
    this.deleteObj = {
      id: obj.id
    }
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this site? This will also delete the associated units, vessels links, zones links, outages and nominal lining.',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'aq-destructive',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'aq-outline-primary',
      accept: () => {
        this.deleteSite(this.deleteObj.id);
      }
    });
  }

  reactivateRow(obj: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to reactivate this site?',
      acceptLabel: 'Activate',
      acceptIcon: 'pi pi-chevron-circle-up',
      acceptButtonStyleClass: 'aq-success ',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'aq-outline-primary',
      accept: () => {
        var tempPatchArrayObj = [];
        var patchArrayObj = [];
        tempPatchArrayObj.push(this.shellService.createPatchArray("active", true))
        tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))

        for (var i = 0; i < tempPatchArrayObj.length; i++) {   //pushing the 0th
          patchArrayObj.push(tempPatchArrayObj[i][0]);
        }

        var siteViewModel = patchArrayObj;

        if (siteViewModel.length > 0 && obj.id) {
          this.siteService.patchUpdate(obj.id, siteViewModel).subscribe(response => {
            this.shellService.alert(this.dialogService, { title: 'Success!', message: 'Site activated successfully!', severity: 'success', icon: 'pi pi-check' })
            this.loadSiteGridData();
          }, error => {
            this.toggleSiteDialog();
          })
        }
      }
    });
  }
}
