// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.wizard-controls {
  background: #DEEAFF;
  height: 56px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  overflow-x: auto;
}

.internal-div {
  width: -moz-fit-content;
  width: fit-content;
}

.wizard-control {
  margin: 14px;
  margin-top: 16px;
  cursor: pointer;
  display: inline-block;
  outline: 0 !important;
}

.wizard-control-number {
  display: inline-block;
  color: #003C67;
  width: 1.5rem;
  height: 1.5rem;
  font-size: 1rem;
  text-align: center;
  border: 1px solid #003C67;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(71, 127, 167, 0);
  transition: box-shadow 1s ease;
}

.wizard-control.active > .wizard-control-number {
  box-shadow: 0 0 0 4px #477FA7;
}

.wizard-control-label {
  display: inline-block;
  color: #003C67;
  font-size: 0.94rem;
  margin-left: 12px;
  opacity: 0.4;
}

.wizard-control.active > .wizard-control-label {
  opacity: 1;
}

.wizard-control:hover > .wizard-control-label {
  opacity: 0.6;
}`, "",{"version":3,"sources":["webpack://./src/app/wizards/shutdown-execution/shutdown-execution.component.scss"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,YAAA;EACA,wCAAA;EACA,gBAAA;AACJ;;AAEE;EACE,uBAAA;EAAA,kBAAA;AACJ;;AAEE;EACE,YAAA;EACA,gBAAA;EACA,eAAA;EACA,qBAAA;EACA,qBAAA;AACJ;;AAEE;EACE,qBAAA;EACA,cAAA;EACA,aAAA;EACA,cAAA;EACA,eAAA;EACA,kBAAA;EACA,yBAAA;EACA,kBAAA;EACA,2CAAA;EACA,8BAAA;AACJ;;AAEE;EACE,6BAAA;AACJ;;AAEE;EACE,qBAAA;EACA,cAAA;EACA,kBAAA;EACA,iBAAA;EACA,YAAA;AACJ;;AAEE;EACE,UAAA;AACJ;;AAEE;EACE,YAAA;AACJ","sourcesContent":[".wizard-controls {\n    background: #DEEAFF;\n    height: 56px;\n    box-shadow: 0 2px 8px rgba(0,0,0,0.2);\n    overflow-x: auto;\n  }\n  \n  .internal-div {\n    width: fit-content;\n  }\n  \n  .wizard-control {\n    margin: 14px;\n    margin-top: 16px;\n    cursor: pointer;\n    display: inline-block;\n    outline: 0 !important;\n  }\n  \n  .wizard-control-number {\n    display: inline-block;\n    color: #003C67;\n    width: 1.5rem;\n    height: 1.5rem;\n    font-size: 1rem;\n    text-align: center;\n    border: 1px solid #003C67;\n    border-radius: 50%;\n    box-shadow: 0 0 0 1px #477FA700;\n    transition: box-shadow 1s ease;\n  }\n  \n  .wizard-control.active > .wizard-control-number {\n    box-shadow: 0 0 0 4px #477FA7;\n  }\n  \n  .wizard-control-label {\n    display: inline-block;\n    color: #003C67;\n    font-size: 0.94rem;\n    margin-left: 12px;\n    opacity: 0.4;\n  }\n  \n  .wizard-control.active > .wizard-control-label {\n    opacity: 1;\n  }\n  \n  .wizard-control:hover > .wizard-control-label {\n    opacity: 0.6;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
