import { EventEmitter, Injectable } from '@angular/core';
import { AppHttpHeaders } from '../../../../shared/services/common/app-http-headers';
import { catchError, Subject } from 'rxjs';
import { HttpClient } from '@angular/common/http';

import { Site } from '../../models/site.model';
import { Unit } from '../../models/unit.model';
import { ShellService } from '../../../../shared/services/shell/shell.service';

@Injectable({
  providedIn: 'root'
})
export class SiteService {
  appReqHeader = new AppHttpHeaders();
  siteAccessPointUrl: string = this.appReqHeader.domainURL + 'api/Site';
  //emit events to components which injected the service
  invokeError = new EventEmitter();
  invokeSuccess = new EventEmitter();
  refreshSiteGridSubject = new Subject<any>();

  constructor(private http: HttpClient, private shellService: ShellService) { }

  getStaticSites() {
    return this.http.get<any>('assets/site.json')
      .toPromise()
      .then(res => <any[]>res.data)
      .then(data => { return data; });
  }


  getUnitBySiteId(siteId: number) {
    var query = "";
    if (siteId != undefined) {
      query = "/" + siteId + "/Units";
    }
    return this.http.get<Unit[]>(this.siteAccessPointUrl + query, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getSites() {
     
    return this.http.get<Site[]>(this.siteAccessPointUrl, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getSiteById(siteId: number) {
    return this.http.get<Site>(this.siteAccessPointUrl + "/" + siteId.toString(), { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  searchSites(filterSites: any) {
    return this.http.post<Site[]>(this.siteAccessPointUrl + '/SearchSites', filterSites, { headers: this.appReqHeader.formHeaders}).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  addSites(siteObj: any) {
    return this.http.post<any>(this.siteAccessPointUrl, siteObj, { headers: this.appReqHeader.formHeaders}).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  patchUpdate(siteId: number, patchSiteViewModel: any) {
    var query = "";
    if (siteId) {
      query = "/" + siteId;
    }
    return this.http.patch<any>(this.siteAccessPointUrl + query, patchSiteViewModel, { headers: this.appReqHeader.formHeaders}).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  deleteSite(id: number,) {
    var query = "";
    if (id) {
      query = "/" + id;
    }
    return this.http.delete<any>(this.siteAccessPointUrl + query, { headers: this.appReqHeader.formHeaders}).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
}
