import { NgIf } from "@angular/common";
import { Component, OnInit, ChangeDetectorRef } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { RouterModule, ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { MsalModule, MsalService, MsalBroadcastService } from "@azure/msal-angular";
import { AccountInfo, InteractionStatus } from "@azure/msal-browser";
import { MessageService, PrimeNGConfig } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { DialogModule } from "primeng/dialog";
import { DropdownModule } from "primeng/dropdown";
import { DialogService } from "primeng/dynamicdialog";
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { Subject, Subscription, interval } from "rxjs";
import { environment } from "../environments/environment";
import { publicClientApp } from "./auth/app-msal-client-app";
import { findAppRoleFromAccountInfo, AccessLevel } from "./auth/app-roles";
import { authScopes } from "./auth/auth-config";
import { ReportsPageComponent } from "./module/reports-page/reports-page.component";
import { Outage } from "./module/utilities/models/outage.model";
import { Site } from "./module/utilities/models/site.model";
import { Unit } from "./module/utilities/models/unit.model";
import { SiteService } from "./module/utilities/services/site/site.service";
import { UnitService } from "./module/utilities/services/unit/unit.service";
import { FooterComponent } from "./shared/components/footer/footer.component";
import { HeaderbarComponent } from "./shared/components/headerbar/headerbar.component";
import { SidebarComponent } from "./shared/components/sidebar/sidebar.component";
import { ShellService } from "./shared/services/shell/shell.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [MsalModule, HeaderbarComponent, ProgressSpinnerModule, ReportsPageComponent, RouterModule, SidebarComponent, NgIf, DropdownModule, FormsModule, DialogModule, FooterComponent, ButtonModule],
  providers: [MessageService, ShellService, DialogService],
})
export class AppComponent implements OnInit {
  title = 'Refractory Management System';
  isIframe = false;
  sideBarVisible: boolean = false;
  loginDisplay = false;
  isOffline: boolean = false;
  failureCount: number = 0;
  private readonly _destroying$ = new Subject<void>();

  loggedinUserName: string = 'RMS USER';
  _profileInitials: string = 'USER';
  userImage: boolean = true;
  responseData: string = '';
  errorMessage: string = '';
  currentUrl = '';

  /*Page loading variables */
  public loading: boolean = false;
  public launcherExpand: boolean = true;

  // Global Parameters
  isSiteUnitOutageModalOpen = false;
  showGlobalOutageDropdown = true;
  modalLoading = false;
  modalUnitsLoading = false;
  modalOutagesLoading = false;

  globalSites: Site[] = [];
  globalUnits: Unit[] = [];
  globalOutages: Outage[] = [];

  selectedGlobalSite: Site | any;
  selectedGlobalUnit: Unit | any;
  selectedGlobalOutage: Outage | any;

  signingIn: boolean = false;
  authenticated: boolean = false;

  // new
  initialized: boolean = false;
  user: AccountInfo | undefined;

  private msalSubscription: Subscription = null!;

  constructor(
    private msal: MsalService,
    private msalBroadcast: MsalBroadcastService,

    private activatedRoute: ActivatedRoute,
    private shellService: ShellService,
    // public translate: TranslateService,
    private primengConfig: PrimeNGConfig,
    // For data
    private siteService: SiteService,
    private unitService: UnitService,
    private messageService: MessageService,
    private router: Router,
    private changeDetector: ChangeDetectorRef,
    private dialogService: DialogService
  ) {
    this.isOffline = false;
    this.sideBarVisible = this.shellService.isSidebarVisible;

    this.shellService.sidebarVisibilityChange.subscribe((value) => {
      this.sideBarVisible = value;
    });

    // translate.addLangs(['en', 'fr']);
    // translate.setDefaultLang('en');
    // this.translate.use("en");

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }

  /**
   * This method is used to initialize MSAL Authentication
   */
  async initializeAuthentication() {
    // debugger
    if (!this.initialized) {
      await publicClientApp.initialize();
      this.initialized = true;

      const accounts = this.msal.instance.getAllAccounts();
      this.authenticated = accounts.length > 0;
      if (this.authenticated) {
        this.user = accounts[0];
        this.shellService.setGlobalUser(this.user);
        this.shellService.setAppAccessLevel(findAppRoleFromAccountInfo(this.user))
      }

      this.msalSubscription = this.msalBroadcast.inProgress$.subscribe(
        (status: InteractionStatus) => {
          if (
            (status === InteractionStatus.Startup ||
              status === InteractionStatus.None) &&
            !this.signingIn &&
            !this.authenticated
          ) {
            this.signIn();
          }
        }
      );
    }
  }
  async ngOnInit(): Promise<void> {
    this.isIframe = window !== window.parent && !window.opener;
    this.primengConfig.ripple = true;
    await this.initializeAuthentication().then(() => {
      this.shellService.loadGlobalSiteUnitOutage();
      this.shellService.globalLoadingSubject.subscribe((showLoading) => {
        this.loading = showLoading;
        this.changeDetector.detectChanges()
      });
      this.shellService.globalSiteUnitOutageUnset.subscribe((showOutage) => {
        this.showGlobalOutageDropdown = showOutage;
        this.openSiteUnitOutageDialog();
      });

      this.pingCycle();
    });
  }

  private swipeCoord: [number, number] = [0, 0];
  private swipeTime: number = 0;

  swipe(e: TouchEvent, when: string): void {
    const coord: [number, number] = [
      e.changedTouches[0].clientX,
      e.changedTouches[0].clientY,
    ];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const direction = [
        coord[0] - this.swipeCoord[0],
        coord[1] - this.swipeCoord[1],
      ];
      const duration = time - this.swipeTime;

      // Check horizontal swipe
      if (
        duration < 1000 &&
        Math.abs(direction[0]) > 30 &&
        Math.abs(direction[0]) > Math.abs(direction[1] * 3)
      ) {
        if (this.shellService.getAppAccessLevel() > AccessLevel.NoAccess) {
          this.shellService.toggleSidebarVisibility(direction[0] < 0);
        }

      }
    }
  }

  public getData() {
    this.loading = true;
    this.changeDetector.detectChanges()
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  async signIn(): Promise<void> {
    this.signingIn = true;

    await this.msal.instance.handleRedirectPromise().then((result)=>{
      if (result){
        this.authenticated=true
        this.user=result.account
        if (result.account){
          this.shellService.setAppAccessLevel(findAppRoleFromAccountInfo(this.user))
        }
        this.signingIn=false
        this.redirectToHome()
      }
    });

    const accounts = this.msal.instance.getAllAccounts();
    if (accounts.length === 0) {
      // No user signed in
      this.msal.instance.loginRedirect({scopes:authScopes});
    }
  }

  redirectToHome() { 
    this.router.navigate([this.router.url]).then(()=>{
      window.location.reload()
    })
  }

  // to switch or change language
  switchLang(lang: string) {
    // this.translate.use(lang);
  }

  showLauncher() {
    this.launcherExpand = true;
  }

  hideLauncher() {
    this.launcherExpand = false;
  }

  loadMenu() { }

  closeSideBar(): void {
    this.shellService.toggleSidebarVisibility();
  }

  onGlobalSiteChange(site: any): void {
    this.selectedGlobalUnit = undefined;
    this.selectedGlobalOutage = undefined;
    this.globalUnits = [];
    this.globalOutages = [];
    this.modalUnitsLoading = true
    this.siteService.getUnitBySiteId(site.id).subscribe((response: any) => {
      for (var unit in response) {
        this.globalUnits.push(response[unit]);
      }
      let unitId = Number(
        this.activatedRoute.snapshot.queryParamMap.get('unitId')
      );
      this.modalUnitsLoading = false
      if (unitId > 0) {
        let requestedUnit = this.globalUnits.find((unit) => {
          return unit.unitId === unitId;
        });
        if (requestedUnit) {
          this.selectedGlobalUnit = requestedUnit;
          this.onGlobalUnitChange(requestedUnit);
        }
        if (
          this.currentUrl.includes('calciner-setup/nominal-lining') ||
          this.currentUrl.includes('shutdown-execution/inspection')
        ) {
          if (
            this.activatedRoute.snapshot.queryParamMap.get('vesselId') ==
            null &&
            this.activatedRoute.snapshot.queryParamMap.get('outageId') == null
          ) {
            var qIndex = this.currentUrl.indexOf('?');
            console.log(this.currentUrl.substring(0, qIndex));
            window.history.replaceState(
              {},
              document.title,
              '/#' + this.currentUrl.substring(0, qIndex)
            );
          }
        } else {
          var qIndex = this.currentUrl.indexOf('?');
          console.log(this.currentUrl.substring(0, qIndex));
          window.history.replaceState(
            {},
            document.title,
            '/#' + this.currentUrl.substring(0, qIndex)
          );
        }
      }
    });
    this.shellService.setSite(site);
  }

  onGlobalUnitChange(unit: any): void {
    this.selectedGlobalOutage = undefined;
    this.modalOutagesLoading = true
    if (this.showGlobalOutageDropdown) {
      this.globalOutages = [];
      this.unitService
        .getOutageByUnitId(unit.unitId)
        .subscribe((response: any) => {
          for (var outage in response) {
            if (
              this.currentUrl === '/estimate-refractory-materials' ||
              this.currentUrl ===
              '/future-outage-setup/estimate-refractory-materials'
            ) {
              var checkOutageName = response[outage].name.split('-');
              var unitoutageNameType = checkOutageName[1].trim();

              if (unitoutageNameType != 'Nominal Lining') {
                this.globalOutages.push(response[outage]);
              }
            } else {
              this.globalOutages.push(response[outage]);
            }
          }
          this.modalOutagesLoading = false
          let queryOutage = Number(
            this.activatedRoute.snapshot.queryParamMap.get('outageId')
          );
          if (queryOutage > 0) {
            let requiredOutage = this.globalOutages.find((outage) => {
              return outage.id === queryOutage;
            });
            if (requiredOutage) {
              this.selectedGlobalOutage = requiredOutage;
              this.onGlobalOutageChange(requiredOutage);
            }
            if (
              this.currentUrl.includes('calciner-setup/nominal-lining') ||
              this.currentUrl.includes('shutdown-execution/inspection')
            ) {
              if (
                this.activatedRoute.snapshot.queryParamMap.get('vesselId') ==
                null
              ) {
                var qIndex = this.currentUrl.indexOf('?');
                console.log(this.currentUrl.substring(0, qIndex));
                window.history.replaceState(
                  {},
                  document.title,
                  '/#' + this.currentUrl.substring(0, qIndex)
                );
              }
            } else {
              var qIndex = this.currentUrl.indexOf('?');
              console.log(this.currentUrl.substring(0, qIndex));
              window.history.replaceState(
                {},
                document.title,
                '/#' + this.currentUrl.substring(0, qIndex)
              );
            }
          }
        });
    }
    this.shellService.setUnit(unit);
  }

  onGlobalOutageChange(outage: any): void {
    if (this.showGlobalOutageDropdown) {
      this.shellService.setOutage(outage);
    }
  }

  openSiteUnitOutageDialog(): void {
    if (!this.isSiteUnitOutageModalOpen) {
      this.modalLoading = true;
      this.isSiteUnitOutageModalOpen = true;
      this.changeDetector.detectChanges()

      this.siteService.getSites().subscribe((response: any) => {
        this.selectedGlobalSite = undefined;
        this.globalSites = [];
        this.selectedGlobalUnit = undefined;
        this.globalUnits = [];
        this.selectedGlobalOutage = undefined;
        this.globalOutages = [];
        for (var site in response) {
          this.globalSites.push(response[site]);
        }
        let siteId = Number(
          this.activatedRoute.snapshot.queryParamMap.get('siteId')
        );
        if (siteId > 0) {
          let requestedSite = this.globalSites.find((site) => {
            return site.id === siteId;
          });
          if (requestedSite) {
            this.selectedGlobalSite = requestedSite;
            this.onGlobalSiteChange(requestedSite);
          }
          if (
            this.currentUrl.includes('calciner-setup/nominal-lining') ||
            this.currentUrl.includes('shutdown-execution/inspection')
          ) {
            if (
              this.activatedRoute.snapshot.queryParamMap.get('vesselId') ==
              null &&
              this.activatedRoute.snapshot.queryParamMap.get('outageId') ==
              null &&
              this.activatedRoute.snapshot.queryParamMap.get('unitId') == null
            ) {
              var qIndex = this.currentUrl.indexOf('?');
              console.log(this.currentUrl.substring(0, qIndex));
              window.history.replaceState(
                {},
                document.title,
                '/#' + this.currentUrl.substring(0, qIndex)
              );
            }
          } else {
            var qIndex = this.currentUrl.indexOf('?');
            console.log(this.currentUrl.substring(0, qIndex));
            window.history.replaceState(
              {},
              document.title,
              '/#' + this.currentUrl.substring(0, qIndex)
            );
          }
        }
        this.modalLoading = false;
      });



    }
  }

  closeSiteUnitOutageDialog(): void {
    this.isSiteUnitOutageModalOpen = false;
    this.changeDetector.detectChanges()
    this.shellService.siteUnitOutageModalClosed();
    window.location.reload();
  }

  pingCycle(): void {
    if (environment.envName != 'Local') {
      interval(30000).subscribe(() => {
        this.ping();
      });
    }
  }

  ping(): void {
    try {
      var gotResponse = false;
      if (environment.envName != 'Prod' && environment.envName != 'Test') {
        console.log('Pinging API server...');
      }
      var currentTime = new Date().getTime();
      var sub = this.shellService.ping().subscribe(
        (res) => {
          if (environment.envName != 'Prod' && environment.envName != 'Test') {
            console.log(
              'Request reached API server after ' +
              (new Date(res as string).getTime() - currentTime) +
              'ms'
            );
          }
          gotResponse = true;
          this.isOffline = false;
        },
        (err) => {
          if (environment.envName != 'Prod') {
            console.log(err);
          }
          if (err) {
            this.isOffline = err.status != 200;
          }
          gotResponse = !this.isOffline;
        },
        () => {
          if (environment.envName != 'Prod') {
            console.log(
              'Got response after ' +
              (new Date().getTime() - currentTime) +
              'ms'
            );
          }
          gotResponse = true;
        }
      );
      setTimeout(() => {
        sub.unsubscribe();
        if (!gotResponse) {
          if (environment.envName != 'Prod') {
            console.log('Got no response from API server');
          }
          this.failureCount++;
          if (this.failureCount > 2) {
            this.isOffline = true;
          }
        } else {
          this.failureCount = 0;
        }
      }, 10000);
    } catch (e) {
      if (environment.envName != 'Prod') {
        console.log('Got error response ' + e);
      }
      this.failureCount++;
    }
  }
}
