export const environment = {
  envName: 'Dev',
  production: false,
  msalConfig: {
    auth: {
      clientId: 'e794d084-5578-44e1-86e1-78218cf542ed',
      tenantId: '37a72eb7-1faf-49d3-9ddc-6b923b751bc0',
      authority: 'https://login.microsoftonline.com/37a72eb7-1faf-49d3-9ddc-6b923b751bc0/',
      redirectUri: '/'
    }
  },
  apiConfig: {
    scopes: ['https://rms.devapi.alcoa.com/User_Impersonation'],
    uri: 'https://rms.devapi.alcoa.com/',
    flatoutUri: 'https://rmsdf.devapps.alcoa.com/#/',

  },
  version: "1.0.1",
  report_maps: [
    {
      "uri": "nominal-lining-summary",
      "id": "1a503a25-9fbe-465b-a7a2-6cb31ba16762"
    },
    {
      "uri": "calciner-information",
      "id": "e854554e-71d3-4a3a-b3c7-b788e5f10fb1"
    },
    {
      "uri": "estimates-vs-actual",
      "id": "95a799f7-76b1-4cc6-99d3-f0aa884c307c",
      "showCostOption": true
    },
    {
      "uri": "estimates",
      "id": "e9f2f076-cd4e-4fcf-a92f-8e3b8b376550",
      "showCostOption": true
    },
    {
      "uri": "estimate-material-summary",
      "id": "84e9e228-f543-45c3-9d87-9cb0bf48df06",
      "showCostOption": true
    },
    {
      "uri": "inspection-comments",
      "id": "4ec957e3-587e-43a5-91d0-23063f1d6f10"
    },
    {
      "uri": "outage-cost",
      "id": "7e8c4e1a-4b06-46ba-a79d-97afb3f56ad6"
    },
    {
      "uri": "anchor-refractory-product",
      "id": "9286e5d6-9021-4505-9cb1-4e2b560d07f2"
    }
  ]
}