export const constDateObj = {
  dateFormat: "MM/dd/yyyy"
}

export const defaultOutageType: number = 2

export const constProduct = {
  anchorType: "Anchor",
  refractoryType: "Refractory",
  positionBackup: "Backup",
  positionHotface: "Hotface",
}

export const SitePage={
  country:'Country',
  siteName:'Site Name',
  gangRate:'Gang Rate :'
}

export const errorMessage = {
  msalTimeout: "MSAL authorization timed out. RMS Access Denied! Please contact support.",
  rolesUndefined: "You do not currently have access to RMS. Please contact the administrator for access",
  unauthorized: "You do not have access as expected role is missing. Please ensure that your account is assigned to an app role and then sign-out and sign-in again.",
  notFound: "Lost? Redirecting to Home..."
}

export const ProductTypeCode = {
  Anchor: 1,
  Refractory: 2
}

export const FlatoutMessages={
  uploader:'Open Flatout Uploader',
  editor:'Open Flatout Editor',
  noUploaderAccess:"You don't have access to Flatout Uploader"

}

export const Header = {
  "welcome": "Welcome",
  "pageTitle": "Refractory Management System",
  "pageTitleAbbreviated": "RMS",
  "country": "Country",
  "site": "Site",
  "outageDate": "Outage Date",
  "flatout": "Flatout",
  "newUser": "New User",
  "noRoleTagged": "No Role Tagged"
}


export const menuModel = [
  {
    name: "Data Entry",
    icon: "data-entry",
    routerLink: null,
    allowedRoles: null,
    children: [
      {
        name: "Equipment Setup",
        routerLink: "/calciner-setup/*",
        wizardLink: "/calciner-setup/site",
        allowedRoles: null,
        children: [
          {
            name: "Site",
            routerLink: "/site",
            allowedRoles: null
          },
          {
            name: "Site-Unit",
            routerLink: "/unit",
            allowedRoles: [
              "Administrator", "Basic User", "Contractor"
            ]
          },
          {
            name: "Site-Unit-Vessel",
            routerLink: "/unit-vessel",
            allowedRoles: null
          },
          {
            name: "Site-Unit-Vessel-Zone",
            routerLink: "/unit-vessel-zone",
            allowedRoles: null
          },
          {
            name: "Nominal Lining",
            routerLink: "/nominal-lining",
            allowedRoles: null
          },
          {
            name: "Flatout Uploader",
            routerLink: "/flatout-uploader",
            allowedRoles: null
          }
        ]
      },
      {
        name: "Outage Setup",
        routerLink: "/future-outage-setup/*",
        wizardLink: "/future-outage-setup/outage",
        allowedRoles: null,
        children: [
          {
            name: "Outages",
            routerLink: "/outage",
            allowedRoles: null
          },
          {
            name: "Estimate Refractory Materials",
            routerLink: "/estimate-refractory-materials",
            allowedRoles: null
          }
        ]
      },
      {
        name: "Shutdown Execution",
        routerLink: "/shutdown-execution/*",
        wizardLink: "/shutdown-execution/inspection",
        allowedRoles: null,
        children: [
          {
            name: "Inspection Comments",
            routerLink: "/inspection",
            allowedRoles: null
          },
          {
            name: "Flatout Editor",
            routerLink: "/flatout-editor",
            allowedRoles: null
          },
          {
            name: "Bulk Upload",
            routerLink: "/bulk-loader",
            allowedRoles: null
          },
          {
            name: "Daily Usage by Installation",
            routerLink: "/dailyusage",
            allowedRoles: null
          }
        ]
      },
      {
        name: "Shutdown Review",
        routerLink: "/shutdown-review/*",
        wizardLink: "/shutdown-review/estimates-vs-actual",
        allowedRoles: null,
        children: [
          {
            name: "Estimate vs Actual Report",
            routerLink: "/reports/estimates-vs-actual",
            allowedRoles: null
          },
          {
            name: "Outage Cost Report",
            routerLink: "/reports/outage-cost",
            allowedRoles: null
          }
        ]
      }
    ]
  },
  {
    name: "Utilities",
    icon: "utilities",
    routerLink: null,
    allowedRoles: null,
    children: [
      {
        name: "Vessels",
        routerLink: "/vessel",
        allowedRoles: null,
        children: null
      },
      {
        name: "Zones",
        routerLink: "/zone",
        allowedRoles: null,
        children: null
      },
      {
        name: "Vendors",
        routerLink: "/vendor",
        allowedRoles: null,
        children: null
      },
      {
        name: "Product Type",
        routerLink: "/product-type",
        allowedRoles: null,
        children: null
      },
      {
        name: "Product Information",
        routerLink: "/product",
        allowedRoles: null,
        children: null
      },
      {
        name: "Product Categories",
        routerLink: "/category-product",
        allowedRoles: null,
        children: null
      }
    ]
  },
  {
    name: "Reports",
    icon: "report",
    routerLink: null,
    allowedRoles: null,
    children: [
      {
        name: "Nominal Lining Summary",
        routerLink: "/reports/nominal-lining-summary",
        allowedRoles: null,
        children: null
      },
      {
        name: "Estimates BOM",
        routerLink: "/reports/estimates",
        allowedRoles: null,
        children: null
      },
      {
        name: "Estimates Material",
        routerLink: "/reports/estimate-material-summary",
        allowedRoles: null,
        children: null
      },
      {
        name: "Estimates vs Actual",
        routerLink: "/reports/estimates-vs-actual",
        allowedRoles: null,
        children: null
      },
      {
        name: "Inspection Comments",
        routerLink: "/reports/inspection-comments",
        allowedRoles: null,
        children: null
      },
      {
        name: "Outage Cost Report",
        routerLink: "/reports/outage-cost",
        allowedRoles: null,
        children: null
      },
      {
        name: "Anchor/Refractory Product",
        routerLink: "/reports/anchor-refractory-product",
        allowedRoles: null,
        children: null
      },
      {
        name: "Alcoa Calciner Information",
        routerLink: "/reports/calciner-information",
        allowedRoles: null,
        children: null
      }
    ]
  }
]




