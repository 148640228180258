import { Routes } from '@angular/router';
import { HomeComponent } from './module/home/home/home.component';
import { ReportsPageComponent } from './module/reports-page/reports-page.component';
import { BulkUploaderComponent } from './module/utilities/bulk-uploader/bulk-uploader.component';
import { CategoryProductComponent } from './module/utilities/category-product/category-product.component';
import { DailyUsageComponent } from './module/utilities/daily-usage/daily-usage.component';
import { EstimateRefractoryMaterialsComponent } from './module/utilities/estimate-refractory-materials/estimate-refractory-materials.component';
import { FlatoutEditorComponent } from './module/utilities/flatout-editor/flatout-editor.component';
import { FlatoutUploaderComponent } from './module/utilities/flatout-uploader/flatout-uploader.component';
import { InspectionCommentsComponent } from './module/utilities/inspection-comments/inspection-comments.component';
import { NominalLiningComponent } from './module/utilities/nominal-lining/nominal-lining.component';
import { OutageComponent } from './module/utilities/outage/outage.component';
import { ProductInformationComponent } from './module/utilities/product-information/product-information.component';
import { ProductTypeComponent } from './module/utilities/product-type/product-type.component';
import { SiteComponent } from './module/utilities/site/site.component';
import { UnitVesselZoneComponent } from './module/utilities/unit-vessel-zone/unit-vessel-zone.component';
import { UnitVesselComponent } from './module/utilities/unit-vessel/unit-vessel.component';
import { UnitComponent } from './module/utilities/unit/unit.component';
import { VendorComponent } from './module/utilities/vendor/vendor.component';
import { VesselsComponent } from './module/utilities/vessels/vessels.component';
import { ZoneComponent } from './module/utilities/zone/zone.component';
import { AccessDeniedComponent } from './shared/error-pages/access-denied/access-denied.component';
import { NotFoundComponent } from './shared/error-pages/not-found/not-found.component';
import { CalcinerSetupComponent } from './wizards/calciner-setup/calciner-setup.component';
import { FutureOutageSetupComponent } from './wizards/future-outage-setup/future-outage-setup.component';
import { ShutdownExecutionComponent } from './wizards/shutdown-execution/shutdown-execution.component';
import { ShutdownReviewComponent } from './wizards/shutdown-review/shutdown-review.component';

export const routes: Routes = [
    { path: '', component: HomeComponent, pathMatch: 'full', },
    { path: '403', component: AccessDeniedComponent },
    { path: 'reports/:uri', component: ReportsPageComponent, pathMatch: "full" },
    { path: 'site', component: SiteComponent, pathMatch: "full" },
    { path: 'unit', component: UnitComponent, pathMatch: 'full' },
    { path: 'unit-vessel', component: UnitVesselComponent, pathMatch: 'full' },
    { path: 'unit-vessel-zone', component: UnitVesselZoneComponent, pathMatch: 'full' },
    { path: 'nominal-lining', component: NominalLiningComponent, pathMatch: 'full' },
    { path: 'flatout-uploader', component: FlatoutUploaderComponent, pathMatch: 'full' },
    { path: 'outage', component: OutageComponent, pathMatch: 'full' },
    { path: 'estimate-refractory-materials', component: EstimateRefractoryMaterialsComponent, pathMatch: 'full' },
    { path: 'inspection', component: InspectionCommentsComponent, pathMatch: 'full' },
    { path: 'flatout-editor', component: FlatoutEditorComponent, pathMatch: 'full' },
    { path: 'bulk-loader', component: BulkUploaderComponent, pathMatch: 'full' },
    { path: 'dailyusage', component: DailyUsageComponent, pathMatch: 'full' },
    { path: 'vessel', component: VesselsComponent, pathMatch: 'full' },
    { path: 'zone', component: ZoneComponent, pathMatch: 'full' },
    { path: 'vendor', component: VendorComponent, pathMatch: 'full' },
    { path: 'product-type', component: ProductTypeComponent, pathMatch: 'full' },
    { path: 'product', component: ProductInformationComponent, pathMatch: 'full' },
    { path: 'category-product', component: CategoryProductComponent, pathMatch: 'full' },
    // Wizards
    { path: 'calciner-setup/:comp', component: CalcinerSetupComponent, pathMatch: 'full' },
    { path: 'future-outage-setup/:comp', component: FutureOutageSetupComponent, pathMatch: "full" },
    { path: 'shutdown-execution/:comp', component: ShutdownExecutionComponent, pathMatch: "full", },
    { path: 'shutdown-review/:comp', component: ShutdownReviewComponent, pathMatch: "full" },
    // error
    { path: '*', component: NotFoundComponent, pathMatch: 'full' },
    {path:'**' ,redirectTo:''}


];

