// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.content-inner {
  text-align: center;
  display: flex;
  height: calc(100vh - 100px);
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  animation: showDotPattern 3s ease-out forwards;
}

.dot-pattern {
  background-image: url('dotpattern.60aebd09932249d7.png');
}

@keyframes showDotPattern {
  from {
    -webkit-backdrop-filter: blur(12px);
            backdrop-filter: blur(12px);
  }
  to {
    -webkit-backdrop-filter: blur(0px);
            backdrop-filter: blur(0px);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/module/utilities/flatout-uploader/flatout-uploader.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,aAAA;EACA,2BAAA;EACA,qBAAA;EACA,uBAAA;EACA,eAAA;EACA,mBAAA;EACA,8CAAA;AACJ;;AAEE;EACE,wDAAA;AACJ;;AAEE;EACE;IACE,mCAAA;YAAA,2BAAA;EACJ;EAAI;IACA,kCAAA;YAAA,0BAAA;EAEJ;AACF","sourcesContent":[".content-inner {\n    text-align: center;\n    display: flex;\n    height: calc(100vh - 100px);\n    align-content: center;\n    justify-content: center;\n    flex-wrap: wrap;\n    align-items: center;\n    animation: showDotPattern 3s ease-out forwards;\n  }\n  \n  .dot-pattern {\n    background-image: url(../../../../assets/images/dotpattern.png); \n  }\n  \n  @keyframes showDotPattern {\n    from {\n      backdrop-filter: blur(12px);\n    } to {\n      backdrop-filter: blur(0px);\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
