import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, forkJoin } from 'rxjs';
import { AnchorRefractoryViewModel } from '../../models/nominalLining.model';
import { AppHttpHeaders } from '../../../../shared/services/common/app-http-headers';
import { ShellService } from '../../../../shared/services/shell/shell.service';

@Injectable({
  providedIn: 'root'
})
export class NominalLiningService {

  appReqHeader = new AppHttpHeaders();
  accessPointUrl = this.appReqHeader.domainURL + 'api/NominalLining';
  zoneUrl = this.appReqHeader.domainURL + 'api/Zone';
  refractoryUrl = this.appReqHeader.domainURL + 'api/RefractoryDetail';

  constructor(private http: HttpClient, private shellService: ShellService) { }

  public getNominalLiningAllProducts(anchorBackupObj: any, anchorHotFaceObj: any, refractoryBackupObj: any, refractoryHotfaceObj: any, unitVesselZoneObj: any): Observable<any[]> {
    let response1 = this.http.post<any>(this.accessPointUrl + '/SearchAnchorBackup', anchorBackupObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));

    let response2 = this.http.post<any>(this.accessPointUrl + '/SearchAnchorHotface', anchorHotFaceObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));

    let response3 = this.http.post<any>(this.accessPointUrl + '/SearchRefractoryBackup', refractoryBackupObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));

    let response4 = this.http.post<any>(this.accessPointUrl + '/SearchRefractoryHotface', refractoryHotfaceObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));

    var queryParams = this.createRequestParams(unitVesselZoneObj, 'vesselZoneCalculationFactors');
    let response5 = this.http.get<any>(this.zoneUrl + queryParams, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));

    var queryParams = this.createRequestParams(unitVesselZoneObj, '');
    let response6 = this.http.get<any>(this.refractoryUrl + queryParams, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));

    return forkJoin([response1, response2, response3, response4, response5, response6]);
  }

  createRequestParams(obj: any, type: string) {
    var queryParams = "";
    if (obj.unitId != undefined) {
      queryParams = "/" + obj.unitId;
    }
    if (obj.vesselId != undefined) {
      queryParams = queryParams + "/" + obj.vesselId;
    }
    if (obj.zoneId != undefined) {
      queryParams = queryParams + "/" + obj.zoneId + "/" + type;
    }
    return queryParams;
  }

  loadAnchorBackup(anchorBackupObj: any) {
    return this.http.post<any>(this.accessPointUrl + '/SearchAnchorBackup', anchorBackupObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));
  }

  loadAnchorHotface(anchorBackupObj: any) {
    return this.http.post<any>(this.accessPointUrl + '/SearchAnchorHotface', anchorBackupObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));
  }

  loadRefractoryBackup(refractoryBackupObj: any) {
    return this.http.post<any>(this.accessPointUrl + '/SearchRefractoryBackup', refractoryBackupObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));
  }

  loadRefractoryHotface(refractoryBackupObj: any) {
    return this.http.post<any>(this.accessPointUrl + '/SearchRefractoryHotface', refractoryBackupObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));
  }

  addAnchorRefractoryDetails(anchorRefractoryViewModel: AnchorRefractoryViewModel) {
    return this.http.post<any>(this.accessPointUrl + '/Add', anchorRefractoryViewModel, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  updateAnchorRefractoryDetails(nominalId: number, patchNominalViewModel: any) {
    var query = "";
    if (nominalId) {
      query = "/" + nominalId;
    }
    return this.http.patch<any>(this.accessPointUrl + query, patchNominalViewModel, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  deleteAnchorRefractoryDetails(id: number) {
    var query = "";
    if (id) {
      query = "/" + id;
    }
    return this.http.delete<any>(this.accessPointUrl + query, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  copyNominalIntoEstimateRefractory(copyNominalObj: any) {
    return this.http.post<any>(this.accessPointUrl + '/CopyNominlLiningIntoEstimateRefractory', copyNominalObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  saveRepairArea(refractoryDetailsObj:any) {
    return this.http.post<any>(this.appReqHeader.domainURL + 'api/RefractoryDetail', refractoryDetailsObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  updateHotfaceRepairArea(Id: number, patchRepairAreaObj: any) {
    var query = "";
    if (Id) {
      query = "/" + Id;
    }
    return this.http.patch<any>(this.appReqHeader.domainURL + 'api/RefractoryDetail' + query, patchRepairAreaObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
}
