import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { AppHttpHeaders } from '../../../shared/services/common/app-http-headers';
import { ShellService } from '../../../shared/services/shell/shell.service';
import { Country } from '../models/country.model';

@Injectable({
  providedIn: 'root'
})
export class CountryService {
  appReqHeader = new AppHttpHeaders();
  baseURL = this.appReqHeader.domainURL;
  accessPointUrl: string = this.baseURL + 'api/Country';
  constructor(private http: HttpClient, private shellService: ShellService) { }

  getCountries() {
    return this.http.get<Country[]>(this.accessPointUrl , { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
}
