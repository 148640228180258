import { NgIf } from '@angular/common';
import { Component } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { NominalLiningComponent } from "../../module/utilities/nominal-lining/nominal-lining.component";
import { SiteComponent } from '../../module/utilities/site/site.component';
import { UnitVesselZoneComponent } from "../../module/utilities/unit-vessel-zone/unit-vessel-zone.component";
import { UnitVesselComponent } from "../../module/utilities/unit-vessel/unit-vessel.component";
import { UnitComponent } from "../../module/utilities/unit/unit.component";
import {ShellService } from '../../shared/services/shell/shell.service';
import { AccessLevel } from '../../auth/app-roles';



@Component({
  selector: 'app-calciner-setup',
  standalone: true,
  imports: [NgIf, RouterModule, SiteComponent, UnitComponent, UnitVesselComponent, UnitVesselZoneComponent, NominalLiningComponent],
  templateUrl: './calciner-setup.component.html',
  styleUrl: './calciner-setup.component.scss'
})
export class CalcinerSetupComponent {
  page: string = "";
  routes = [
    'site',
    'unit',
    'unit-vessel',
    'unit-vessel-zone',
    'nominal-lining'
  ]

  accessLevel: AccessLevel = AccessLevel.NoAccess;

  constructor(private route: ActivatedRoute, private shellService: ShellService) {
    this.route.params.subscribe(params => {
      if (this.routes.some(pg => params['comp'] === pg)) {
        this.page = params['comp'];
      }
    });
  }

  ngOnInit(): void {
    // this.shellService.getAccessLevel().then((accessLevel) => {
    //   this.accessLevel = accessLevel;
    // })
    this.accessLevel=this.shellService.getAppAccessLevel();
  }
}
