import { Injectable } from '@angular/core';
import { AppHttpHeaders } from '../../../../shared/services/common/app-http-headers';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs';
import { ShellService } from '../../../../shared/services/shell/shell.service';

@Injectable({
  providedIn: 'root'
})
export class AppSettingService {
  appReqHeader = new AppHttpHeaders();
  accessPointUrl: string = this.appReqHeader.domainURL + 'api/AppSetting';
  
  constructor(private http: HttpClient, private shellService: ShellService) { }


  getSpacing(countryId: number, paramName: number) {
    var query = "";
    if (countryId) {
      query = "/" + countryId;
    }
    if (paramName) {
      query = query + "/" + paramName;
    }
    return this.http.get<any>(this.accessPointUrl + query, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getAppsettings(countryId: number, paramName: number) {
    var query = "";
    if (countryId) {
      query = "/" + countryId;
    }
    if (paramName) {
      query = query + "/" + paramName;
    }
    return this.http.get<any>(this.accessPointUrl + query, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getAnchorDensity(countryId: number, paramName: number) {
    var query = "";
    if (countryId) {
      query = "/" + countryId;
    }
    if (paramName) {
      query = query + "/" + paramName;
    }
    return this.http.get<any>(this.accessPointUrl + query, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

}
