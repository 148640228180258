import { Injectable } from '@angular/core';
import { AppHttpHeaders } from '../../../../shared/services/common/app-http-headers';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs';
import { ShellService } from '../../../../shared/services/shell/shell.service';

@Injectable({
  providedIn: 'root'
})
export class ProductTypeService {
  appReqHeader = new AppHttpHeaders();
  productTypeAccessPointUrl: string = this.appReqHeader.domainURL + 'api/ProductType';
  
  constructor(private http: HttpClient, private shellService: ShellService) { }

  searchProductType(filterProductTypeObj: any) {
    return this.http.post<any>(this.productTypeAccessPointUrl + '/SearchProductTypes', filterProductTypeObj, { headers: this.appReqHeader.formHeaders }).pipe(
        catchError(err => this.shellService.handleError(err))
      );
  }

  addProductType(productTypeObj: any) {
    return this.http.post<any>(this.productTypeAccessPointUrl, productTypeObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  updateProductType(productTypeId:number,productTypeObj: any) {
    var query = "";
    if (productTypeId) {
      query = "/" + productTypeId;
    }
    return this.http.patch<any>(this.productTypeAccessPointUrl + query, productTypeObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
}
