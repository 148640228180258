import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable, Injector } from "@angular/core";
import { AccountInfo } from "@azure/msal-browser";
import { DynamicDialogRef, DialogService } from "primeng/dynamicdialog";
import { Subject, Observable, throwError, timeout, catchError } from "rxjs";
import { environment } from "../../../../environments/environment";
import { roles } from "../../../auth/auth-config";
import { NotificationModel } from "../../../module/utilities/models/notification.model";
import { Outage } from "../../../module/utilities/models/outage.model";
import { Site } from "../../../module/utilities/models/site.model";
import { Unit } from "../../../module/utilities/models/unit.model";
import { AlertDialog } from "../../components/alert-dialog/alert-dialog.component";
import { Header } from "../../constants";
import { AppHttpHeaders } from "../common/app-http-headers";
import { AccessLevel } from "../../../auth/app-roles";


@Injectable({
  providedIn: 'root'
})
export class ShellService {
  appName = `Refractory Management System`;
  appReqHeader = new AppHttpHeaders();
  isSidebarVisible: boolean = false;
  errorMessage = "";
  globalLoadingSubject = new Subject<boolean>();
  preventUserAccess = new Subject<string>();

  globalSiteUnitOutageObject: GlobalSiteUnitOutage | any = null;

  sidebarVisibilityChange: Subject<boolean> = new Subject<boolean>();
  resetDropDownSubject = new Subject<any>();
  refreshFooterSubject = new Subject<Outage>();

  globalSiteUnitOutageUnset: Subject<boolean> = new Subject<boolean>();
  globalSiteUnitOutageSet: Subject<GlobalSiteUnitOutage> = new Subject<GlobalSiteUnitOutage>();

  private activeAlert: DynamicDialogRef | undefined;
  globalUser: AccountInfo | undefined;
  // private dialogService:DialogService|undefined;

  constructor(private http: HttpClient, private injector: Injector) {
    // this.alertService = this.injector.get(AlertModalService);
    this.sidebarVisibilityChange.subscribe((value) => {
      this.isSidebarVisible = value
    });
  }

  showLoadingIndicator(show: boolean) {
    this.globalLoadingSubject.next(show);
  }

  /**
   * close the sidebar
   * @param close 
   */
  toggleSidebarVisibility(close: boolean = false) {
    this.sidebarVisibilityChange.next(close ? false : !this.isSidebarVisible);
  }

  refreshFooter(outage: Outage) {
    this.refreshFooterSubject.next(outage);
  }

  setSite(site: Site) {
    this.globalSiteUnitOutageObject.siteId = site.id;
    this.globalSiteUnitOutageObject.siteName = site.name;
    this.globalSiteUnitOutageObject.countryId = site.countryId;
    this.globalSiteUnitOutageObject.unitId = 0;
    this.globalSiteUnitOutageObject.unitName = "";
    this.globalSiteUnitOutageObject.outageId = 0;
    this.globalSiteUnitOutageObject.outageName = "";
    this.saveGlobalSiteUnitOutage();
  }

  setUnit(unit: Unit) {
    this.globalSiteUnitOutageObject.unitId = unit.unitId;
    this.globalSiteUnitOutageObject.unitName = unit.unitName;
    this.globalSiteUnitOutageObject.outageId = 0;
    this.globalSiteUnitOutageObject.outageName = "";
    this.saveGlobalSiteUnitOutage();
  }

  setOutage(outage: Outage) {
    this.globalSiteUnitOutageObject.outageId = outage.id;
    this.globalSiteUnitOutageObject.outageName = outage.name;
    this.saveGlobalSiteUnitOutage();
  }

  setGlobalDetails(globalObj: GlobalSiteUnitOutage) {
    this.globalSiteUnitOutageObject = globalObj;
    this.saveGlobalSiteUnitOutage();
    this.siteUnitOutageModalClosed();
  }

  loadGlobalSiteUnitOutage(hidePrompt: boolean = false, outageRequired: boolean = true): GlobalSiteUnitOutage {

    if (this.globalSiteUnitOutageObject == undefined || this.globalSiteUnitOutageObject == null) {
      let json_data = localStorage.getItem('siteUnitOutageObj');
      this.globalSiteUnitOutageObject = JSON.parse(json_data ? json_data : '{}');
    }
    if ((this.globalSiteUnitOutageObject.siteId == undefined || this.globalSiteUnitOutageObject.siteId == 0 ||
      this.globalSiteUnitOutageObject.unitId == undefined || this.globalSiteUnitOutageObject.unitId == 0 ||
      (outageRequired && (this.globalSiteUnitOutageObject.outageId == undefined || this.globalSiteUnitOutageObject.outageId == 0))) &&
      !hidePrompt) {
      this.globalSiteUnitOutageUnset.next(outageRequired);
    }
    return this.globalSiteUnitOutageObject;
  }

  saveGlobalSiteUnitOutage(): void {
    localStorage.setItem('siteUnitOutageObj', JSON.stringify(this.globalSiteUnitOutageObject));
  }

  siteUnitOutageModalClosed(): void {
    this.globalSiteUnitOutageSet.next(this.globalSiteUnitOutageObject);
  }

  resetConfig(autoOpenModal: boolean = false, outageRequired: boolean = true) {
    localStorage.removeItem("siteUnitOutageObj");
    this.globalSiteUnitOutageObject = new GlobalSiteUnitOutage();
    // console.log(this.globalSiteUnitOutageObject)
    this.resetDropDownSubject.next(null);
    if (autoOpenModal) {
      this.globalSiteUnitOutageUnset.next(outageRequired);
    }
  }

  getClickEvent(): Observable<any> {
    return this.resetDropDownSubject.asObservable();
  }

  //set roles and access throughout the application
  // role: string = "";
  // setAppAccessLevel(value: string) {
  //   this.role = value;
  // }
  role:AccessLevel=AccessLevel.NoAccess
  setAppAccessLevel(value:AccessLevel){
    this.role=value
  }

  getAppAccessLevel():AccessLevel{
    return this.role;
  }

  // get roles and access throughout the application
  // getRoles() {
  //   this.role = "Administrator"
  //   if (this.role)
  //     return this.role
  //   else
  //     return Header.noRoleTagged;
  // }

  // isAdmin() {
  //   if (this.role) {
  //     if (this.role == AccessLevel.Administrator) { //check if given role matches with admin
  //       return true;
  //     }
  //   }
  //   return false;
  // }
  /**
   * Evaluate Access Level
   * @param role 
   * @returns 
   */
  // evaluateAccessLevel(role: string): AccessLevel {
  //   switch (role) {
  //     case roles.ReadOnly:
  //       return AccessLevel.ReadOnly;
  //     case roles.Contractor:
  //       return AccessLevel.Contractor;
  //     case roles.BasicUser:
  //       return AccessLevel.BasicUser;
  //     case roles.Admin:
  //       return AccessLevel.Administrator;
  //   }
  //   return 0;
  // }

  // getAccessLevelUpdated() {
    // if (this.globalUser && this.globalUser.idTokenClaims && this.globalUser.idTokenClaims.roles) { 
    //   return this.evaluateAccessLevel(this.globalUser.idTokenClaims.roles[0])
    // }
    // return this.getAppAccessLevel()
  // }
  // getAccessLevel(): Promise<AccessLevel> {
  //   var tries = 5;
  //   var exit_condition = () => this.role != undefined;
  //   return new Promise((resolve, reject) => {
  //     if (exit_condition()) {
  //       if (this.role != "") {
  //         resolve(this.evaluateAccessLevel(this.role));
  //       } else {
  //         this.showErrorScreen(errorMessage.rolesUndefined);
  //       }
  //     } else {
  //       var retry_loop = () => {
  //         if (tries > 0) {
  //           if (exit_condition()) {
  //             if (this.role != "") {
  //               resolve(this.evaluateAccessLevel(this.role));
  //             } else {
  //               this.showErrorScreen(errorMessage.rolesUndefined);
  //             }
  //           } else {
  //             tries--;
  //             setTimeout(retry_loop, 1000);
  //           }
  //         } else {
  //           this.showErrorScreen(errorMessage.msalTimeout);
  //         }
  //       }
  //       retry_loop();
  //     }
  //   })
  // }

  showErrorScreen(message: string) {
    this.errorMessage = message;
    this.preventUserAccess.next(message);
  }

  createPatchArray(columnName: string, value: any) {
    return [
      {
        "value": value,
        "path": "/" + columnName,
        "op": "replace"
      }
    ]
  }


  handleError(errorResponse: HttpErrorResponse) {
    // console.log(errorResponse)
    if (errorResponse.error instanceof ProgressEvent) {
      if (errorResponse.status == 0) {
        // this.alert(this.dialogService, { title: 'Aw snap!', message: 'Connection to API server failed!', severity: 'error' })
        console.error('Connection to API server failed!')
      } else {
        // this.showAlert(errorResponse.statusText, errorResponse.message);
        // this.alert(this.dialogService, { title: errorResponse.statusText, message: errorResponse.message, severity: 'error' })
      }
      return throwError(errorResponse);
    } else if (errorResponse.error instanceof ErrorEvent) {
      console.error("RMS app ran into an unexpected problem! A browser refresh is recommended.")
      // this.showAlert("Something went wrong!", "RMS app ran into an unexpected problem! A browser refresh is recommended.")
      // this.alert(this.dialogService, { title: 'Something went wrong!', message: 'RMS app ran into an unexpected problem! A browser refresh is recommended', severity: 'error' })
      // A client-side or network error occurred. Handle it accordingly.
      // return throwError(errorResponse.error.message);
      return throwError(()=>new Error(errorResponse.message))
    } else {
      var titleText = ""
      switch (errorResponse.status) {
        case 400:
          titleText = "Invalid Input!"
          break;
        case 401:
          titleText = "Access Denied!"
          break;
        case 403:
          titleText = "Forbidden Operation!"
          break;
        case 405:
          titleText = "Operation Not Allowed!"
          break;
        case 408:
          titleText = "Request Timed Out!"
          break;
        case 429:
          titleText = "API Server is Busy"
          break;
        case 500:
          titleText = "API Server Error!"
          break;
        case 501:
          titleText = "Feature Not Available"
          break;
        case 502:
          titleText = "Gateway Error!"
          break;
        case 503:
          titleText = "API Server Unavailable"
          break;
        default:
          titleText = "Error " + errorResponse.status;
      }
      // The backend returned an unsuccessful response code. The response body may contain clues as to what went wrong,      
      // this.showAlert(titleText, errorResponse.error)
      // this.alert(, { title: titleText, message: errorResponse.error, severity: 'error' })
      // return throwError(errorResponse.error);
      return throwError(()=>new Error(errorResponse.error));
    }
  }

  ping() {
    return this.http.get(environment.apiConfig.uri + "api/Generic/Ping", { headers: this.appReqHeader.formHeaders }).pipe(
      timeout(10000),
      catchError(() => {
        return new Observable<null>()
      })
    )
  }

  alert(dialogService: DialogService, notification: NotificationModel, appendTo = undefined) {
    debugger
    console.log(dialogService)
    if (this.activeAlert) {
      this.activeAlert.close();
    }

    this.activeAlert = dialogService.open(AlertDialog, {
      showHeader: false,
      maskStyleClass: 'aq-dialog-backdrop',
      styleClass: 'alert',
      closable: false,
      contentStyle: {
        padding: '0px',
        borderRadius: '4px',
        overflow: 'hidden'
      },
      data: {
        header: notification.title,
        styleClass: notification.severity,
        message: notification.message,
      },
      appendTo: appendTo ? appendTo : 'body',
    });
  }


  closeAlert() {
    this.activeAlert?.close()
    this.activeAlert = undefined
  }

  /**
 * This is used to set the global user
 * @param user 
 */
  setGlobalUser(user: AccountInfo) {
    this.globalUser = user
  }
  /**
   * This is used to get the global user
   * @returns AccountInfo | "User Not Present"
   */
  // getGlobalUser() {
  //   if (this.globalUser)
  //     return this.globalUser
  //   return undefined
  // }



}
export class GlobalSiteUnitOutage {
  siteId: number = -1
  siteName: string = ""

  countryId: number = -1

  unitId: number = -1
  unitName: string = ""

  outageId: number = -1
  outageName: string = ""
}


