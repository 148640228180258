import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { FormsModule } from "@angular/forms";
import { environment } from "../../../environments/environment";
import { ShellService } from "../../shared/services/shell/shell.service";
import { AccessLevel } from "../../auth/app-roles";








// Handles the embed config response for embedding
export interface ConfigResponse {
  Id: string;
  EmbedUrl: string;
  EmbedToken: {
    Token: string;
  };
}

@Component({
  selector: 'app-reports-page',
  standalone: true,
  imports: [FormsModule],
  // imports: [PowerBIEmbedModule],
  templateUrl: './reports-page.component.html',
  styleUrl: './reports-page.component.scss'
})
export class ReportsPageComponent implements AfterViewInit, OnInit {
  // Will be used if required
  // embedConfig!: powerbi.IEmbedConfiguration;
  showCost = true
  @Input() wizard_link = ''
  @ViewChild('reportFrame') iframe!: ElementRef


  // _user: AccountInfo | undefined;
  // accessToken = ""

  accessLevel:AccessLevel=AccessLevel.NoAccess;

  showShowCostControl = false

  page: string = "";

  map = new Map<string, string>();
  showCostMap = new Map<string, boolean>();

  constructor(private route: ActivatedRoute, private shellService: ShellService,private changeDetector:ChangeDetectorRef) {

  }
  ngOnInit(): void {
    environment.report_maps.forEach(map_item => {
      this.map.set(map_item.uri, map_item.id)
      this.showCostMap.set(map_item.uri, map_item.showCostOption == undefined ? false : map_item.showCostOption)
    })

  }
  ngAfterViewInit(): void {
    this.route.params.subscribe(params => {
      this.accessLevel = this.shellService.getAppAccessLevel()
      if (this.map.has(params["uri"])) {
        this.page = params["uri"];
        var showcontrol = this.showCostMap.get(params["uri"])
      
        this.showShowCostControl = showcontrol == undefined ? false : showcontrol
        // this.changeDetector.detectChanges()
        
       
        this.loadReportView();
        this.wizard_link = '';
      }
    });
    if (this.wizard_link.length > 0) {
      this.page = this.wizard_link;
      var showcontrol = this.showCostMap.get(this.page)
      this.showShowCostControl = showcontrol == undefined ? false : showcontrol
      this.loadReportView();
    }
  }


  private getRandomDigit(max: number): number {
    return Math.round(Math.random() * max);
  }

  private findNextNumber(n: number): number {
    for (let i = 1; i < 4; i++) {
      if ((i + n) % 3 == 0) {
        return i;
      }
    }
    return 0;
  }
  /**This method is used to generate the security token */
  private generateToken(isAdmin: boolean): string {
    var token = "";
    var random_numbers = []
    for (let i = 0; i < 8; i++) {
      random_numbers.push(this.getRandomDigit(9.0));
    }
    var random_index = (this.getRandomDigit(3.0) * 2) + 1;
    var random_number_sum = 0;
    for (let i = 0; i < 4; i++) {
      if (((i * 2) + 1) != random_index) {
        random_number_sum += random_numbers[(i * 2) + 1];
      }
    }
    var nextNumber = this.findNextNumber(random_number_sum);
    if (!(this.accessLevel<AccessLevel.Administrator)) {
      if (nextNumber == 0) {
        nextNumber++;
      } else {
        nextNumber--;
      }
    }
    random_numbers[random_index] = nextNumber;
    random_numbers.forEach(n => {
      token += n.toString();
    });
    return token;
  }

  /**
   * This method is used to load the report
   */
  loadReportView() {
    // console.log("in load report view")
    var globalDetails = this.shellService.loadGlobalSiteUnitOutage()

    this.iframe.nativeElement.src = "https://app.powerbi.com/rdlEmbed?reportId=" +
      this.map.get(this.page) +
      "&autoAuth=true&ctid=" +
      environment.msalConfig.auth.tenantId +
      "&rdl:reportView=pageView" +
      "&rp:Site=" + globalDetails.siteId +
      "&rp:SiteUnit=" + globalDetails.unitId +
      "&rp:OutageDate=" + globalDetails.outageId +
      "&rp:IncludeCost=" + this.generateToken(this.accessLevel == AccessLevel.Administrator && this.showCost);
  }
}




