import { Injectable } from '@angular/core';
import { AppHttpHeaders } from '../../../../shared/services/common/app-http-headers';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, catchError } from 'rxjs';
import { ShellService } from '../../../../shared/services/shell/shell.service';
import { PaginatedResult } from '../../models/paginatedResult.model';
import { ProductCategory } from '../../models/productCategory.model';

@Injectable({
  providedIn: 'root'
})
export class ProductCategoryService {
  appReqHeader = new AppHttpHeaders();
  baseURL = this.appReqHeader.domainURL;
  accessPointUrl: string = this.baseURL + 'api/ProductCategory';

  constructor(private http: HttpClient, private shellService: ShellService) { }

  searchCategories(searchObject: any, pageNumber?: number, pageSize?: number, sortField?: string, sortOrder?: number) {
    const paginatedResult:PaginatedResult<ProductCategory[]> =new PaginatedResult<ProductCategory[]>();
    let params=new HttpParams();
    if(pageNumber!=null&&pageSize!=null){
      params=params.append('PageNumber',pageNumber.toString());
      params=params.append('PageSize',pageSize.toString());
    }
    if(sortField!=null&&sortOrder!=null){
      params=params.append('SortField',sortField.toString());
      params=params.append('SortOrder',sortOrder.toString());
    }
    return this.http.post<ProductCategory[]>(this.accessPointUrl + "/SearchProductCategory",
      searchObject,
      {observe:'response', params, headers: this.appReqHeader.formHeaders}).pipe(
        map(response=>{
          paginatedResult.result=response.body != null ? response.body : [];
          if(response.headers.get('X-Pagination')!=null) {
            paginatedResult.pagination=JSON.parse(response.headers.get('X-Pagination') || '{}');
          }
          return paginatedResult;
        }), catchError(err => this.shellService.handleError(err))
    );
  }

  addCategory(categoryObject: any) {
    return this.http.post<any>(this.accessPointUrl, categoryObject, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  patchUpdate(categoryId: number, patchObject: any) {
    var query = "";
    if (categoryId) {
      query = "/" + categoryId;
    }
    return this.http.patch<any>(this.accessPointUrl + query, patchObject, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
}
