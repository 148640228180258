export class VesselDialogViewModel {
    isOpen: boolean=false;
    isUpdate: boolean=false;
  
    id: number=0;
    vesselName: string ="";
    vesselDescription: string ="";
    
    originalData!: VesselDialogViewModel
  
    isVesselNameEmpty: boolean=false;
  }