import { Vendor } from "../models/vendor.model";
import { JobType } from "./jobType.model";
import { Shift } from "./shift.model";


export class BatchUsageDialogViewModel{
  id: number = 0;
  opened: boolean = false;
  isUpdate: boolean = false;
  vessels: any;
  zones: any;
  selectedVessel: any;
  selectedZone: any;
  shiftOptions: Shift[]=[];
  selectedShift: Shift | any;
  installDate: Date = new Date();
  installer: Vendor | any;
  installers: Vendor[]=[];
  crew: string | any;
  jobNo: string | any;
  jobTypes: JobType[]=[];
  selectedJobType: any;
  internalLabourInstManHours: number = 0;
  internalLabourCost: number = 0;
  externalLabourInstManHours: number = 0;
  externalLabourCost: number = 0;
  comments: string | any;

  // Validation
  isVesselNameEmpty: boolean = false;
  isZoneNameEmpty: boolean = false;
  isInstallerEmpty: boolean = false;
  isCrewEmpty: boolean = false;
  isJobNoEmpty: boolean = false;
  isJobTypeEmpty: boolean = false;
  isOnSiteHoursEmpty: boolean = false;
  isOnSiteCostEmpty: boolean = false;
  isOffSiteHoursEmpty: boolean = false;
  isOffSiteCostEmpty: boolean = false;

  // Original Data
  originalData!: BatchUsageDialogViewModel;
}