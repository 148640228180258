// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabView {
  width: 100%;
  padding-top: 12px;
}

.tabPanel {
  background-color: #e0e0e0;
  padding: 12px;
}

.tabBar {
  height: 42px;
}

.tabBar > button {
  border-radius: 8px 8px 0 0;
  background: none;
  outline: 0 !important;
  border: none !important;
  box-shadow: 0 8px 0 #e0e0e0 !important;
  color: #3f89c2;
  margin-top: 9px;
  margin-left: 12px;
  font-weight: bold;
}

.tabBar > button:hover {
  color: #165788;
}

.tabActive {
  color: #165788 !important;
  background: #e0e0e0 !important;
}

.nominal-other-details-lbl2 {
  font-size: 11px;
  font-weight: 600;
  color: rgb(0, 153, 255);
}

.product-inactive {
  color: rgba(238, 0, 0, 0.7333333333);
}

.product-inactive::after {
  content: " (Inactive)";
  color: rgba(238, 127, 0, 0.8);
}`, "",{"version":3,"sources":["webpack://./src/app/module/utilities/daily-usage/daily-usage.component.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,iBAAA;AACJ;;AAEE;EACE,yBAAA;EACA,aAAA;AACJ;;AAEE;EACE,YAAA;AACJ;;AAEE;EACE,0BAAA;EACA,gBAAA;EACA,qBAAA;EACA,uBAAA;EACA,sCAAA;EACA,cAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;AACJ;;AAEE;EACE,cAAA;AACJ;;AAEE;EACE,yBAAA;EACA,8BAAA;AACJ;;AAEE;EACE,eAAA;EACA,gBAAA;EACA,uBAAA;AACJ;;AAEE;EACE,oCAAA;AACJ;;AAEE;EACE,sBAAA;EACA,6BAAA;AACJ","sourcesContent":[".tabView {\n    width: 100%;\n    padding-top: 12px;\n  }\n  \n  .tabPanel {\n    background-color: #e0e0e0;\n    padding: 12px;\n  }\n  \n  .tabBar {\n    height: 42px;\n  }\n  \n  .tabBar > button {\n    border-radius: 8px 8px 0 0;\n    background: none;\n    outline: 0 !important;\n    border: none !important;\n    box-shadow: 0 8px 0 #e0e0e0 !important;\n    color: #3f89c2;\n    margin-top: 9px;\n    margin-left: 12px;\n    font-weight: bold;\n  }\n  \n  .tabBar > button:hover {\n    color: #165788;\n  }\n  \n  .tabActive {\n    color: #165788 !important;\n    background: #e0e0e0 !important;\n  }\n  \n  .nominal-other-details-lbl2{\n    font-size: 11px;\n    font-weight: 600;\n    color: rgb(0, 153, 255);\n  }\n  \n  .product-inactive {\n    color: #e00b;\n  }\n  \n  .product-inactive::after {\n    content:\" (Inactive)\";\n    color: rgba(238, 127, 0, 0.8);\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
