// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notch-container {
  position: relative;
  top: 0;
  right: 146px;
  left: calc(100% - 276px);
  max-height: 0;
  height: 0;
  width: 130px;
}

.notch {
  text-align: right;
  background: #faf9f8 !important;
  border-radius: 0 0 12px 12px;
  padding: 4px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 4px;
  animation: bounce-out 0.5s ease-in-out;
}
.notch * {
  vertical-align: middle;
  font-size: 14px;
  font-weight: 400;
  font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", "sans-serif";
}

.report-container {
  height: calc(100vh - 7px);
  width: 100%;
}

@keyframes bounce-out {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/module/reports-page/reports-page.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,MAAA;EACA,YAAA;EACA,wBAAA;EACA,aAAA;EACA,SAAA;EACA,YAAA;AACF;;AAEA;EACE,iBAAA;EACA,8BAAA;EACA,4BAAA;EACA,YAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,sCAAA;AACF;AAEE;EACE,sBAAA;EACA,eAAA;EACA,gBAAA;EACA,kIAAA;AAAJ;;AAWA;EACE,yBAAA;EACA,WAAA;AARF;;AAWA;EACE;IACE,UAAA;EARF;EAWA;IACE,UAAA;EATF;AACF","sourcesContent":[".notch-container {\n  position: relative;\n  top: 0;\n  right: 146px;\n  left: calc(100% - 276px);\n  max-height: 0;\n  height: 0;\n  width: 130px;\n}\n\n.notch {\n  text-align: right;\n  background: #faf9f8 !important;\n  border-radius: 0 0 12px 12px;\n  padding: 4px;\n  padding-left: 12px;\n  padding-right: 12px;\n  padding-bottom: 4px;\n  animation: bounce-out 0.5s ease-in-out;\n\n\n  * {\n    vertical-align: middle;\n    font-size: 14px;\n    font-weight: 400;\n    font-family: 'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', 'sans-serif';\n  }\n}\n\n// .notch > * {\n//   vertical-align: middle;\n//   font-size: 14px;\n//   font-weight: 400;\n//   font-family: 'Segoe UI', 'Segoe UI Web (West European)', -apple-system, BlinkMacSystemFont, Roboto, 'Helvetica Neue', 'sans-serif';\n// }\n\n.report-container {\n  height: calc(100vh - 7px);\n  width: 100%;\n}\n\n@keyframes bounce-out {\n  from {\n    opacity: 0;\n  }\n\n  to {\n    opacity: 1;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
