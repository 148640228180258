import { NgIf } from '@angular/common';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { InspectionCommentsComponent } from "../../module/utilities/inspection-comments/inspection-comments.component";
import { DailyUsageComponent } from "../../module/utilities/daily-usage/daily-usage.component";
import { BulkUploaderComponent } from "../../module/utilities/bulk-uploader/bulk-uploader.component";

@Component({
  selector: 'app-shutdown-execution',
  standalone: true,
  imports: [RouterModule, NgIf, InspectionCommentsComponent, DailyUsageComponent, BulkUploaderComponent],
  templateUrl: './shutdown-execution.component.html',
  styleUrl: './shutdown-execution.component.scss'
})
export class ShutdownExecutionComponent implements OnInit{
  @ViewChild("wizardContainer", { read: ViewContainerRef }) wizardContainer: any;
  
  page: string = "";
  routes = [
    'inspection',
    'bulk-loader',
    'dailyusage'
  ]
  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      if (this.routes.some(pg => params['comp'] === pg)) {
        this.page = params['comp'];
      }
    });
  }
  ngOnInit(): void {
  }
}
