import { Component } from '@angular/core';
import { DynamicDialogConfig } from 'primeng/dynamicdialog';
import { ShellService } from '../../services/shell/shell.service';


@Component({
  selector: 'alert-dialog',
  standalone: true,
  imports: [],
  templateUrl: './alert-dialog.component.html',
  styleUrl: './alert-dialog.component.scss'
})
export class AlertDialog {
  public message: string = "Hello";
  public header: string = "Hi!";
  public styleClass: string = "";

  constructor(public config: DynamicDialogConfig, public shell: ShellService) {
    console.log(config.data)
    this.message = config.data.message;
    this.header = config.data.header;
    this.styleClass = config.data.styleClass;
  }

  public close () : void {
    this.shell.closeAlert();
  }
}
