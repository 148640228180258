import { Component, OnInit } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { ButtonModule } from 'primeng/button';
import { FlatoutMessages } from '../../../shared/constants';
import { ShellService, GlobalSiteUnitOutage } from '../../../shared/services/shell/shell.service';

@Component({
  selector: 'app-flatout-editor',
  standalone: true,
  imports: [ButtonModule],
  templateUrl: './flatout-editor.component.html',
  styleUrl: './flatout-editor.component.scss'
})
export class FlatoutEditorComponent implements OnInit {
  constructor(private shellService:ShellService){}
  siteUnitOutage: GlobalSiteUnitOutage | undefined = undefined;
  openEditor=FlatoutMessages.editor;
  ngOnInit(): void {
    this.siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage();

    if (this.siteUnitOutage.outageId > 0) {
      this.openFlatoutEditor();
    }

    this.shellService.globalSiteUnitOutageSet.subscribe((obj) => {
      this.siteUnitOutage = obj;
      this.openFlatoutEditor();
    })
  }

  openFlatoutEditor() {
    if (this.siteUnitOutage) {
      var url=environment.apiConfig.flatoutUri+"?"+"siteId="+this.siteUnitOutage?.siteId+"&unitId="+this.siteUnitOutage?.unitId;

      if (!this.siteUnitOutage?.outageName.includes("Nominal Lining")) {
        url += "&outageId=" + this.siteUnitOutage?.outageId;
      }
      window.open(url, "_blank");
    }
  }

}
