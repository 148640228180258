// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vessel-data-inactive {
  color: rgba(238, 0, 0, 0.7333333333);
}

:host ::ng-deep .dropdown-border-color .p-dropdown {
  border: 1px solid red !important;
}`, "",{"version":3,"sources":["webpack://./src/app/module/utilities/unit-vessel-zone/unit-vessel-zone.component.scss"],"names":[],"mappings":"AAAA;EACI,oCAAA;AACJ;;AAEE;EACE,gCAAA;AACJ","sourcesContent":[".vessel-data-inactive {\n    color: #e00b;\n  }\n  \n  :host ::ng-deep .dropdown-border-color .p-dropdown {\n    border: 1px solid red !important;\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
