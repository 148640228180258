// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-image {
  height: 70vh;
  background-image: url('403.5459e29d5bd08145.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 60vh;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/error-pages/access-denied/access-denied.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,iDAAA;EACA,4BAAA;EACA,2BAAA;EACA,qBAAA;AACJ","sourcesContent":[".error-image {\n    height: 70vh;\n    background-image: url('../../../../assets/images/error-doodles/403.svg');\n    background-repeat: no-repeat;\n    background-position: center;\n    background-size: 60vh;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
