import { Component, Input, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';

import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { Subject } from 'rxjs';

import { environment } from '../../../../environments/environment';
import { roles } from '../../../auth/auth-config';
import { Header } from '../../constants';
import { ShellService } from '../../services/shell/shell.service';
import { AccessLevelName, findAppRoleFromAccountInfo } from '../../../auth/app-roles';


@Component({
  selector: 'app-headerbar',
  standalone: true,
  imports: [ButtonModule, OverlayPanelModule, AvatarModule],
  templateUrl: './headerbar.component.html',
  styleUrl: './headerbar.component.scss'
})
export class HeaderbarComponent implements OnInit {
  environmentName = `Redirect URI Not setup`;
  currentUrl = '';
  showFlatoutPreview = false;
  currentPage: string = "";
  loginDisplay = false;
  displayedColumns: string[] = ['claim', 'value'];
  dataSource: any = [];

  pageTitle = Header.pageTitle;
  welcome = Header.welcome;
  pageTitleAbbreviated = Header.pageTitleAbbreviated;
  flatout = Header.flatout;
  newUser = Header.newUser;
  noRoleTagged = Header.noRoleTagged;

  private _user: AccountInfo | undefined;
  userName: string = '';
  roleName: string | undefined;
  userInitials = '';
  profilePicColor = '#fff4';

  private readonly _destroying$ = new Subject<void>();

  @Input() set user(value: AccountInfo | undefined) {
    this._user = value;
    // console.log('role -> ',value?.idTokenClaims?.roles![0])
    if (this.user) {
      this.loginDisplay = true;
      let user_name = this.user.name + '';
      this.userName = user_name
      this.setUI(this.user)
      let alphabetics = user_name.replace(/[^A-Za-z ]/gi, '');
      let initialsMatch = alphabetics.match(/(\b\S)?/g);

      if (initialsMatch) {
        let initials = initialsMatch
          .join('')
          .toUpperCase()
          .substring(0, 2)
          .split('')
          .reverse();

        this.profilePicColor = this.getRandomColor();
        this.userInitials = initials.join('');
      }
      this.shellService.setGlobalUser(this.user)
      this.shellService.setAppAccessLevel(findAppRoleFromAccountInfo(this.user))
    }
    else this.loginDisplay = false;
  }

  get user(): AccountInfo | undefined {
    return this._user;
  }

  constructor(private shellService: ShellService,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url === '/') {
          this.currentPage = "Home"
        }

        if (event.url === '/403') {
          this.currentPage = "Access Denied"
        }

        if (event.url.includes('/calciner-setup')) {
          this.currentPage = "Equipment Setup"
        } else if (event.url === '/site') {
          this.currentPage = "Site"
        } else if (event.url === '/unit') {
          this.currentPage = "Site-Unit"
        } else if (event.url === '/unit-vessel') {
          this.currentPage = "Site-Unit-Vessel"
        } else if (event.url === '/unit-vessel-zone') {
          this.currentPage = "Site-Unit-Vessel-Zone"
        } else if (event.url === '/nominal-lining') {
          this.currentPage = "Nominal Lining"
        } else if (event.url === '/flatout-uploader') {
          this.currentPage = "Flatout Upload"
        }

        if (event.url.includes('/future-outage-setup')) {
          this.currentPage = "Outage Setup"
        } else if (event.url === '/outage') {
          this.currentPage = "Outages"
        } else if (event.url === '/estimate-refractory-materials') {
          this.currentPage = "Estimate Refractory Materials"
        }

        if (event.url === '/product') {
          this.currentPage = "Product Information"
        }

        if (event.url === '/category-product') {
          this.currentPage = "Product Categories"
        }

        if (event.url.includes('/shutdown-execution')) {
          this.currentPage = "Shutdown Execution"
          this.showFlatoutPreview = event.url.includes('flatout');
        } else if (event.url === '/inspection') {
          this.currentPage = "Inspection"
        } else if (event.url === '/dailyusage') {
          this.currentPage = "Daily Usage by Installation"
        } else if (event.url === '/bulk-loader') {
          this.currentPage = "Bulk Upload"
        } else if (event.url === '/flatout-editor') {
          this.currentPage = "Flatout Editor"
        }

        this.showFlatoutPreview = event.url.includes('/flatout')

        if (event.url.includes('/reports/')) {
          this.currentPage = "Reports"
        } else if (event.url.includes('/shutdown-review/')) {
          this.currentPage = "Shutdown Review"
        }

        if (event.url === '/vendor') {
          this.currentPage = "Vendors"
        }

        if (event.url === '/vessel') {
          this.currentPage = "Vessels"
        }

        if (event.url === '/zone') {
          this.currentPage = "Zones"
        }
        if (event.url === '/product-type') {
          this.currentPage = "Product Type"
        }
      }
    });

  }
  ngOnInit(): void {
    this.currentUrl = window.location.href;
    this.environmentName = environment.envName;
    this.shellService.preventUserAccess.subscribe((message) => {
      this.router.navigateByUrl("/403");
    })
  }
  setUI(user: AccountInfo | null, retries: number = 4) {
    setTimeout(() => {
      if (user != null) {
        this.setLoginDisplay();
        this.getClaims(user?.idTokenClaims)
      //  this.roleName=AccessLevelName[this.shellService.getAppAccessLevel()]
        let userName = user?.username
        if (userName) {
          this.setUserDataInLocalStorage(userName);
        }
      } else if (retries > 0) {
        this.setUI(null, retries - 1)
      } else {
        return;
      }
    }, 500);
  }

  logOut() {
    this.msalService.logout();
  }
  toggleSideBar() {
    if (this.dataSource[3]['value']) {
      this.shellService.toggleSidebarVisibility()
    }
  }

  accountSettings() {
    var hint = encodeURIComponent(this.user?.homeAccountId!);
    var _w = window.open('https://myaccount.microsoft.com/?login_hint=' + hint);
    _w!.focus();
  }

  /**
   * To be removed if not required
   */
  // checkAndSetActiveAccount() {
  //   /**
  //    * If no active account set but there are accounts signed in, sets first account to active account
  //    * To use active account set here, subscribe to inProgress$ first in your component
  //    * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
  //    */
  //   let activeAccount = this.msalService.instance.getActiveAccount();

  //   if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
  //     let accounts = this.msalService.instance.getAllAccounts();
  //     this.msalService.instance.setActiveAccount(accounts[0]);
  //   }
  // }

  setLoginDisplay() {
    this.loginDisplay = this.user != undefined
  }

  getClaims(claims: any) {
    this.dataSource = [
      { id: 1, claim: "Display Name", value: claims ? claims['name'] : null },
      { id: 2, claim: "User Principal Name (UPN)", value: claims ? claims['preferred_username'] : null },
      { id: 3, claim: "OID", value: claims ? claims['oid'] : null },
      { id: 4, claim: "Roles", value: claims ? claims['roles'] : null }
    ];
    const available_Roles = [roles.Admin, roles.BasicUser, roles.Contractor, roles.ReadOnly]
    var role = '';
    for (let i = 0; i < available_Roles.length; i++) {
      if (claims['roles'].includes(available_Roles[i])) {
        role = available_Roles[i];
        break;
      }
    }
    this.roleName = role
    // this.shellService.setRoles(role); //setting the current role in shell service 
  }

  setUserDataInLocalStorage(userName: string) {
    localStorage.setItem("user", userName)
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  showHelpDocument() {
    const PDF = '../../../../assets/docs/manual.pdf'
    window.open(PDF)
  }


  // Account

  getRandomColor(): string {
    const color = 'hsl(' + Math.random() * 360 + ', 75%, 80%)';
    return color;
  }
}
