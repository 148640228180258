import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { catchError, Subject } from 'rxjs';
import { AppHttpHeaders } from '../../../../shared/services/common/app-http-headers';
import { ShellService } from '../../../../shared/services/shell/shell.service';
import { Outage } from '../../models/outage.model';
import { Unit } from '../../models/unit.model';
import { Vessel } from '../../models/vessel.model';

@Injectable({
  providedIn: 'root'
})
export class UnitService {
  appReqHeader = new AppHttpHeaders();
  unitAccessPointUrl: string = this.appReqHeader.domainURL + 'api/Unit';
  fuelTypeAccessPointUrl: string = this.appReqHeader.domainURL + 'api/FuelType';

  //emit events to components which injected the service
  invokeError = new EventEmitter();
  invokeSuccess = new EventEmitter();
  refreshUnitGridSubject = new Subject<any>();

  constructor(private http: HttpClient, private shellService: ShellService) { }

  getUnits() {
    return this.http.get<Unit[]>(this.unitAccessPointUrl, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  getUnitById(unitId: number) {
    return this.http.get<Unit>(this.unitAccessPointUrl + "/" + unitId.toString(), { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  filterUnits(FilterUnits: any) {
    return this.http.post<Unit[]>(this.unitAccessPointUrl + '/SearchUnits', FilterUnits, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }


  getFuelType() {
    return this.http.get<any>(this.fuelTypeAccessPointUrl, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  addUnit(unitViewModel: any) {
    return this.http.post<number>(this.unitAccessPointUrl, unitViewModel, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  updateUnit(unitId: number, patchUnitViewModel: any) {
    var query = "";
    if (unitId) {
      query = "/" + unitId;
    }
    return this.http.patch<any>(this.unitAccessPointUrl + query, patchUnitViewModel, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
  /**
   * 
   * @param unitId 
   * @returns 
   */
  deleteUnit(unitId: number) {
    var query = "";
    if (unitId) {
      query = query + "/" + unitId;
    }
    return this.http.delete<any>(this.unitAccessPointUrl + query, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
  /**
   * 
   * @param unitId 
   * @returns 
   */
  getOutageByUnitId(unitId: number) {
    var query = "";
    if (unitId != undefined) {
      query = "/" + unitId + "/Outages";
    }
    return this.http.get<Outage[]>(this.unitAccessPointUrl + query, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
  /**
   * 
   * @param unitId 
   * @returns 
   */
  getVesselByUnitId(unitId: number) {
    var query = "";
    if (unitId != undefined) {
      query = "/" + unitId + "/Vessels";
    }
    return this.http.get<Vessel[]>(this.unitAccessPointUrl + query, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
/**
 * This method is used to get the vessel based on vessel estimate 
 * @param unitId 
 * @returns 
 */
  getVesselForBulkLoader(unitId:number){
    var query="";
    if(unitId!=undefined){
      query="/"+unitId+"/VesselsBulkLoader";
    }
    return this.http.get<Vessel[]>(this.unitAccessPointUrl+query, {headers:this.appReqHeader.formHeaders}).pipe(catchError(err=>this.shellService.handleError(err)))
  }

  /**
   * 
   * @param unitId 
   * @returns 
   */
  getNominalOutageByUnitId(unitId: number) {
    var query = "";
    if (unitId != undefined) {
      query = "/" + unitId + "/NominalOutage";
    }
    return this.http.get<Outage>(this.unitAccessPointUrl + query, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
  /**
   * 
   * @param unitId 
   * @param vesselId 
   * @returns 
   */
  getZoneBySiteUnitVesselId(unitId: number, vesselId: number) {
    var query = "";
    if (unitId != undefined) {
      query = "/" + unitId;
    }
    if (vesselId != undefined) {
      query = query + "/Vessel/" + vesselId + "/Zones";
    }
    return this.http.get<any>(this.unitAccessPointUrl + query, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

}
