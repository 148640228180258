export class Zone {
    id: number=0;
    name: string="";
  }
  
  export class UnitVesselZoneViewModel {
    siteId: number=0;
    siteName: string="";
    siteActive: boolean=false;
    unitId: number=0;
    unitName: string="";
    unitActive: boolean=false;
    vesselId: number=0;
    vesselName: string="";
    vesselActive: boolean=false;
    zoneId: number=0;
    zoneName: string="";
    zoneActive: boolean=false;
    operatingTemperature: number=0;
    particulateLoading: string="";
    velocity: number=0;
    shellTemperature: number=0;
    hfGeometricCalcFactor: number=0;
    bkGeometricCalcFactor: number=0;
    mor: number=0;
    coldCrush: number=0;
    plc: number = 0;
    abrasion: number = 0;
    density: number = 0;
    thermalShock: number = 0;
    thermalExpansion: number = 0;
    active: boolean =false;
    inactiveDate: Date = new Date();
    createdBy: string ="";
  }
  
  export class VesselZoneResponseViewModel {
    id: number=0;
    vesselUnit: UnitVesselZoneViewModel=new UnitVesselZoneViewModel();
    name: string ="";
    active: boolean =false;
    modifiedBy: string ="";
  }
  
  
  