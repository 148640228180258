import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { errorMessage } from '../../constants';


@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss'
})
export class NotFoundComponent implements OnInit {
  constructor(private router: Router){}
  errorMessage: string = errorMessage.notFound
  ngOnInit(): void {
   setTimeout(()=>this.router.navigateByUrl('/'),4000)
  }

}
