import { NgFor, NgIf, NgStyle } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { InputNumberModule } from 'primeng/inputnumber';
import { TableModule } from 'primeng/table';
import { ShellService } from '../../../shared/services/shell/shell.service';

import { InputSwitchModule } from 'primeng/inputswitch';
import { SiteService } from '../services/site/site.service';
import { FuelType } from '../models/fuelType.model';
import { Site } from '../models/site.model';
import { Unit, UnitViewModel } from '../models/unit.model';
import { UnitService } from '../services/unit/unit.service';
import { AccessLevel } from '../../../auth/app-roles';

@Component({
  selector: 'app-unit',
  standalone: true,
  imports: [DropdownModule, FormsModule, TableModule, NgIf, NgStyle, NgFor, DialogModule, InputNumberModule, ConfirmDialogModule, InputSwitchModule],
  providers: [DialogService, ConfirmationService],
  templateUrl: './unit.component.html',
  styleUrl: './unit.component.scss'
})
export class UnitComponent implements OnInit {

  //#region variable declaration
  units: Unit[] = [];
  unitItems: Unit[] = [];
  sites: Site[] = [];
  cols: any[] = [];
  first: number = 0;
  loading: boolean = false;
  selectedSite: any;
  selectedSiteName: any;
  selectedUnit: Unit | any;
  isFilterModeOn: boolean = false;
  isActive: boolean = true; //default is true
  public unitObj: UnitViewModel = new UnitViewModel()
  deleteObj: any;
  accessLevel: AccessLevel = AccessLevel.NoAccess;
  fuelTypes: FuelType[] = [];
  selectedFuelType: any;
  //#endregion

  constructor(private unitService: UnitService,
    private siteService: SiteService,
    private shellService: ShellService,
    private confirmationService: ConfirmationService,
    private dialogService: DialogService,
  ) { }

  ngOnInit(): void {
    this.accessLevel = this.shellService.getAppAccessLevel()
    if (this.accessLevel < AccessLevel.Contractor) {
      this.shellService.showErrorScreen("You don't have access to Unit Setup");
    }
    this.unitObj = new UnitViewModel();
    this.getSites(true);
    this.getFuelType();
    this.shellService.globalSiteUnitOutageSet.subscribe((data) => {
      this.getSites(true);
    });
  }

  headerInitialize() {
    this.cols = [
      { field: 'siteName', header: 'Site', width: '6%' },
      { field: 'name', header: 'Unit', width: '10%' },
      { field: 'yearBuilt', header: 'Year Built', width: '10%' },
      { field: 'markNumber', header: 'Mark #', width: '6%' },
      { field: 'ratedCapacity', header: 'Rated Capacity', width: this.accessLevel == AccessLevel.Administrator ? '12%' : '14%' },
      { field: 'currentCapacity', header: 'Current Capacity', width: this.accessLevel == AccessLevel.Administrator ? '12%' : '14%' },
      { field: 'averageFuelRate', header: 'Avg Fuel Rate', width: this.accessLevel == AccessLevel.Administrator ? '12%' : '14%' },
      { field: 'fuelType', header: 'Fuel Type', width: '7%' },
      { field: 'normalProduct', header: 'Normal Product', width: this.accessLevel == AccessLevel.Administrator ? '10%' : '12%' },
      { field: 'averageVelocity', header: 'Avg Velocity', width: '9%' }];
  }

  loadSiteUnitGrid() {
    this.unitService.getUnits().subscribe(response => {
      this.unitItems = response;
      this.headerInitialize();
    })
  }

  //#region dropdown binding
  getSites(autoSelect: boolean = false) {
    this.shellService.showLoadingIndicator(true);
    this.siteService.getSites().subscribe(response => {
      this.shellService.showLoadingIndicator(false);
      if (response) {
        this.sites = response;
        let siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(true);
        if (siteUnitOutage.siteId > 0 && autoSelect) {
          this.selectedSite = this.sites.filter(s => s.id == siteUnitOutage.siteId)[0];
          this.getUnits(this.selectedSite.id, autoSelect);
        } else {
          this.filterRecords();
        }
      }
    }, error => {
      this.shellService.showLoadingIndicator(false);
    });
  }

  getFuelType() {
    this.unitService.getFuelType().subscribe(response => {
      if (response) {
        this.fuelTypes = response;
      }
    });
  }

  getUnits(siteId: number, autoSelect: boolean = false) {
    this.units = [];
    this.selectedUnit = undefined;
    this.siteService.getUnitBySiteId(siteId).subscribe(response => {
      if (response) {
        this.units = response;
        let siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(true);
        if (siteUnitOutage.unitId > 0 && autoSelect) {
          this.selectedUnit = this.units.filter(u => u.unitId == siteUnitOutage.unitId)[0];
          this.filterRecords();
        }
      }
    })
  }
  //#endregion

  //#region filter methods
  filterRecords() {
    var FilterUnits = {
      siteId: this.selectedSite ? this.selectedSite.id : 0,
      unitName: this.selectedUnit ? this.selectedUnit.unitName : "",
      active: this.isActive
    }
    this.loading = true;
    this.unitService.filterUnits(FilterUnits).subscribe(response => {
      this.headerInitialize();
      this.first = 0
      if (response) {
        this.unitItems = response;
      } else {
        this.unitItems = [];
      }
      this.loading = false;
    }, error => {
      this.loading = false;
    })
  }

  resetFilterControls() {
    this.selectedSite = [];
    this.selectedUnit = new Unit();
    this.isActive = true;
    this.loadSiteUnitGrid();
  }

  showFilter() {
    this.isFilterModeOn = true;
  }

  closeFilter() {
    this.isFilterModeOn = false;
  }

  onSiteChange(event: any) {
    this.getUnits(event.value.id);
  }
  //#endregion

  toggleUnitDialog() {
    this.unitDialog.opened = !this.unitDialog.opened;
    if (this.unitDialog.opened) {
      this.resetUnitDialog();
      this.selectedSiteName = [];
    }
  }

  unitDialog = {
    opened: false,
    isSiteNameEmpty: false,
    isUnitEmpty: false,
    isUpdate: false,
  }

  resetUnitDialog() {
    this.unitObj = new UnitViewModel();
    this.unitDialog.isSiteNameEmpty = false;
    this.unitDialog.isUnitEmpty = false;
    if (!this.unitDialog.isUpdate) {
      this.selectedSiteName = [];
    }
    this.unitObj.name = "";
    this.selectedFuelType = [];
    this.unitDialog.isUpdate = false;
  }

  deleteRow(obj: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete this unit? This will also delete the associated vessels links, zones links, outages and nominal lining.',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'aq-destructive ',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'aq-outline-primary',
      accept: () => {
        if (obj.id) {
          this.shellService.showLoadingIndicator(true);
          this.unitService.deleteUnit(obj.id).subscribe(response => {
            this.filterRecords();
            this.shellService.alert(this.dialogService, { title: 'Success!', message: 'Unit details deleted successfully', severity: 'success' })
            let siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(true);
            if (siteUnitOutage.unitId == obj.id) {
              this.shellService.resetConfig(true);
            }
            this.shellService.showLoadingIndicator(false);
          }, error => {
            this.shellService.showLoadingIndicator(false);
          })
        }
      }
    });
  }

  reactivateRow(obj: any) {
    this.confirmationService.confirm({
      message: 'Are you sure you want to reactivate this unit?',
      acceptLabel: 'Activate',
      acceptIcon: 'pi pi-chevron-circle-up',
      acceptButtonStyleClass: 'aq-success',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'aq-outline-primary',
      accept: () => {
        var tempPatchArrayObj = [];
        var patchArrayObj = [];
        tempPatchArrayObj.push(this.shellService.createPatchArray("active", true))
        tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))

        for (var i = 0; i < tempPatchArrayObj.length; i++) {   //pushing the 0th
          patchArrayObj.push(tempPatchArrayObj[i][0]);
        }

        var unitViewModel = patchArrayObj;

        if (unitViewModel.length > 0 && obj.id) {
          this.deleteUpdateUnit(obj.id, unitViewModel, 'activate');
        }
      }
    });
  }

  editRow(obj: any) {
    this.unitObj = new UnitViewModel();
    this.unitObj.yearBuilt = obj.yearBuilt;
    this.unitObj.id = obj.id;
    this.unitObj.name = obj.name;
    this.unitObj.markNumber = obj.markNumber;
    this.unitObj.ratedCapacity = obj.ratedCapacity;
    this.unitObj.normalProduct = obj.normalProduct;
    this.unitObj.currentCapacity = obj.currentCapacity;
    this.unitObj.averageVelocity = obj.averageVelocity;
    this.unitObj.fuelType = obj.fuelType;
    this.unitObj.fuelTypeId = obj.fuelTypeId ? obj.fuelTypeId : 0;
    this.unitObj.averageFuelRate = obj.averageFuelRate;
    this.unitObj.modifiedBy = obj.modifiedBy;
    this.unitObj.active = obj.active;

    if (obj.siteName) {
      let rowIndex = this.sites.findIndex(x => x.name == obj.siteName);
      this.selectedSiteName = this.sites[rowIndex];
    } else {
      this.selectedSiteName = [];
    }

    if (obj.fuelType) {
      let rowIndex = this.fuelTypes.findIndex(x => x.name == obj.fuelType);
      this.selectedFuelType = this.fuelTypes[rowIndex];
    } else {
      this.selectedFuelType = [];
    }
    this.unitDialog.isUpdate = true;
    this.unitDialog.opened = true;
    this.setRowData();
  }

  addUnit() {
    if (!this.validateUnitForm()) {
      return;
    }
    this.unitObj.siteId = this.selectedSiteName ? this.selectedSiteName.id : 0;
    this.unitObj.fuelTypeId = this.selectedFuelType ? this.selectedFuelType.id : 0;
    this.unitObj.createdDate = new Date();
    this.shellService.showLoadingIndicator(true);
    this.unitService.addUnit(this.unitObj).subscribe(response => {
      this.shellService.showLoadingIndicator(false);
      let siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(true);
      var siteId = this.selectedSiteName ? this.selectedSiteName.id : 0;
      if (siteId > 0) {
        this.selectedSite = this.sites.filter(s => s.id == siteId)[0];
        this.getUnits(this.selectedSite.id);
      } else {
        this.selectedSite = this.sites.filter(s => s.id == siteUnitOutage.siteId)[0];
        this.getUnits(this.selectedSite.id);
      }
      this.toggleUnitDialog();
      this.shellService.alert(this.dialogService, { title: 'Success!', message: 'Saved Successfully', severity: 'success' })
      this.setGlobalDetails(response, this.unitObj.name);
    }, error => {
      this.shellService.showLoadingIndicator(false);
      this.toggleUnitDialog();
    })
  }

  setGlobalDetails(unitId: number, unitName: string) {
    this.unitService.getNominalOutageByUnitId(unitId).subscribe(outage => {
      this.shellService.showLoadingIndicator(false);
      if (outage) {
        var globalDetailsObj = {
          siteId: this.selectedSiteName.id,
          siteName: this.selectedSiteName.name,
          countryId: this.selectedSiteName.countryId,
          unitId: unitId,
          unitName: unitName,
          outageId: outage.id,
          outageName: outage.name
        }
        this.shellService.setGlobalDetails(globalDetailsObj);
      }
    }, error => {
      this.shellService.showLoadingIndicator(false);
      this.shellService.alert(this.dialogService, { title: 'Error!', message: 'Failed to get default nominal lining outage', severity: 'error' })
    })
  }

  updateUnit() {
    if (!this.validateUnitForm()) {
      return;
    }
    let unit = {
      id: this.unitObj.id,
      name: this.unitObj.name,
      siteId: this.selectedSiteName ? this.selectedSiteName.id : 0,
      markNumber: this.unitObj.markNumber,
      yearBuilt: this.unitObj.yearBuilt,
      ratedCapacity: this.unitObj.ratedCapacity,
      normalProduct: this.unitObj.normalProduct,
      currentCapacity: this.unitObj.currentCapacity,
      averageVelocity: this.unitObj.averageVelocity,
      fuelTypeId: this.selectedFuelType ? this.selectedFuelType.id : 0,
      averageFuelRate: this.unitObj.averageFuelRate,
      active: this.unitObj.active,
    }
    var unitViewModel = this.bindUnitPatchObject(unit);
    if (unitViewModel.length > 0) {
      this.deleteUpdateUnit(unit.id, unitViewModel, 'update');
    }
  }

  deleteUpdateUnit(id: number, unitViewModel: any, type: string) {
    this.shellService.showLoadingIndicator(true);
    this.unitService.updateUnit(id, unitViewModel).subscribe(response => {
      this.shellService.showLoadingIndicator(false);
      if (type == 'activate') {
        this.filterRecords();
        this.shellService.alert(this.dialogService, { title: 'Success!', message: 'Unit activated successfully!', severity: 'success' })
      } else {
        this.toggleUnitDialog();
        this.setGlobalDetails(id, this.unitObj.name);
        let siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(true);
        var siteId = this.selectedSiteName ? this.selectedSiteName.id : 0;
        if (siteId > 0) {
          this.selectedSite = this.sites.filter(s => s.id == siteId)[0];
          this.getUnits(this.selectedSite.id);
        } else {
          this.selectedSite = this.sites.filter(s => s.id == siteUnitOutage.siteId)[0];
          this.getUnits(this.selectedSite.id);
        }
        this.shellService.alert(this.dialogService, { title: 'Success!', message: 'Unit details updated successfully!', severity: 'suceess' })
      }
    }, error => {
      this.shellService.showLoadingIndicator(false);
      this.toggleUnitDialog();
    })
  }

  //create array only for modified fields
  tempPatchArrayObj: any = [];
  bindUnitPatchObject(unit: any) {
    this.tempPatchArrayObj = [];
    var patchArrayObj = [];
    if (unit.id != this.gridRowData.id) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("id", unit.id))
    }
    if (unit.name != this.gridRowData.name) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("name", unit.name))
    }
    if (unit.siteId != this.gridRowData.siteId) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("siteId", unit.siteId))
    }
    if (unit.markNumber != this.gridRowData.markNumber) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("markNumber", unit.markNumber))
    }
    if (unit.yearBuilt != this.gridRowData.yearBuilt) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("yearBuilt", unit.yearBuilt))
    }
    if (unit.ratedCapacity != this.gridRowData.ratedCapacity) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("ratedCapacity", unit.ratedCapacity))
    }
    if (unit.normalProduct != this.gridRowData.normalProduct) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("normalProduct", unit.normalProduct))
    }
    if (unit.currentCapacity != this.gridRowData.currentCapacity) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("currentCapacity", unit.currentCapacity))
    }
    if (unit.averageVelocity != this.gridRowData.averageVelocity) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("averageVelocity", unit.averageVelocity))
    }
    if (unit.fuelTypeId != this.gridRowData.fuelTypeId) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("fuelTypeId", unit.fuelTypeId))
    }
    if (unit.averageFuelRate != this.gridRowData.averageFuelRate) {
      this.tempPatchArrayObj.push(this.shellService.createPatchArray("averageFuelRate", unit.averageFuelRate))
    }

    this.tempPatchArrayObj.push(this.shellService.createPatchArray("active", unit.active))
    this.tempPatchArrayObj.push(this.shellService.createPatchArray("modifiedDate", new Date()))

    if (this.tempPatchArrayObj.length > 2) { //count is 2 because there is default 2 array created
      for (var i = 0; i < this.tempPatchArrayObj.length; i++) {   //pushing the 0th
        patchArrayObj.push(this.tempPatchArrayObj[i][0]);
      }
    }
    return patchArrayObj;
  }

  //sets the original grid data
  gridRowData: any;
  setRowData() {
    this.gridRowData = {
      id: this.unitObj.id,
      name: this.unitObj.name,
      siteId: this.selectedSiteName ? this.selectedSiteName.id : 0,
      markNumber: this.unitObj.markNumber,
      yearBuilt: this.unitObj.yearBuilt,
      ratedCapacity: this.unitObj.ratedCapacity,
      normalProduct: this.unitObj.normalProduct,
      currentCapacity: this.unitObj.currentCapacity,
      averageVelocity: this.unitObj.averageVelocity,
      fuelType: this.unitObj.fuelType,
      fuelTypeId: this.unitObj.fuelTypeId,
      averageFuelRate: this.unitObj.averageFuelRate,
      active: this.unitObj.active,
    }
  }

  validateUnitForm() {
    var isValid = true;
    var siteName = this.selectedSiteName ? this.selectedSiteName.name : "";
    if (siteName) {
      this.unitDialog.isSiteNameEmpty = false;
    } else {
      this.unitDialog.isSiteNameEmpty = true;
      isValid = false;
    }

    if (this.unitObj.name && this.unitObj.name !== '') {
      this.unitDialog.isUnitEmpty = false;
    } else {
      isValid = false;
      this.unitDialog.isUnitEmpty = true;
    }
    return isValid;
  }
}
