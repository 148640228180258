// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.nominal-other-details-lbl {
  font-size: 11px;
  font-weight: 600;
  color: rgb(153, 153, 153);
}

.nominal-other-details-lbl2 {
  font-size: 11px;
  font-weight: 600;
  color: rgb(0, 153, 255);
}

:host ::ng-deep .repair-area-cls .p-inputtext {
  width: 5rem;
}

:host ::ng-deep .border-color .p-inputtext {
  border: 1px red !important;
}

.margin-top {
  top: 15px;
}

.text-left {
  margin-left: 0px;
}

.text-top {
  margin-top: 30px;
}

:host ::ng-deep .dropdown-border-color .p-dropdown {
  border: 1px solid red !important;
}

.product-inactive {
  color: rgba(238, 0, 0, 0.7333333333);
}
.product-inactive ::after {
  content: " (Inactive)";
  color: rgba(238, 127, 0, 0.8);
}`, "",{"version":3,"sources":["webpack://./src/app/module/utilities/nominal-lining/nominal-lining.component.scss"],"names":[],"mappings":"AACA;EACI,eAAA;EACA,gBAAA;EACA,yBAAA;AAAJ;;AAGE;EACE,eAAA;EACA,gBAAA;EACA,uBAAA;AAAJ;;AAGE;EACE,WAAA;AAAJ;;AAEE;EACE,0BAAA;AACJ;;AACE;EACE,SAAA;AAEJ;;AAAE;EACI,gBAAA;AAGN;;AADE;EACE,gBAAA;AAIJ;;AAFE;EACE,gCAAA;AAKJ;;AAFE;EACE,oCAAA;AAKJ;AAHI;EACI,sBAAA;EACA,6BAAA;AAKR","sourcesContent":["\n.nominal-other-details-lbl{\n    font-size:11px;\n    font-weight: 600;\n    color: rgb(153, 153, 153);\n  }\n  \n  .nominal-other-details-lbl2{\n    font-size:11px;\n    font-weight: 600;\n    color: rgb(0, 153, 255);\n  }\n  \n  :host ::ng-deep .repair-area-cls .p-inputtext {\n    width: 5rem;\n  }\n  :host ::ng-deep .border-color .p-inputtext {\n    border: 1px red !important;\n  }\n  .margin-top {\n    top: 15px;\n  }\n  .text-left{\n      margin-left:0px;\n  }\n  .text-top {\n    margin-top: 30px;\n  }\n  :host ::ng-deep .dropdown-border-color .p-dropdown {\n    border: 1px solid red !important;\n  }\n  \n  .product-inactive {\n    color: #e00b;\n\n    ::after{\n        content:\" (Inactive)\";\n        color: rgba(238, 127, 0, 0.8);\n    }\n  }\n  \n//   .product-inactive::after {\n   \n//   }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
