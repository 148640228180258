import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppHttpHeaders } from '../../../../shared/services/common/app-http-headers';

import { catchError, map } from 'rxjs';
import { PaginatedResult } from '../../models/paginatedResult.model';
import { SiteUnitVesselViewModel } from '../../models/vessel.model';
import { ShellService } from '../../../../shared/services/shell/shell.service';

@Injectable({
  providedIn: 'root'
})
export class UnitVesselService {
  appReqHeader = new AppHttpHeaders()
  vesselAccessPointUrl: string=this.appReqHeader.domainURL+'api/Vessel'
  vesselMapAccessPointUrl: string=this.appReqHeader.domainURL+'api/VesselMap'

  constructor(private http: HttpClient, private shellService: ShellService) { }

  getVessel() {
    return this.http.get<any>(this.vesselAccessPointUrl, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  searchVessels(filterObj: any, pageNumber?: number, pageSize?: number) {
    const paginatedResult:PaginatedResult<SiteUnitVesselViewModel[]> =new PaginatedResult<SiteUnitVesselViewModel[]>();
    let params=new HttpParams();
    if(pageNumber!=null&&pageSize!=null){
      params=params.append('PageNumber',pageNumber.toString());
      params=params.append('PageSize',pageSize.toString());
    }
    return this.http.post<SiteUnitVesselViewModel[]>(this.vesselMapAccessPointUrl + '/SearchVesselMaps', filterObj, {observe:'response', params, headers: this.appReqHeader.formHeaders}).pipe(
      map(response=>{
        paginatedResult.result=response.body != null ? response.body : [];
        if(response.headers.get('X-Pagination')!=null) {
          paginatedResult.pagination=JSON.parse(response.headers.get('X-Pagination') || '{}');
        }
        return paginatedResult;
      }), catchError(err => this.shellService.handleError(err))
    );
  }

  addVesselUnitMap(vesselViewModel: any) {
    return this.http.post<any>(this.vesselMapAccessPointUrl, vesselViewModel, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  updateVesselUnitMap(vesselId: number, unitId: number, patchVesselViewModel: any) {
    var query = "";
    if (vesselId) {
      query = "/" + vesselId;
    }
    if (unitId) {
      query = query + "/Unit/" + unitId;
    }
    return this.http.patch<any>(this.vesselMapAccessPointUrl + query, patchVesselViewModel, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  deleteVessel(vesselId: number, unitId: number) {
    var query = "";
    if (vesselId) {
      query = query + "/" + vesselId;
    }
    if (unitId) {
      query = query + "/Unit/" + unitId;
    }
    return this.http.delete<any>(this.vesselMapAccessPointUrl + query, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
}
