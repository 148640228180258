import { NgClass, NgIf } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { GalleriaModule } from 'primeng/galleria';
import { ImageModule } from 'primeng/image';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { environment } from '../../../../environments/environment';
import {  GlobalSiteUnitOutage, ShellService } from '../../../shared/services/shell/shell.service';
import { Picture } from '../models/picture.model';
import { Vessel } from '../models/vessel.model';
import { Zone } from '../models/zone.model';
import { InspectionService } from '../services/inspection/inspection.service';
import { OutageService } from '../services/outage/outage.service';
import { UnitService } from '../services/unit/unit.service';
import { AccessLevel } from '../../../auth/app-roles';

@Component({
  selector: 'app-inspection-comments',
  standalone: true,
  imports: [DropdownModule,ButtonModule,TooltipModule,GalleriaModule,ImageModule,TableModule,ConfirmDialogModule,NgClass,NgIf,FormsModule],
  providers: [DialogService, ConfirmationService],
  templateUrl: './inspection-comments.component.html',
  styleUrl: './inspection-comments.component.scss'
})
export class InspectionCommentsComponent implements OnInit {
  showingGrid = false
  loading = false
  loadingPictures = false
  addNewRecordMode = false

  filterVessels: Vessel[] = []
  filterZones: Zone[] = []
  filteredVessel: Vessel | any
  filteredZone: Zone | any

  isVesselNameEmpty = true
  isZoneNameEmpty = false

  generalOutageComment: string = ""
  inspectionComment: string = ""
  futureOutageNotes: string = ""
  inspectionId: number = 0;
  accessLevel: AccessLevel = AccessLevel.NoAccess;
  tableData: any = [];
  cols = [
    { field: "vesselName", header: "Vessel", width: "15%" },
    { field: "zoneName", header: "Zone", width: "20%" },
    { field: "inspectionComment", header: "Inspection Comments", width: "30%" },
    { field: "futureOutageNotes", header: "Notes For Future Outages", width: "35%" },
  ]
  vesselZoneImages: Picture[] = []
  pictureId: number = 0

  inspectionPicturesForGrid: Picture[] = []
  selectedInspection: any;
  @ViewChild('file')
  uploadButton!: ElementRef

  globalSiteUnitOutage: GlobalSiteUnitOutage | undefined = undefined;

  constructor(private inspectionService: InspectionService,
    private outageService: OutageService,
    private shellService: ShellService,
    private unitService: UnitService,
    private confirmationService: ConfirmationService,
    // private alertService: AlertModalService,
    private dialogService: DialogService,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    // this.shellService.getAccessLevel().then((accessLevel) => {
    //   this.accessLevel = accessLevel;
    // })
    this.accessLevel=this.shellService.getAppAccessLevel();
    this.globalSiteUnitOutage = this.shellService.loadGlobalSiteUnitOutage();
    this.getVesselsForFilter(this.globalSiteUnitOutage.unitId);
    if (this.globalSiteUnitOutage.outageId && this.globalSiteUnitOutage.outageId > 0) {
      this.loadGeneralComment(this.globalSiteUnitOutage.outageId);
    }

    this.shellService.globalSiteUnitOutageSet.subscribe((obj) => {
      this.globalSiteUnitOutage = obj;
      this.getVesselsForFilter(obj.unitId);
      this.inspectionId = 0;
      this.filteredVessel = undefined;
      this.vesselZoneImages = [];
      this.filteredZone = undefined;
      this.filterZones = [];
      this.tableData = [];
      if (this.showingGrid) {
        this.selectedInspection = undefined;
        this.loadGridData();
      } else {
        this.inspectionComment = "";
        if (obj.outageId && obj.outageId > 0) {
          this.loadGeneralComment(obj.outageId);
        }
        this.futureOutageNotes = "";
      }
    })
    if (
      this.activatedRoute.snapshot.queryParamMap.get('siteId') &&
      this.activatedRoute.snapshot.queryParamMap.get('unitId')
    ) {
      if (
        Number(this.activatedRoute.snapshot.queryParamMap.get('siteId')) !=
        this.globalSiteUnitOutage.siteId ||
        Number(this.activatedRoute.snapshot.queryParamMap.get('unitId')) !=
        this.globalSiteUnitOutage.unitId ||
        Number(this.activatedRoute.snapshot.queryParamMap.get('outageId')) !=
        this.globalSiteUnitOutage.outageId
      )
        this.shellService.resetConfig(true);
    }
    if (
      Number(this.activatedRoute.snapshot.queryParamMap.get('unitId')) &&
      Number(this.activatedRoute.snapshot.queryParamMap.get('vesselId'))
    ) {
      this.getVesselsForFilter(
        Number(this.activatedRoute.snapshot.queryParamMap.get('unitId'))
      );
    }
  }

  toggleGrid(): void {
    this.showingGrid = !this.showingGrid;
    this.inspectionId = 0;
    this.filteredVessel = undefined;
    this.vesselZoneImages = [];
    if (this.showingGrid) {
      this.inspectionComment = "";
      this.futureOutageNotes = "";
      this.selectedInspection = undefined;
      this.loadGridData();
    } else {
      this.filteredZone = undefined;
      this.filterZones = [];
      this.tableData = [];
    }
  }

  getVesselsForFilter(unitId: number = 0): void {
    this.unitService.getVesselByUnitId(unitId).subscribe(response => {
      if (response) {
        this.filterVessels = response;
        let parameterVessel = Number(this.activatedRoute.snapshot.queryParamMap.get('vesselId'))
        if (parameterVessel > 0) {
          let requiredVessel = this.filterVessels.find((vessel) => { return vessel.id === parameterVessel })
          if (requiredVessel) {
            this.filteredVessel = requiredVessel
            this.onChangeVessel(requiredVessel)
          }
          window.history.replaceState({}, document.title, "/#/shutdown-execution/inspection");
        }
      }
    })
  }
  /* Only zones that are in the nominal lining
   * and have no inspection records associated with
   * them will be displayed here
   */

  getZonesForFilter(unitId: number, outageId: number, vesselId: number): void {
    this.filterZones = [];
    if (this.addNewRecordMode) {
      this.inspectionService.getAvailableZonesByVessel(unitId, outageId, vesselId).subscribe(response => {
        if (response) {
          this.filterZones = response;
        }
      })
    } else {
      this.unitService.getZoneBySiteUnitVesselId(unitId, vesselId).subscribe(response => {
        if (response) {
          this.filterZones = response;
        }
      })
    }
  }

  onChangeVessel(vessel: any): void {
    var globalObj = this.shellService.loadGlobalSiteUnitOutage();
    if (this.showingGrid) {
      this.loadGridData(vessel.id);
    } else {
      this.getZonesForFilter(globalObj.unitId, globalObj.outageId, vessel.id);
      this.isZoneNameEmpty = true;
    }
    this.filteredZone = undefined;
    this.isVesselNameEmpty = false;
  }

  onChangeZone(zone: any): void {
    if (!this.showingGrid) {
      if (!this.addNewRecordMode) {
        var siteUnitOutageObj = this.shellService.loadGlobalSiteUnitOutage();
        this.loadInspectionComments(siteUnitOutageObj.unitId, siteUnitOutageObj.outageId);
      }
    }
    this.isZoneNameEmpty = false;
  }

  validateFilterSelection(): boolean {
    var isValid = true;

    var vesslName = this.filteredVessel ? this.filteredVessel.name : "";
    if (vesslName) {
      this.isVesselNameEmpty = false;
    } else {
      this.isVesselNameEmpty = true;
      isValid = false;
    }
    var zone = this.filteredZone ? this.filteredZone.name : "";
    if (zone) {
      this.isZoneNameEmpty = false;
    } else {
      this.isZoneNameEmpty = true;
      isValid = false;
    }
    return isValid;
  }

  loadGridData(vesselId: number = 0): void {
    this.loading = true;
    var outageId = this.shellService.loadGlobalSiteUnitOutage().outageId;
    this.inspectionService.getAllRecords(vesselId, outageId).subscribe((response) => {
      this.tableData = response;
      this.loading = false;
    }, (error) => {
      this.loading = false;
    })
  }

  loadGeneralComment(outageId: number) {
    this.shellService.showLoadingIndicator(true);
    this.outageService.getOutageById(outageId).subscribe((response) => {
      if (response == null || response.generalComments == null) {
        this.generalOutageComment = "";
      } else {
        this.generalOutageComment = "" + response.generalComments;
      }
      this.shellService.showLoadingIndicator(false);
    }, (error) => {
      this.shellService.showLoadingIndicator(false);
    })
  }

  loadInspectionComments(unitId: number, outageId: number) {
    if (!this.validateFilterSelection()) {
      return;
    }
    this.shellService.showLoadingIndicator(true);
    this.inspectionService.getInspectionComments(this.filteredVessel.id,
      this.filteredZone.id,
      unitId,
      outageId).subscribe((response: any) => {
        if (response != null) {
          this.inspectionComment = response.inspectionComment ? response.inspectionComment + "" : "";
          this.futureOutageNotes = response.futureOutageNotes ? response.futureOutageNotes + "" : "";
        } else {
          this.inspectionComment = "";
          this.futureOutageNotes = "";
        }
        this.shellService.showLoadingIndicator(false);
        if (response.inspectionId != undefined) {
          this.inspectionId = response.inspectionId;
          this.getPicturesByInspectionId(this.inspectionId);
        } else {
          this.shellService.showLoadingIndicator(false);
          this.loadingPictures = false;
        }

      }, (error) => {
        this.shellService.showLoadingIndicator(false);
        this.loadingPictures = false;
      });
  }

  getPicturesByInspectionId(inspectionId: number) {
    this.loadingPictures = true;
    this.vesselZoneImages = [];
    this.inspectionService.getPictures(inspectionId).subscribe((imageList: Picture[]) => {
      // This logic is added to show the images properly in the Inspection Comments page
      // @Author - Rahul
      // User Story - 3653
      if (imageList && imageList.length) {
        for (let j = 0; j < imageList.length; j++) {
          var data = 'data:image/png;base64,' + imageList[j].data;
          var picture: Picture = new Picture();
          picture.id = imageList[j].id;
          picture.name = imageList[j].name;
          picture.uri = imageList[j].uri;
          picture.data = this.sanitizer.bypassSecurityTrustUrl(data);
          this.vesselZoneImages.push(picture);
        }
      }
      this.shellService.showLoadingIndicator(false);
      this.loadingPictures = false;
    }, (error) => {
      this.shellService.showLoadingIndicator(false);
      this.loadingPictures = false;
    });
  }

  onDataSelect(event: any) {
    if (event) {
      this.getPicturesByInspectionId(event.data.inspectionId)
    }
  }

  // Modifying data
  addRecord(): void {
    this.filteredZone = undefined;
    this.filteredZone = [];
    if (this.addNewRecordMode) {
      this.inspectionComment = "";
      this.futureOutageNotes = "";
      this.filteredVessel = undefined;
    } else {
      if (this.inspectionComment != "" || this.futureOutageNotes != "") {
        this.confirmationService.confirm({
          message: 'Save and exit "Add New Record" mode?',
          acceptLabel: 'Save',
          acceptIcon: 'pi pi-save',
          acceptButtonStyleClass: 'p-button',
          rejectLabel: 'Discard',
          rejectIcon: 'pi pi-trash',
          rejectButtonStyleClass: 'aq-outline-primary',
          accept: () => {
            this.saveInspectionComments();
          },
          reject: () => {
            var siteUnitOutageObj = this.shellService.loadGlobalSiteUnitOutage();
            this.loadInspectionComments(siteUnitOutageObj.unitId, siteUnitOutageObj.outageId);
          }
        });
      }
      if (this.filteredVessel != undefined) {
        this.onChangeVessel(this.filteredVessel);
      }
    }
  }

  addPicture(files: any): any {
    if (files != null) {
      var iid = this.inspectionId;
      this.inspectionId = 0;
      this.vesselZoneImages = [];
      this.loadingPictures = true;
      var siteUnitOutageObj = this.shellService.loadGlobalSiteUnitOutage();
      this.inspectionService.uploadPicture(iid, files, this.filteredVessel.id, this.filteredZone.id, siteUnitOutageObj.unitId, siteUnitOutageObj.outageId, this.inspectionComment ? this.inspectionComment : "", this.futureOutageNotes ? this.futureOutageNotes : "").subscribe((response: any) => {
        this.shellService.alert(this.dialogService, { title: 'Saved!', message: 'The picture has been uploaded successfully', severity: 'success' })
        this.inspectionId = response;
        this.loadingPictures = false;
        this.loadInspectionComments(siteUnitOutageObj.unitId, siteUnitOutageObj.outageId);
        this.uploadButton.nativeElement.value = '';
      }, (error) => {
        this.loadingPictures = false;
        this.uploadButton.nativeElement.value = '';
      })
    }

  }

  deletePicture(): void {
    this.confirmationService.confirm({
      message: 'Delete this picture associated with this zone?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'aq-destructive',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'aq-outline-primary',
      accept: () => {
        var id = this.vesselZoneImages[this.pictureId].id;
        this.inspectionId = 0;
        this.loadingPictures = true;
        this.inspectionService.deletePicture(id).subscribe((event: any) => {
          // this.alertService.openAlert("Deleted!", "The picture has deleted successfully", "success");
          this.shellService.alert(this.dialogService, { title: 'Deleted!', message: 'The picture has been deleted successfully', severity: 'success' })
          this.vesselZoneImages = [];
          this.pictureId = 0;
          this.loadingPictures = false;
          var siteUnitOutageObj = this.shellService.loadGlobalSiteUnitOutage();
          this.loadInspectionComments(siteUnitOutageObj.unitId, siteUnitOutageObj.outageId);
        }, (error) => {
          this.loadingPictures = false;
        })
      }
    });
  }

  saveGeneralOutageComment(): void {
    this.shellService.showLoadingIndicator(true);
    var outageId = this.shellService.loadGlobalSiteUnitOutage().outageId;
    var patchObj = {
      "value": this.generalOutageComment,
      "path": "/generalComments",
      "op": "replace"
    }
    this.outageService.patchUpdate(outageId, [patchObj]).subscribe((response) => {
      this.shellService.showLoadingIndicator(false);
      // this.alertService.openAlert("Saved!", "Outage Comment saved successfully!", "success");
      this.shellService.alert(this.dialogService, { title: 'Saved!', message: 'Outage Comment saved successfully!', severity: 'success' })
    }, (error) => {
      this.shellService.showLoadingIndicator(false);
    })
  }

  deleteGeneralOutageComment(): void {
    this.confirmationService.confirm({
      message: 'Delete the general outage comment for this outage?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'aq-destructive',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'aq-outline-primary',
      accept: () => {
        this.generalOutageComment = "";
        this.saveGeneralOutageComment();
      }
    });
  }

  saveInspectionComments(): boolean {
    if (this.validateFilterSelection()) {
      this.shellService.showLoadingIndicator(true);
      var siteUnitOutageObj = this.shellService.loadGlobalSiteUnitOutage();
      this.inspectionService.setInspectionComments(this.filteredVessel.id,
        this.filteredZone.id,
        siteUnitOutageObj.unitId,
        siteUnitOutageObj.outageId,
        this.inspectionComment,
        this.futureOutageNotes).subscribe((response) => {
          this.inspectionId = response;
          this.shellService.showLoadingIndicator(false);
          this.addNewRecordMode = false;
          // this.alertService.openAlert("Saved!", "Inspection comments and notes have been saved successfully", "success");
          this.shellService.alert(this.dialogService, { title: 'Saved!', message: 'Inspection comments and notes have been saved successfully', severity: 'succes' })
          var siteUnitOutageObj = this.shellService.loadGlobalSiteUnitOutage();
          this.loadInspectionComments(siteUnitOutageObj.unitId, siteUnitOutageObj.outageId);
        }, (error) => {
          this.shellService.showLoadingIndicator(false);
        })
    } else {
      return false;
    }
    return true;
  }

  deleteRecord(): void {
    this.confirmationService.confirm({
      message: 'Delete the inspection comments, notes and pictures for selected vessel and zone?',
      acceptLabel: 'Delete',
      acceptIcon: 'pi pi-trash',
      acceptButtonStyleClass: 'aq-destructive',
      rejectLabel: 'Cancel',
      rejectIcon: 'pi pi-ban',
      rejectButtonStyleClass: 'aq-outline-primary',
      accept: () => {
        var siteUnitOutageObj = this.shellService.loadGlobalSiteUnitOutage();
        this.shellService.showLoadingIndicator(true);
        this.inspectionService.deleteRecord(this.filteredVessel.id,
          this.filteredZone.id,
          siteUnitOutageObj.unitId,
          siteUnitOutageObj.outageId).subscribe((response) => {
            this.vesselZoneImages = [];
            this.inspectionId = 0;
            this.shellService.showLoadingIndicator(false);
            // this.alertService.openAlert("Deleted!", "Inspection notes and pictures have been deleted for this vessel, zone and outage.", "success");
            this.shellService.alert(this.dialogService, { title: 'Deleted!', message: 'Inspection notes and pictures have been deleted for this vessel, zone and outage', severity: 'success' })
            this.loadInspectionComments(siteUnitOutageObj.unitId, siteUnitOutageObj.outageId);
            this.filteredZone = undefined;
          }, (error) => {
            this.shellService.showLoadingIndicator(false);
          })
      }
    });
  }

  insertSuggestion(event: any): void {
    if (this.futureOutageNotes == undefined) {
      this.futureOutageNotes = "";
    }
    this.futureOutageNotes = this.futureOutageNotes + " " + event.target.innerText;
  }

  //#region Flatout
  openFlatout() {
    if (this.globalSiteUnitOutage) {
      var url = environment.apiConfig.flatoutUri + "?" +
        "siteId=" + this.globalSiteUnitOutage.siteId +
        "&unitId=" + this.globalSiteUnitOutage.unitId;

      if (!this.globalSiteUnitOutage.outageName.includes("Nominal Lining")) {
        url += "&outageId=" + this.globalSiteUnitOutage.outageId;
      }

      if (this.filteredVessel) {
        url += "&vesselId=" + this.filteredVessel.id;
      }
      window.open(url, "_blank");
    }
  }
  //#endregion
}
