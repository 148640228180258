import { AccountInfo } from "@azure/msal-browser"

export const roles = {
  Admin: "Administrator",
  BasicUser: "StandardUser",
  Contractor: "Contractor",
  ReadOnly: "ReadOnly"
}
export enum AccessLevel {
  NoAccess = 0,
  ReadOnly = 1,
  Contractor = 2,
  BasicUser = 3,
  Administrator = 4
}

export var AccessLevelName = ["No Access", "Read Only", "Contractor", "Basic User", "Administrator"]

/**
 * Find the app role from account info 
 * @param userAccountInfo 
 * @returns 
 */
export function findAppRoleFromAccountInfo(userAccountInfo: AccountInfo): AccessLevel {
  const idTokenClaims = userAccountInfo.idTokenClaims;
  if (idTokenClaims) {
    var roles = idTokenClaims.roles
    if (roles && roles.length > 0) {
      let appRole = AccessLevel.NoAccess
      for (let i = 0; i < roles.length; i++) 
        appRole = evaluateAccessLevel(roles[i])
      return appRole
    }
  }
  return AccessLevel.NoAccess;
}

/**
 * Evaluates access level 
 * @param role 
 * @returns 
 */
function evaluateAccessLevel(role: string): AccessLevel {
  var maxRole = AccessLevel.NoAccess
  switch (role) {
    case roles.ReadOnly:
      maxRole = AccessLevel.ReadOnly > maxRole ? AccessLevel.ReadOnly : maxRole;
      break;
    case roles.Contractor:
       maxRole=AccessLevel.Contractor>maxRole?AccessLevel.Contractor:maxRole;
       break;
    case roles.BasicUser:
      maxRole= AccessLevel.BasicUser>maxRole?AccessLevel.BasicUser:maxRole;
      break;
    case roles.Admin:
     maxRole= AccessLevel.Administrator>maxRole?AccessLevel.Administrator:maxRole;
  }
  return maxRole;
}