import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs';
import { AppHttpHeaders } from '../../../../shared/services/common/app-http-headers';
import { ShellService } from '../../../../shared/services/shell/shell.service';



@Injectable({
  providedIn: 'root'
})
export class VesselProductService {
  appReqHeader = new AppHttpHeaders();
  accessPointUrl = this.appReqHeader.domainURL + 'api/VesselProduct';

  constructor(private http: HttpClient, private shellService: ShellService) { }

  loadVesselProduct(searchObj: any) {
    return this.http.post<any>(this.accessPointUrl + '/SearchVesselProduct', searchObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err)));

  }

  addVesselProductDetails(vesselProductViewModel: any) {
    return this.http.post<any>(this.accessPointUrl, vesselProductViewModel, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  updateVesselProduct(id: number, patchVesselProductViewModel: any) {
    var query = "";
    if (id) {
      query = "/" + id;
    }
    return this.http.patch<any>(this.accessPointUrl + query, patchVesselProductViewModel, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  deleteVesselProduct(id: number) {
    var query = "";
    if (id) {
      query = "/" + id;
    }
    return this.http.delete<any>(this.accessPointUrl + query, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
}
