import { NgIf } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { DropdownModule } from 'primeng/dropdown';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { ShellService } from '../../../shared/services/shell/shell.service';
import { Outage } from '../../utilities/models/outage.model';
import { Site } from '../../utilities/models/site.model';
import { Unit } from '../../utilities/models/unit.model';
import { SiteService } from '../../utilities/services/site/site.service';
import { UnitService } from '../../utilities/services/unit/unit.service';
import { AccessLevel } from '../../../auth/app-roles';


const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me';

type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};
@Component({
  selector: 'app-home',
  standalone: true,
  imports: [DropdownModule, FormsModule, RouterModule, ProgressSpinnerModule, NgIf],
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  providers: [SiteService, UnitService]
})
export class HomeComponent implements OnInit {
  profile!: ProfileType;
  loginDisplay = false;
  //dropdown arrays
  sites: Site[] = [];
  units: Unit[] = [];
  outageDates: Outage[] = [];
  currentDate = "";
  totalRecords: any = 0;
  selectedSites1: string[] = []; //multiselect
  selectedSite: Site | undefined;
  selectedUnit: Unit | undefined;
  selectedOutage: Outage | undefined;
  item: any = null;
  unitId: number = -1;
  countryId: number = -1;

  loadingUnits = false;
  loadingOutages = false;

  loadingComplete: boolean = false;
  lockAnimation: boolean = false;
  errorOccurred: boolean = false;

  accessLevel:AccessLevel=AccessLevel.NoAccess;

  constructor(private http: HttpClient,
    private siteService: SiteService,
    private unitService: UnitService,
    private shellService: ShellService,
    private router: Router,
    
  ) {

    this.shellService.getClickEvent().subscribe(() => {
      this.resetDropDownData();
    })
  }


  ngOnInit(): void {
    // this.getSites();
    // this.shellService.getAccessLevel();
    this.accessLevel=this.shellService.getAppAccessLevel();
    if (this.accessLevel>AccessLevel.NoAccess){
      this.getSites();
    }
  }

  resetDropDownData() {
    this.selectedSite = undefined;
    this.selectedUnit = undefined;
    this.selectedOutage = undefined;
    this.units = [];
    this.outageDates = [];
  }

  getSites() {
    this.sites = [];
    this.siteService.getSites().subscribe(
      response => {
        for (var site in response) {
          this.sites.push(response[site]);
        }
        var siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(true);
        if (siteUnitOutage.siteId > 0) {
          this.selectedSite = this.sites.filter(s => s.id == siteUnitOutage.siteId)[0];
          if (this.selectedSite) {
            this.getUnits(this.selectedSite.id);
          }
        } else {
          this.loadingComplete = true;
          setTimeout(() => {
            this.lockAnimation = true;
          }, 7000);
        }
      }, error => {
        this.errorOccurred = true;
      }
    );
  }

  getUnits(siteId: number) {

    this.units = [];
    this.selectedOutage = undefined;
    this.loadingUnits = true;
    this.siteService.getUnitBySiteId(siteId).subscribe(
      response => {
        this.loadingUnits = false;
        for (var unit in response) {
          this.units.push(response[unit])
        }

        var siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(true);
        
        if (siteUnitOutage.unitId > 0) {
          this.selectedUnit = this.units.filter(u => u.unitId == siteUnitOutage.unitId)[0];
          if (this.selectedUnit) {
            this.getOutages(this.selectedUnit.unitId);

          }
        }
        this.loadingComplete = true;
        setTimeout(() => {
          this.lockAnimation = true;
        }, 7000);
      }, error => {
        this.loadingUnits = false;
        this.loadingComplete = true;
        setTimeout(() => {
          this.lockAnimation = true;
        }, 7000);
      }
    );
  }

  getOutages(unitId: number) {
    this.outageDates = [];
    this.loadingOutages = true;
    this.unitService.getOutageByUnitId(unitId).subscribe(
      response => {
        this.loadingOutages = false;
        for (var outage in response) {
          this.outageDates.push(response[outage]);
        }
        var siteUnitOutage = this.shellService.loadGlobalSiteUnitOutage(true);
        if (siteUnitOutage.outageId > 0) {
          this.selectedOutage = this.outageDates.filter(o => o.id == siteUnitOutage.outageId)[0];
        }
        this.loadingComplete = true;
        setTimeout(() => {
          this.lockAnimation = true;
        }, 7000);
      }, error => {
        this.loadingOutages = false;
        this.loadingComplete = true;
        setTimeout(() => {
          this.lockAnimation = true;
        }, 7000);
      }
    );
  }

  onSiteChange(event: any) {
    if (event) {
      this.shellService.setSite(event.value);
      this.getUnits(event.value.id)
    }
  }

  onUnitChange(event: any) {
    if (event) {
      this.shellService.setUnit(event.value);
      this.getOutages(event.value.unitId);
      this.unitId = event.value.unitId;
      this.shellService.siteUnitOutageModalClosed();
    }
  }

  onOutageChange(event: any) {
    if (event) {
      this.shellService.setOutage(event.value);
      this.shellService.siteUnitOutageModalClosed();
      if (event.value.outageTypeId == 1) {
        this.router.navigateByUrl('/nominal-lining')
      }
    }
  }

}
