import { Injectable } from '@angular/core';
import { AppHttpHeaders } from '../../../../shared/services/common/app-http-headers';
import { HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs';
import { Zone } from '../../models/zone.model';
import { ShellService } from '../../../../shared/services/shell/shell.service';

@Injectable({
  providedIn: 'root'
})
export class ZoneService {
  appReqHeader = new AppHttpHeaders();
  zoneAccessPointUrl: string = this.appReqHeader.domainURL + 'api/Zone';
  constructor(private http: HttpClient, private shellService: ShellService) { }

  searchZones(searchObject: any) {
    return this.http.post<Zone[]>(this.zoneAccessPointUrl +'/SearchZones', searchObject, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
  
  addZone(zoneObj: any) {
    return this.http.post<any>(this.zoneAccessPointUrl, zoneObj, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }

  patchUpdate(zoneId: number, patchZoneViewModel: any) {
    var query = "";
    if (zoneId) {
      query = "/" + zoneId;
    }
    return this.http.patch<any>(this.zoneAccessPointUrl + query, patchZoneViewModel, { headers: this.appReqHeader.formHeaders }).pipe(
      catchError(err => this.shellService.handleError(err))
    );
  }
}
