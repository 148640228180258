import { ApplicationConfig } from '@angular/core';
import { provideRouter, withHashLocation } from '@angular/router';

import { routes } from './app.routes';
import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { AppMsalInterceptor } from './auth/app-msal.interceptor';
import { provideAnimations } from '@angular/platform-browser/animations';
import { MSAL_INSTANCE, MsalService, MsalGuard, MsalBroadcastService, MsalGuardConfiguration, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { publicClientApp } from './auth/app-msal-client-app';
import { InteractionType } from '@azure/msal-browser';

// export const appConfig: ApplicationConfig = {
//   providers: [provideZoneChangeDetection({ eventCoalescing: true }), provideRouter(routes), provideClientHydration(), provideHttpClient(withInterceptors([AppMsalInterceptor]))]
// };
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: []
    }
  };
}


export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideHttpClient(withInterceptors([AppMsalInterceptor])),
    provideAnimations(),
    { provide: MSAL_INSTANCE, useValue: publicClientApp },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    }
  ],
};
