import { NgIf, NgFor, NgStyle } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { NavigationEnd,Router } from "@angular/router";
import { ButtonModule } from "primeng/button";
import { TooltipModule } from "primeng/tooltip";
import { AccessLevel, AccessLevelName } from "../../../auth/app-roles";
import { menuModel } from "../../constants";
import { ShellService } from "../../services/shell/shell.service";


@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [NgIf,NgFor,NgStyle,TooltipModule,ButtonModule],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent implements OnInit{
  menuStructure: any;
  secondaryMenuStructure: any;

  menuReady: boolean = false;
  subMenuName = "";

  currentUrl: string = "";

  // Authentication
  userRole = "";
  accessLevel:AccessLevel=AccessLevel.NoAccess;
  // Eye Candy
  secondaryMenuPositionOffset: number = 0;

  // @Input() set user(value: AccountInfo | undefined) {
  //   let _user = value;
  //   if(_user){
  //    this.accessLevel=findAppRoleFromAccountInfo(_user)
  //   }
  // }

  constructor(private shellService: ShellService, private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentUrl = event.url;
      }
    });
  }
  ngOnInit(): void {
    this.accessLevel=this.shellService.getAppAccessLevel()
    this.userRole= AccessLevelName[this.accessLevel];
    this.menuStructure = menuModel;
    this.menuReady = true;
    this.secondaryMenuStructure = undefined;
    this.subMenuName = "";
  }

  openSubMenu(name: string) {
    // this.accessLevel=this.shellService.getAppAccessLevel();
    if (this.subMenuName != name) {
      this.subMenuName = name;
    } else {
      this.subMenuName = "";
    }
    this.secondaryMenuStructure = undefined;
  }

  stopClickPropagation(event: Event): void {
    event.stopPropagation()
  }
  routeNavigation(pageRoute: string, event: any) {
     
    this.stopClickPropagation(event);
    this.router.navigateByUrl("/" + pageRoute);
    this.secondaryMenuStructure = undefined;
    this.shellService.toggleSidebarVisibility();
  }

  showSecondaryMenu(data: any, event: any): void {
     
    this.stopClickPropagation(event);
    this.secondaryMenuPositionOffset = event.target.offsetTop - 8;
    this.secondaryMenuStructure = undefined;
    setTimeout(() => {
      this.secondaryMenuStructure = data;
    }, 200);
  }

  checkUrl(url: string, excludeUrl: string = ""): boolean {
    var token = "";
    if (url.includes("/*")) {
      token = url.replace("/*", "");
      return this.currentUrl.includes(token);
    }
    if (excludeUrl.includes("/*")) {
      token = excludeUrl.replace("/*", "");
      return this.currentUrl.replace(token, "") == url;
    }
    return this.currentUrl == token;
  }

  showHelpDocument() {
    const PDF = '../../../../assets/docs/manual.pdf'
    window.open(PDF)
  }
}
