import { NgIf } from '@angular/common';
import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, RouterModule } from '@angular/router';
import { ReportsPageComponent } from "../../module/reports-page/reports-page.component";

@Component({
  selector: 'app-shutdown-review',
  standalone: true,
  imports: [RouterModule, NgIf, ReportsPageComponent],
  templateUrl: './shutdown-review.component.html',
  styleUrl: './shutdown-review.component.scss'
})
export class ShutdownReviewComponent implements OnInit {
  @ViewChild("wizardContainer", { read: ViewContainerRef }) wizardContainer: any;
  
  page: string = "";
  routes = [
    'estimates-vs-actual',
    'outage-cost'
  ]
  constructor(private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      if (this.routes.some(pg => params['comp'] === pg)) {
        this.page = params['comp'];
      }
    });
  }

  ngOnInit(): void {
  }

}
