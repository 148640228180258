import { HttpHeaders } from "@angular/common/http";
import { environment } from "../../../../environments/environment";

export class AppHttpHeaders {
    public domainURL: string;
    // public hostURL:  string;
    public genericHeaders: HttpHeaders;
    public formHeaders: HttpHeaders;

    constructor() {
        this.domainURL = environment.apiConfig.uri;
        // this.hostURL  =  window.location.host;

        const genericHeaderDict = {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        };

        const formHeaderDict = {
            'Accept': 'application/json',
            'Access-Control-Allow-Origin': '*',
            'Access-Control-Allow-Methods': 'GET, POST, PATCH, PUT, DELETE, OPTIONS',
        }

        this.genericHeaders = new HttpHeaders(genericHeaderDict);
        this.formHeaders = new HttpHeaders(formHeaderDict);
    }
}